import { __awaiter, __decorate, __rest } from "tslib";
import { Upload } from "antd";
import { computed, observable, toJS } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import React from "react";
import * as XLSX from "../../../shared/utils/sheets";
import { IconButton } from "../../../shared/components/IconButton";
import { UploadIcon } from "../../../shared/components/Icons/Upload";
import { MAXIMUN_FILE_SIZE } from "../../constants/file.constant";
let XlsPicker = class XlsPicker extends React.Component {
    constructor() {
        super(...arguments);
        this.sheetData = null;
        this.humanizeErrorMessage = (errorMessage) => {
            const verifyBaseSheet = i18n.t("sampleSheetMessage");
            switch (errorMessage) {
                case "Empty header cell":
                    return i18n.t("emptyHeaderCellMessage") + verifyBaseSheet;
                case "Empty sheet":
                    return i18n.t("emptySheetMessage") + verifyBaseSheet;
                case "Unidentified column":
                    return i18n.t("unidentifiedColumnMessage") + verifyBaseSheet;
                default:
                    return errorMessage;
            }
        };
        this.onChange = (file) => __awaiter(this, void 0, void 0, function* () {
            const fileHasXLSXExtension = file.name.endsWith(".xlsx");
            if (!fileHasXLSXExtension) {
                throw new Error(i18n.t("invalidExtensionMessage"));
            }
            if (file.size > MAXIMUN_FILE_SIZE) {
                throw new Error(i18n.t("spreadsheetSizeExceeded"));
            }
            const { schema } = this.props;
            this.sheetData = yield XLSX.findInvalidFields(yield XLSX.uploadAsync(file, schema), schema);
            if (!this.isSheetValid) {
                XLSX.downloadSheetAsync(toJS(this.sheetData), `${file.name.replace(".xlsx", "")}+${i18n.t("invalidLabel")}`);
                throw new Error(i18n.t("invalidSheetMessage"));
            }
            const isEmpty = yield XLSX.verifyIfIsEmpty(file);
            this.props.onChangeValue(file, this.isSheetValid, isEmpty);
        });
        this.customRequest = (data) => __awaiter(this, void 0, void 0, function* () {
            const { clearMessage, setErrorMessage } = this.props.generalStore;
            const { file, onError, onProgress, onSuccess } = data;
            onProgress({ percent: 0 });
            try {
                clearMessage();
                yield this.onChange(file);
                onProgress({ percent: 100 });
            }
            catch (e) {
                const error = e;
                setErrorMessage(this.humanizeErrorMessage(error.message ? error.message : error));
                onError();
            }
            onSuccess();
        });
    }
    get isSheetValid() {
        return this.sheetData ? XLSX.isSheetDataValid(this.sheetData) : false;
    }
    render() {
        const _a = this.props, { buttonText, onChangeValue, schema } = _a, rest = __rest(_a, ["buttonText", "onChangeValue", "schema"]);
        return (React.createElement(Upload, Object.assign({ accept: ".xlsx", customRequest: this.customRequest, multiple: false, showUploadList: false }, rest),
            React.createElement(IconButton, { buttonIcon: UploadIcon, type: "primary" }, buttonText || i18n.t("importMonthlySheetLabel"))));
    }
};
__decorate([
    observable
], XlsPicker.prototype, "sheetData", void 0);
__decorate([
    computed
], XlsPicker.prototype, "isSheetValid", null);
XlsPicker = __decorate([
    inject("generalStore"),
    observer
], XlsPicker);
export default XlsPicker;
