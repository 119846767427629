import React from "react";
import { useTranslation } from "react-i18next";
import { FilterForm } from "../../../shared/components/FilterForm";
import { RoundedButton } from "../RoundedButton";
import styles from "./style.scss";
export const FilterEmployeeForm = ({ onSubmit }) => {
    const { t } = useTranslation();
    const items = [
        {
            key: "value",
            name: "value",
            type: "input",
            placeholder: t("firing.nameCpfFilterPlaceholder"),
        },
    ];
    return (React.createElement(FilterForm, { className: styles.filterForm, items: items, filterButton: React.createElement(RoundedButton, { htmlType: "submit", id: "filter_button", "data-cy": "filter-button" }, t("filterLabel")), handleSubmit: onSubmit }));
};
