import React from "react";
import { Button } from "antd";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CredifitWhiteLogo } from "../Icons/CredifitWhiteLogo";
import s from "./style.scss";
export const NotFound = () => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: s.container },
        React.createElement("div", { className: s.blurBackground }),
        React.createElement("div", { className: s.box },
            React.createElement(CredifitWhiteLogo, null),
            React.createElement("h1", { className: s.title }, "404"),
            React.createElement("h3", { className: s.message }, t("somethingWrongMessage")),
            React.createElement("h4", { "data-testid": "message", className: s.message }, t("tryAgainMessage")),
            React.createElement(Button, { className: s.button },
                React.createElement(NavLink, { to: "/" }, t("backToStartMessage"))))));
};
