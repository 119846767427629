import { __rest } from "tslib";
import React, { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";
import Form from "antd/lib/form";
import s from "./style.scss";
const currentDateIsAfterToday = (current) => current && current > moment().endOf("day");
const DateRangePicker = (_a) => {
    var { form, keyName, dateFormat } = _a, rest = __rest(_a, ["form", "keyName", "dateFormat"]);
    const [initialDate, setInitialDate] = useState();
    const [finalDate, setFinalDate] = useState();
    const { getFieldDecorator } = form;
    const disabledFinalDate = (current) => {
        return currentDateIsAfterToday(current) || (current && current < initialDate);
    };
    const disabledInitialDate = (current) => {
        return currentDateIsAfterToday(current) || (current && current > finalDate);
    };
    // Change Initial Date
    const changeInitial = (date) => {
        // date === null && (date = undefined);
        setInitialDate(date);
    };
    // Change Final Date
    const changeFinal = (date) => {
        // date === null && (date = undefined);
        setFinalDate(date);
    };
    const { t } = useTranslation();
    return (React.createElement("div", { className: s.datePickerContainer, "data-testid": "datePickerRangeFormItem", "data-cy": "datePickerRange-item-data-cy" },
        React.createElement(Form.Item, { key: keyName + "- Inicial" }, getFieldDecorator(keyName + "Initial")(React.createElement(DatePicker, Object.assign({ dropdownClassName: "selectTimeHidden", disabledDate: (current) => disabledInitialDate(current), onChange: (date) => changeInitial(date), placeholder: t("initialDateLabel"), format: dateFormat, showTime: { defaultValue: moment("00:00:00", "HH:mm:ss") } }, rest)))),
        React.createElement(Form.Item, { key: keyName + "- Final" }, getFieldDecorator(keyName + "Final")(React.createElement(DatePicker, Object.assign({ dropdownClassName: "selectTimeHidden", disabledDate: (current) => disabledFinalDate(current), onChange: (date) => changeFinal(date), placeholder: t("finalDateLabel"), format: dateFormat, showTime: { defaultValue: moment("23:59:59", "HH:mm:ss") } }, rest))))));
};
export default DateRangePicker;
