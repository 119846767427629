import React, { useMemo } from "react";
import classNames from "classnames";
import styles from "./style.scss";
export const Typograph = ({ className, variant, children }) => {
    const variantClassName = useMemo(() => {
        if (!variant) {
            return styles.body;
        }
        return styles[variant];
    }, [variant]);
    return (React.createElement("span", { className: classNames([String(variantClassName), String(className)]) }, children));
};
