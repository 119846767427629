import { __awaiter } from "tslib";
import onlyNumbers from "@brazilian-utils/helper-only-numbers";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Modal, Tooltip } from "antd";
import ActionTable from "../../../shared/components/ActionTable";
import { FilterForm } from "../../../shared/components/FilterForm";
import { CircledDownloadIcon } from "../../../shared/components/Icons/CircledDownload";
import { DownloadIcon } from "../../../shared/components/Icons/Download";
import { ListColumnInfo } from "../../../shared/components/ListColumnInfo";
import { Pagination } from "../../../shared/components/Pagination";
import { SectionContainer } from "../../../shared/components/SectionContainer";
import { SectionFooter } from "../../../shared/components/SectionFooter";
import { SemanticButton } from "../../../shared/components/SemanticButton";
import TooltipDisplay from "../../../shared/components/TooltipDisplay";
import { getCompanyContractsStatusLabels, getStatusLabels, } from "../../../shared/constants/contracts.constants";
import { defaultConfirmProps } from "../../../shared/utils";
import { getDateLocalTimezoneFormat, getTodayDateLocalTimezoneFormat, } from "../../../shared/utils/date";
import { downloadFileFromBuffer, openFileInNewTab } from "../../../shared/utils/file";
import { cut, formatCnpj, formatCpf, formatStringCurrency } from "../../../shared/utils/formatters";
import { mapCompaniesToSelectOptions } from "../../../shared/utils/mappers";
import { filterOption } from "../../../shared/utils/select";
import { SectionHeader } from "../../components/SectionHeader";
import { useStores } from "../../utils/mobx";
import s from "./style.scss";
const downloadCCB = (link) => {
    if (link) {
        openFileInNewTab(link);
    }
};
const handleClick = (link, event) => {
    event.stopPropagation();
    downloadCCB(link);
};
const Contracts = observer(() => {
    var _a;
    const { t } = useTranslation();
    const { authStore } = useStores();
    const columns = [
        {
            title: t("nameLabel"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Empresa",
            dataIndex: "companyDisplay",
            key: "companyDisplay",
        },
        {
            title: t("ccbNumberLabel"),
            dataIndex: "ccbNumber",
            key: "ccbNumber",
            width: 150,
        },
        {
            title: "CPF",
            dataIndex: "cpf",
            key: "cpf",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "CCB",
            dataIndex: "ccbLink",
            key: "ccb",
            align: "right",
            render: (ccbLink) => {
                const ccbIsNull = ccbLink === null;
                const Button = (React.createElement(SemanticButton, { disabled: ccbIsNull, onClick: (e) => {
                        handleClick(ccbLink, e);
                    } },
                    React.createElement(CircledDownloadIcon, null)));
                return ccbIsNull ? (React.createElement(Tooltip, { mouseLeaveDelay: 0, trigger: "hover", placement: "topRight", title: t("ccbTooltipLabel") },
                    React.createElement("div", { className: s.tooltipContainer }, Button))) : (React.createElement(React.Fragment, null, Button));
            },
        },
        {
            title: t("contractSignDateLabel"),
            dataIndex: "signDate",
            key: "signDate",
            align: "right",
        },
    ];
    const { generalStore: { setErrorMessage, catchErrors }, contractStore, } = useStores();
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [contracts, setContracts] = useState([]);
    const [totalContracts, setTotalContracts] = useState(0);
    const [loadingContracts, setLoadingContracts] = useState(true);
    const [currentFilter, setCurrentFilter] = useState({
        cpf: null,
        status: "active",
        date: { from: null, to: null },
        companiesIds: [],
    });
    const confirm = Modal.confirm;
    const contractLabels = {
        name: t("contractModalNameLabel"),
        cpf: "CPF",
        status: t("contractModalStatusLabel"),
        ccb: "CCB",
        ccbNumber: t("ccbNumberLabel"),
        signDate: t("contractModalDateLabel"),
        inssAway: "STATUS",
        totalValue: t("contractModalTotalValueLabel"),
        paidValue: t("contractModalPaidValueLabel"),
        remainingValue: `${t("contractModalRemainingValueLabel")} ${getTodayDateLocalTimezoneFormat()}`,
        totalInstallments: t("contractModalInstallmentsLabel"),
        remainingInstallments: t("contractModalRemainingInstallmentsLabel"),
    };
    const statusLabels = getStatusLabels(t);
    const formatNullValue = (value) => {
        if (value != null) {
            return formatStringCurrency(value);
        }
        return "--";
    };
    const showModal = (row) => {
        confirm(Object.assign(Object.assign({}, defaultConfirmProps), { title: (React.createElement("div", { className: s.modalHeaderDialog },
                React.createElement("h2", { className: s.modalTitleDialog }, row.name),
                React.createElement("div", { className: s.statusDialog }, row.inssAway && React.createElement("span", { className: s.statusDialog }, t("inssAwayLabel"))))), content: (React.createElement("div", { className: s.modal },
                React.createElement("div", { className: s.modalBodyDialog },
                    React.createElement(ListColumnInfo, { labels: [
                            contractLabels.cpf,
                            contractLabels.signDate,
                            contractLabels.ccbNumber,
                            contractLabels.status,
                            contractLabels.totalValue,
                            contractLabels.paidValue,
                            contractLabels.remainingValue,
                            contractLabels.totalInstallments,
                            contractLabels.remainingInstallments,
                        ], values: [
                            row.cpf,
                            row.signDate,
                            row.ccbNumber,
                            row.status,
                            formatStringCurrency(row.totalValue),
                            formatNullValue(row.paidValue),
                            formatNullValue(row.balance),
                            row.totalInstallments,
                            row.remainingInstallments,
                        ] })))), className: s.containerDialog, onOk() {
                downloadCCB(row.ccbLink);
            }, okButtonProps: {
                icon: "download",
                type: "primary",
                disabled: row.ccbLink ? false : true,
            }, okText: t("downloadCcbText"), cancelText: t("closeText"), width: "50%", maskClosable: true }));
    };
    const items = [
        {
            key: "companiesIds",
            name: "companiesIds",
            type: "select",
            placeholder: "Empresa",
            selectOptions: mapCompaniesToSelectOptions((_a = authStore.user) === null || _a === void 0 ? void 0 : _a.companies),
            mode: "multiple",
            props: {
                filterOption,
            },
        },
        {
            key: "cpf",
            name: "cpf",
            type: "input",
            placeholder: "CPF",
            inputMask: "111.111.111-11",
        },
        {
            key: "status",
            name: "status",
            type: "select",
            placeholder: "Status",
            selectDefaultValue: "active",
            selectOptions: getCompanyContractsStatusLabels(t),
        },
        {
            key: "data",
            name: "data",
            type: "dateRange",
        },
    ];
    const getContracts = (filter) => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingContracts(true);
        try {
            const responseTotal = yield contractStore.getContractTotal(filter);
            setTotalContracts(responseTotal);
            const response = yield contractStore.getContracts(filter, currentPage, rowsPerPage);
            setContracts(response.contracts);
            setCurrentPage(response.currentPage);
            setRowsPerPage(response.rowsPerPage);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("contractsApiErrorMessage"));
        }
        finally {
            setLoadingContracts(false);
        }
    });
    const exportPayroll = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const contractReport = yield contractStore.exportContractReport(currentFilter);
            downloadFileFromBuffer(contractReport, "Relatorio de contratos.xlsx");
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("exportApiErrorMessage"));
        }
    });
    const applyFilter = (values) => {
        const filter = {
            cpf: onlyNumbers(values.cpf),
            status: values.status,
            date: {
                from: values.dataInitial ? values.dataInitial.toDate() : null,
                to: values.dataFinal ? values.dataFinal.toDate() : null,
            },
            companiesIds: (values === null || values === void 0 ? void 0 : values.companiesIds) || [],
        };
        setCurrentPage(1);
        setRowsPerPage(10);
        setCurrentFilter(filter);
    };
    React.useEffect(() => {
        getContracts(currentFilter);
    }, [currentFilter]);
    React.useEffect(() => {
        getContracts(currentFilter);
    }, [currentPage, rowsPerPage]);
    return (React.createElement("div", { className: s.containerContracts },
        React.createElement(SectionHeader, { title: t("contractsLabel"), actions: [
                {
                    label: t("exportReportLabel"),
                    handleAction: () => exportPayroll(),
                    icon: React.createElement(Icon, { component: () => React.createElement(DownloadIcon, null) }),
                    disabled: contracts.length === 0,
                },
            ] }),
        React.createElement(SectionContainer, { className: s.container },
            React.createElement(FilterForm, { items: items, handleSubmit: applyFilter }),
            React.createElement("div", { "data-cy": "tabble-wrapper-data-cy" },
                React.createElement(ActionTable, { className: s.table, rowClassName: () => {
                        if (s.rowClick) {
                            return s.rowClick;
                        }
                        return "";
                    }, columns: columns, loading: loadingContracts, onRow: (record) => {
                        return {
                            onClick: () => showModal(record),
                        };
                    }, dataSource: contracts.map((contract) => (Object.assign(Object.assign({}, contract), { key: contract.id, status: (React.createElement("span", { "data-cy": "statusLabels-wrapper-data-cy" }, statusLabels[contract.status])), cpf: formatCpf(contract.cpf), ccbLink: contract.ccbLink, signDate: getDateLocalTimezoneFormat(contract.signDate), companyDisplay: (React.createElement(React.Fragment, null,
                            cut(contract.company.name),
                            React.createElement(TooltipDisplay, { classname: s.tooltipContainer, title: React.createElement("div", { className: s.tooltipContainer },
                                    React.createElement("p", null,
                                        "CNPJ: ",
                                        formatCnpj(contract.company.cnpj))) }))) }))) }))),
        React.createElement(SectionFooter, null,
            React.createElement(Pagination, { current: currentPage, pageSize: rowsPerPage, total: totalContracts, onChange: (page) => setCurrentPage(page), onShowSizeChange: (current, size) => {
                    setCurrentPage(current);
                    setRowsPerPage(size);
                } }))));
});
export default Contracts;
