exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../assets/404_background.png"));

// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.container___GzzM_ {\n  background: url(" + ___CSS_LOADER_URL___0___ + ") no-repeat center center fixed;\n  background-size: cover;\n  display: flex;\n  flex-direction: row;\n  min-height: 100vh;\n  width: 100%;\n  position: fixed;\n  top: 64px;\n  left: 0; }\n\n.blurBackground___3J3RC {\n  position: absolute;\n  width: 27.5rem;\n  height: 31.3rem;\n  filter: blur(5px);\n  background: #176869d1;\n  margin: auto 15rem auto auto;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0; }\n\n.box___16eaD {\n  display: flex;\n  flex-flow: column;\n  align-items: flex-end;\n  margin: auto 19rem auto auto;\n  position: relative; }\n\n.title___2fNau {\n  font-size: 110px;\n  font-weight: bold;\n  line-height: normal;\n  color: #ffffff;\n  width: 100%;\n  text-align: end;\n  margin: 1.8rem 0 1.8rem; }\n\n.message___1aYTT {\n  font-size: 20px;\n  font-weight: bold;\n  font-stretch: normal;\n  line-height: 1.33;\n  text-align: end;\n  color: #ffffff;\n  width: 100%; }\n\n.button___-TB0W {\n  border: #01929f;\n  background-color: #01929f;\n  color: #ffffff;\n  border-radius: 0.2rem;\n  font-weight: bold;\n  margin: 2.5rem 0;\n  cursor: pointer;\n  font-size: 14px; }\n\n.button___-TB0W:hover {\n  border: #FD7622;\n  background-color: #FD7622; }\n\n@media (max-width: 1100px) {\n  .box___16eaD {\n    margin: auto; }\n  .container___GzzM_ {\n    justify-content: center; }\n  .blurBackground___3J3RC {\n    margin: 2rem auto; } }\n", ""]);

// Exports
exports.locals = {
	"container": "container___GzzM_",
	"blurBackground": "blurBackground___3J3RC",
	"box": "box___16eaD",
	"title": "title___2fNau",
	"message": "message___1aYTT",
	"button": "button___-TB0W"
};