import i18n from "../../i18n";
export const columns = [
    {
        title: i18n.t("cpfLabel"),
        dataIndex: "cpf",
        key: "cpf",
        width: "250px",
    },
    {
        title: i18n.t("companyLabel"),
        dataIndex: "companyName",
        key: "companyName",
        width: "200px",
    },
    {
        title: i18n.t("nameLabel"),
        dataIndex: "name",
        key: "name",
        width: "200px",
    },
    {
        title: i18n.t("sectorLabel"),
        dataIndex: "companySector",
        key: "companySector",
        width: "200px",
    },
    {
        title: i18n.t("ccbNumberLabel"),
        dataIndex: "ccb",
        key: "ccb",
        render: (ccb) => ccb.number,
    },
    {
        title: i18n.t("creditorLabel"),
        dataIndex: "creditor",
        key: "creditor",
        width: "150px",
    },
    {
        title: i18n.t("ccbLabel"),
        dataIndex: "ccbLink",
        key: "ccbLink",
        align: "right",
        width: 80,
    },
    {
        title: i18n.t("paymentDayLabel"),
        dataIndex: "dueDate",
        key: "dueDate",
        align: "right",
        width: "150px",
    },
    {
        title: i18n.t("valueLabel") + "(R$)",
        dataIndex: "value",
        key: "value",
        align: "right",
        width: "150px",
    },
    {
        title: i18n.t("installmentLabel"),
        dataIndex: "installment",
        key: "installment",
        align: "right",
    },
    {
        title: i18n.t("loanApplication.firstInstallmentDue"),
        dataIndex: "firstInstallmentDate",
        key: "firstInstallmentDate",
        align: "right",
    },
    {
        title: i18n.t("loanApplication.lastInstallmentDue"),
        dataIndex: "lastInstallmentDate",
        key: "lastInstallmentDate",
        align: "right",
    },
];
