import { __awaiter } from "tslib";
import { Form, Modal, Spin } from "antd";
import MaskedInput from "antd-mask-input";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { checkCPF } from "../../../shared/utils/formValidator";
import s from "./style.scss";
const LoanInitModal = ({ visible, onCancel, onOk, form, setCpf, loading, isOkButtonDisabled, setIsOkButtonDisabled, }) => {
    const { getFieldDecorator, validateFields, setFieldsValue, resetFields } = form;
    useEffect(() => {
        if (visible) {
            setCpf(undefined);
            resetFields();
            setIsOkButtonDisabled(true);
        }
    }, [visible]);
    const handleCPFChange = (e) => __awaiter(void 0, void 0, void 0, function* () {
        const { value } = e.target;
        setFieldsValue({ cpf: value });
        setCpf(value);
        validateFields(["cpf"], (errors, _) => {
            if (!errors) {
                setIsOkButtonDisabled(false);
            }
            else {
                setIsOkButtonDisabled(true);
            }
        });
    });
    const { t } = useTranslation();
    return (React.createElement(Modal, { visible: visible, title: React.createElement("div", { className: s.modalHeaderDialogLoan },
            React.createElement("h2", { className: s.textTitleDialogLoan }, "SIMULAR NOVO EMPR\u00C9STIMO")), closable: false, okText: t("consultLabel"), cancelText: t("cancelLabel"), width: 463, centered: true, maskClosable: true, style: { height: "382px" }, okButtonProps: {
            icon: "check",
            type: "primary",
            disabled: isOkButtonDisabled,
        }, onOk: onOk, onCancel: onCancel, className: s.containerDialog },
        React.createElement(Spin, { spinning: loading },
            React.createElement("div", { className: s.modalBodyDialog },
                React.createElement("div", { className: s.labelBodyDialogLoan },
                    React.createElement("div", { className: s.textBodyDialogLoan }, "Para realizar uma nova simula\u00E7\u00E3o de empr\u00E9stimo, basta preencher o CPF abaixo:")),
                React.createElement("div", { className: s.labelBodyCpf },
                    React.createElement(Form.Item, { label: "CPF" }, getFieldDecorator("cpf", {
                        rules: [{ validator: checkCPF }],
                    })(React.createElement(MaskedInput, { mask: "111.111.111-11", placeholder: "000.000.000-00", allowClear: true, maxLength: 15, onChange: handleCPFChange, "data-cy": "inputCpfDataCy" }))))))));
};
export default Form.create()(LoanInitModal);
