import React from "react";
import { formatFullDateTime } from "../../../../shared/utils/date";
import styles from "./style.scss";
export const UpdatesComponent = ({ datetime, subtext, onShowDetails: OnShowDetails, children, }) => {
    return (React.createElement("div", { className: styles.update, "data-cy": "update-data-cy", onClick: OnShowDetails },
        React.createElement("div", { className: styles.children }, children),
        React.createElement("div", { className: styles.content },
            React.createElement("span", { className: styles.text }, formatFullDateTime(datetime)),
            React.createElement("span", { className: styles.subtext }, subtext))));
};
