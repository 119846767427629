import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { monthNames } from "../../../shared/utils/date";
import { SubSectionHeader } from "../../components/SubSectionHeader";
import { ScrollableList } from "../../components/ScrollableList";
import s from "./style.scss";
export const MonthSelector = ({ months, handleSelect }) => {
    const { t } = useTranslation();
    const [selectedMonth, setSelectedMonth] = useState(0);
    const handleClick = (index, month) => {
        setSelectedMonth(index);
        handleSelect(month);
    };
    return (React.createElement("div", { className: s.monthSelector },
        React.createElement(SubSectionHeader, { title: t("monthLabel") }),
        React.createElement(ScrollableList, null, months.map((month, index) => (React.createElement("div", { key: `${month.month}-${month.year}`, onClick: () => handleClick(index, month), className: classNames(s.monthItem, {
                [s.selectedMonthItem]: selectedMonth === index,
            }) },
            monthNames[decrementMonth(month.month)],
            " ",
            decrementYear(month.month, month.year)))))));
};
/**
 * It is required to decrement the month because the Payroll and Charge report
 *  are based on company's sheet ("Folha"), not month.
 *
 * @see https://gitlab.com/credifit/frontend/dashboard/-/issues/68
 * @param month
 */
function decrementMonth(month) {
    let monthDecremented = month - 1;
    if (month <= 1) {
        monthDecremented = 12;
    }
    return monthDecremented;
}
/**
 * @see decrementMonth()
 * @see https://gitlab.com/credifit/frontend/dashboard/-/issues/68
 *
 * @param month
 * @param year
 */
function decrementYear(month, year) {
    let yearDecremented = year;
    if (month <= 1) {
        yearDecremented--;
    }
    return yearDecremented;
}
