import { __awaiter } from "tslib";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { Spin } from "antd";
import { formatCnpj } from "../../../shared/utils/formatters";
import { Pagination } from "../../../shared/components/Pagination";
import { SectionHeader } from "../../components/SectionHeader";
import { RoundedButton } from "../../components/RoundedButton";
import { FiringDetails } from "../../components/FiringEmployees/FiringDetails";
import { getHREmployeeSelectedCompany } from "../../utils/user.utils";
import { EmployeesTable } from "../../components/EmployeesTable";
import { useStores } from "../../utils/mobx";
import { FilterEmployeeForm } from "../../components/EmployeesFilter";
import { UpdatesList } from "../../components/Updates/UpdatesList";
import { FiringsUpdates } from "../../components/Updates/FiringsUpdates";
import RoundedSection from "../../components/RoundedSection";
import { FiredDetailsModal } from "../../../company/components/FiredDetails";
import { FiredEmployeesDetails } from "../../components/FiredEmployeesDetails";
import styles from "./style.scss";
const FiringEmployees = observer(({ match }) => {
    const { t } = useTranslation();
    const { generalStore: { isMultiCompany, setErrorMessage, catchErrors, clearMessage }, authStore: { user }, companyEmployeesStore, firingsStore, firedStored, } = useStores();
    const { companyId } = match.params;
    const [selectedRequestsKeys, setSelectedRequestsKeys] = useState([]);
    const [confirmingFiring, setConfirmingFiring] = useState(false);
    const [firingsCurrentPage, setFiringsCurrentPage] = useState(1);
    const [hasMoreFirings, setHasMoreFirings] = useState(false);
    const [loadingFirings, setLoadingFirings] = useState(false);
    const [firings, setFirings] = useState([]);
    const company = getHREmployeeSelectedCompany(companyId, user);
    const [loading, setLoading] = React.useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [companyEmployees, setCompanyEmployees] = useState([]);
    const [totalCompanyEmployees, setTotalCompanyEmployees] = useState(0);
    const [currentFilter, setCurrentFilter] = useState();
    const [showDetails, setShowDetails] = useState(false);
    const [changeId, setChangeId] = useState(null);
    const [detailsDateTime, setDetailsDateTime] = useState(new Date());
    const [confirmedFiring, setConfirmedFiring] = useState(false);
    const [loadingEmployeesDetails, setLoadingEmployeesDetails] = React.useState(true);
    const [employeesDetails, setEmployeesDetails] = useState([]);
    useEffect(() => {
        if (!confirmingFiring) {
            getCompanyEmployees(currentPage, rowsPerPage, {
                companyId,
                value: (currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.value) || "",
            });
        }
    }, [currentPage, rowsPerPage, currentFilter, confirmingFiring]);
    useEffect(() => {
        if (!confirmingFiring) {
            getCompanyFirings(1);
        }
    }, [confirmingFiring]);
    const handleSelectChange = (selectedRowKeys) => {
        setSelectedRequestsKeys(selectedRowKeys);
    };
    const getCompanyFirings = (page) => __awaiter(void 0, void 0, void 0, function* () {
        clearMessage();
        try {
            setLoadingFirings(true);
            const firingsRowsPerPage = 6;
            const fetchedFirings = yield firingsStore.getCompanyFirings(companyId, page, firingsRowsPerPage);
            if (page === 1) {
                setFirings(fetchedFirings);
            }
            else {
                setFirings((oldArray) => oldArray.concat(fetchedFirings));
            }
            setHasMoreFirings(fetchedFirings.length >= firingsRowsPerPage);
            setFiringsCurrentPage(page);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("unexpectedErrorMessage"));
        }
        finally {
            setLoadingFirings(false);
        }
    });
    const getCompanyEmployees = (currentPageRequests, rowsPerPageRequests, filter) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const companyEmployeesReport = yield companyEmployeesStore.getCompanyEmployees(filter, currentPageRequests, rowsPerPageRequests);
            const total = yield companyEmployeesStore.getCompanyEployeesTotal(filter || null);
            setCompanyEmployees(companyEmployeesReport.companyEmployees);
            setCurrentPage(companyEmployeesReport.currentPage);
            setRowsPerPage(companyEmployeesReport.rowsPerPage);
            setTotalCompanyEmployees(total);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("companyEmployeesApiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const applyFilter = (searchFilter) => __awaiter(void 0, void 0, void 0, function* () {
        const filter = { companyId, value: searchFilter.value };
        setCurrentFilter(filter);
    });
    const handleConfirmingFiringBackButtonClick = () => setConfirmingFiring(false);
    const handleConfirmedFiringBackButtonClick = () => setConfirmedFiring(false);
    const handleSubmitFiring = (firingsChangeId) => {
        handleConfirmingFiringBackButtonClick();
        setConfirmedFiring(true);
        setSelectedRequestsKeys([]);
        fetchEmployeesDetails(firingsChangeId);
    };
    const onShowDetails = (shouldShowDetails, firingsChangeId, dateTime) => {
        setShowDetails(shouldShowDetails);
        setChangeId(firingsChangeId);
        setDetailsDateTime(dateTime);
    };
    const onClose = () => {
        setShowDetails(false);
        setChangeId(null);
    };
    const fetchEmployeesDetails = (firingsChangeId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setEmployeesDetails([]);
            setLoadingEmployeesDetails(true);
            const employees = yield firedStored.getFiredEmployeesDetails(match.params.companyId, firingsChangeId);
            setEmployeesDetails(employees);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("fired.firedDetailsError"));
        }
        finally {
            setLoadingEmployeesDetails(false);
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("firingEmployees"), linkToBackButton: "/desligar-funcionarios", info: `<b>${company === null || company === void 0 ? void 0 : company.name}</b>`, hasBackButton: isMultiCompany, description: React.createElement(React.Fragment, null,
                React.createElement("p", { className: styles.companyCnpj }, formatCnpj(company === null || company === void 0 ? void 0 : company.cnpj)),
                React.createElement("p", null, t("firing.startFiringText"))) }),
        React.createElement("div", { className: styles.employeesContainer },
            React.createElement("div", { className: styles.updatesSection },
                React.createElement(Spin, { spinning: loadingFirings },
                    React.createElement(UpdatesList, { loadMoreUpdates: getCompanyFirings, currentPage: firingsCurrentPage, hasMore: hasMoreFirings },
                        React.createElement(FiringsUpdates, { firings: firings, onShowDetails: onShowDetails })))),
            confirmingFiring && (React.createElement(RoundedSection, { title: t("firing.choosenEmployees") },
                React.createElement(FiringDetails, { usersIds: selectedRequestsKeys, onBackButtonClick: handleConfirmingFiringBackButtonClick, onSubmit: handleSubmitFiring }))),
            confirmedFiring && (React.createElement(RoundedSection, { title: t("firing.firedEmployees") },
                React.createElement(FiredEmployeesDetails, { employeesDetails: employeesDetails, loading: loadingEmployeesDetails, onBackButtonClick: handleConfirmedFiringBackButtonClick }))),
            !confirmingFiring && !confirmedFiring && (React.createElement(RoundedSection, { title: React.createElement(FilterEmployeeForm, { onSubmit: applyFilter }) },
                React.createElement(Spin, { spinning: loading, "data-cy": "employess-table-wrapper" },
                    React.createElement(EmployeesTable, { employees: companyEmployees, handleSelectChange: handleSelectChange, selectedRequestsKeys: selectedRequestsKeys }),
                    React.createElement("div", { className: styles.footerWrapper },
                        React.createElement(Pagination, { current: currentPage, total: totalCompanyEmployees, pageSize: rowsPerPage, onChange: (page) => setCurrentPage(page), onShowSizeChange: (current, size) => {
                                setCurrentPage(current);
                                setRowsPerPage(size);
                            } }),
                        React.createElement(RoundedButton, { onClick: () => setConfirmingFiring(true), "data-cy": "firing-employees-button", disabled: isEmpty(selectedRequestsKeys) }, t("firingEmployees")))))),
            React.createElement(FiredDetailsModal, { onCancel: onClose, visible: showDetails, changeId: changeId, firedDateTime: detailsDateTime }))));
});
export default FiringEmployees;
