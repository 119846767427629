import * as stores from "./";
import { createContext } from "react";
import { syncHistoryWithStore } from "mobx-react-router";
import { createBrowserHistory } from "history";
export class RootStore {
    constructor() {
        this.routerStore = new stores.RouterStore();
        this.history = syncHistoryWithStore(createBrowserHistory(), this.routerStore);
        this.authStore = new stores.AuthStore(this);
        this.generalStore = new stores.GeneralStore(this);
        this.loanApplicationStore = new stores.LoanApplicationStore();
        this.payrollReportStore = new stores.PayrollReportStore();
        this.contractStore = new stores.ContractStore();
        this.payrollImportStore = new stores.PayrollImportStore();
        this.agreementStore = new stores.AgreementStore();
        this.companyEmployeesStore = new stores.CompanyEmployeesStore();
        this.firingsStore = new stores.FiringsStore();
        this.firedStored = new stores.FiredStore();
        this.proposalStore = new stores.ProposalStore();
    }
}
export const rootStore = new RootStore();
export default createContext(rootStore);
