import { __rest } from "tslib";
import React from "react";
import s from "./style.scss";
import classNames from "classnames";
export const SemanticButton = (_a) => {
    var _b;
    var { disabled, children } = _a, rest = __rest(_a, ["disabled", "children"]);
    return (React.createElement("button", Object.assign({ disabled: disabled }, rest, { className: classNames(s.semanticButton, disabled ? s.disabled : "", (_b = rest.className) !== null && _b !== void 0 ? _b : ""), onClick: (e) => {
            e.preventDefault();
            if (rest.onClick) {
                rest.onClick(e);
            }
        } }), children));
};
