import { __rest } from "tslib";
import React, { forwardRef, useState, useEffect } from "react";
import { formatCurrencyNoPrefix, toInt } from "../../utils/formatters";
import InputNumber from "antd/lib/input-number";
const InputCurrency = forwardRef((_a, ref) => {
    var { placeholder, defaultValue, onChange } = _a, rest = __rest(_a, ["placeholder", "defaultValue", "onChange"]);
    const [value, setValue] = useState(0);
    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }
    }, []);
    return (React.createElement(InputNumber, Object.assign({ ref: ref, formatter: (value) => formatCurrencyNoPrefix(`${value}`), parser: (value) => toInt(value), placeholder: placeholder || "0,00", value: value }, rest, { onChange: (value) => {
            if (onChange) {
                onChange(value);
            }
            setValue(value || 0);
        } })));
});
export default InputCurrency;
