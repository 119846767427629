import { __awaiter } from "tslib";
import { Icon, Input, Modal, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { File } from "../../../../shared/components/Icons/File";
import { UpdatesList } from "../../../components/Updates/UpdatesList";
import { SectionContainer } from "../../../../shared/components/SectionContainer";
import { SectionHeader } from "../../../components/SectionHeader";
import * as XLSX from "../../../../shared/utils/sheets";
import { useStores } from "../../../utils/mobx";
import EmptyPayrolls from "../EmptyPayrolls";
import LastPayroll from "../LastPayroll";
import { payrollSheetSchema } from "../types";
import { downloadFileFromBuffer } from "../../../../shared/utils/file";
import { formatCnpj } from "../../../../shared/utils/formatters";
import { getHREmployeeSelectedCompany } from "../../../utils/user.utils";
import styles from "../style.scss";
import { PayrollUpdates } from "../../../components/Updates/PayrollUpdates";
const PayrollImport = observer(({ match }) => {
    const { t } = useTranslation();
    const { generalStore: { clearMessage, setErrorMessage, setSuccessMessage, catchErrors, isMultiCompany }, payrollImportStore: { lastPayroll, payrolls }, payrollImportStore, routerStore, authStore: { user }, } = useStores();
    const companyId = match.params.companyId;
    const company = getHREmployeeSelectedCompany(companyId, user);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [uploadEmptyPayroll, setUploadEmptyPayroll] = useState(false);
    const [payrollFile, setPayrollFile] = useState();
    const [payrollIsValid, setPayrolIsValid] = useState(false);
    const [companyName, setCompanyName] = useState(null);
    const hasPayrolls = !loading && payrolls.length > 0;
    const hasLastPayroll = lastPayroll !== null;
    React.useEffect(() => {
        getPayrolls();
    }, []);
    const getPayrolls = (page) => __awaiter(void 0, void 0, void 0, function* () {
        clearMessage();
        try {
            setLoading(true);
            const rowsPerPage = 6;
            const paginatedPayrolls = yield payrollImportStore.getPayrolls(companyId, page || 1, rowsPerPage);
            setHasMore(paginatedPayrolls.payrolls.length >= rowsPerPage);
            setCurrentPage(paginatedPayrolls.currentPage);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("unexpectedErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const downloadModelFile = () => XLSX.downloadSampleSheet(payrollSheetSchema, `Modelo_de_Folha_1.0`);
    const downloadPayrollFile = (id, filename) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            clearMessage();
            const file = yield payrollImportStore.getPayrollFile(id);
            if (file) {
                downloadFileFromBuffer(file, filename);
            }
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("unexpectedErrorMessage"));
        }
    });
    const downloadUpdatedPayrollFile = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            clearMessage();
            const file = yield payrollImportStore.getPayrollFileUpdated(companyId);
            if (file) {
                downloadFileFromBuffer(file, t("updatedEmployeeSheetFileName"));
            }
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("unexpectedErrorMessage"));
        }
    });
    const onUploadSheet = (file, isValid, isEmpty) => __awaiter(void 0, void 0, void 0, function* () {
        if (isEmpty) {
            if (hasPayrolls || hasLastPayroll) {
                setPayrollFile(file);
                setPayrolIsValid(isValid);
                setUploadEmptyPayroll(true);
            }
            else {
                setErrorMessage("A folha está vazia");
            }
            return;
        }
        setLoading(true);
        const reader = new FileReader();
        reader.onload = () => __awaiter(void 0, void 0, void 0, function* () {
            const buffer = Buffer.from(reader.result.toString().split(",")[1], "base64");
            try {
                if (isValid) {
                    yield payrollImportStore.uploadPayroll(companyId, buffer, file.name);
                    if (payrollImportStore.hasConflicts) {
                        routerStore.push(`/atualizacoes-importar-folha/${companyId}`);
                    }
                    else {
                        setSuccessMessage(t("successfulImportMessage"));
                    }
                }
            }
            catch (error) {
                catchErrors(error, setErrorMessage, t("unexpectedErrorMessage"));
            }
            finally {
                setLoading(false);
            }
        });
        reader.readAsDataURL(file);
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("importSheetTitle"), linkToBackButton: "/importar-folha", info: `<b>${company === null || company === void 0 ? void 0 : company.name}</b> <span>CNPJ: ${formatCnpj(company === null || company === void 0 ? void 0 : company.cnpj)}</span>`, hasBackButton: isMultiCompany, description: React.createElement(React.Fragment, null,
                React.createElement("p", null, t("importHeaderDescription")),
                React.createElement("p", null, t("importHeaderDescriptionInstruction")),
                React.createElement("p", null, t("importHeaderDescriptionInstructionFinal"))), actions: hasPayrolls || hasLastPayroll
                ? [
                    {
                        label: t("sampleDocumentLabel"),
                        handleAction: downloadModelFile,
                        icon: React.createElement(Icon, { component: File }),
                    },
                ]
                : [] }),
        React.createElement(SectionContainer, { className: styles.payrollContainer || "" },
            React.createElement(Spin, { spinning: loading, wrapperClassName: styles.spinContainer || "" }, hasPayrolls || hasLastPayroll ? (React.createElement("div", { className: styles.payrollContainerContent },
                hasPayrolls && (React.createElement("div", { className: styles.updatesList },
                    React.createElement(UpdatesList, { loadMoreUpdates: getPayrolls, currentPage: currentPage, hasMore: hasMore },
                        React.createElement(PayrollUpdates, { payrolls: payrolls, onClickDownloadPayroll: downloadPayrollFile })))),
                React.createElement(LastPayroll, { lastPayroll: lastPayroll, onClickDownloadPayroll: downloadUpdatedPayrollFile, onUploadSheet: onUploadSheet }))) : (React.createElement(EmptyPayrolls, { downloadModelFile: downloadModelFile, onUploadSheet: onUploadSheet })))),
        React.createElement(Modal, { title: React.createElement("div", null, "Folha vazia, cuidado! \u26A0"), okText: "Demitir todos os funcion\u00E1rios", okButtonProps: {
                icon: "upload",
                type: "primary",
                disabled: companyName !== (company === null || company === void 0 ? void 0 : company.name),
            }, onOk: () => {
                setUploadEmptyPayroll(false);
                // @ts-ignore
                onUploadSheet(payrollFile, payrollIsValid, false);
            }, visible: uploadEmptyPayroll, onCancel: () => setUploadEmptyPayroll(false) },
            React.createElement("p", null,
                "Voc\u00EA est\u00E1 fazendo o upload de uma",
                " ",
                React.createElement("strong", null, "folha vazia! Isso ir\u00E1 demitir todos os funcion\u00E1rios. Esta a\u00E7\u00E3o n\u00E3o poder\u00E1 ser desfeita!")),
            React.createElement("p", null,
                "Por favor, escreva ",
                React.createElement("strong", null, company === null || company === void 0 ? void 0 : company.name),
                " para confirmar."),
            React.createElement(Input, { type: "text", onChange: (e) => setCompanyName(e.target.value), autoFocus: true, allowClear: true }))));
});
export default PayrollImport;
