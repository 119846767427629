import React from "react";
import { useTranslation } from "react-i18next";
import SelectCompany from "../../components/SelectCompany";
import { useStores } from "../../utils/mobx";
import { SectionHeader } from "../../components/SectionHeader";
const CompaniesFiringEmployees = () => {
    const { history } = useStores();
    const { t } = useTranslation();
    function navigateToFiringEmployees(companyId) {
        history.push(`/desligar-funcionarios/${companyId}`);
    }
    return (React.createElement("div", null,
        React.createElement(SectionHeader, { title: t("firingEmployees"), linkToBackButton: "/desligar-funcionarios", info: `<b>${t("firing.chooseCompany")}</b>`, description: t("firing.chooseCompanyDescription") }),
        React.createElement(SelectCompany, { onCompanyClick: navigateToFiringEmployees })));
};
export default CompaniesFiringEmployees;
