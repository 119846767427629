import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { formatMonthYear } from "../../../../shared/utils/date";
import { ButtonGroup } from "../../../../shared/components/ButtonGroup";
import { IconButton } from "../../../../shared/components/IconButton";
import { DownloadIcon } from "../../../../shared/components/Icons/Download";
import { payrollSheetSchema } from "../types";
import s from "../style.scss";
import XlsPicker from "../../../components/XlsPicker";
const LastPayroll = observer(({ onClickDownloadPayroll, lastPayroll, onUploadSheet }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: s.lastUpdateContainer }, lastPayroll && (React.createElement(React.Fragment, null,
        React.createElement("h4", { className: s.title },
            t("lastUpdateLabel"),
            " - ",
            formatMonthYear(lastPayroll.uploadAt)),
        React.createElement(ButtonGroup, { className: s.buttonGroupContainer },
            React.createElement(IconButton, { buttonIcon: () => React.createElement(DownloadIcon, { fill: "#8d8d8d" }), onClick: () => onClickDownloadPayroll(lastPayroll.id, lastPayroll.filename) }, t("downloadSheetUpdatedLabel")),
            React.createElement(XlsPicker, { onChangeValue: onUploadSheet, schema: payrollSheetSchema }))))));
});
export default LastPayroll;
