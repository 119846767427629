import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import SelectCompany from "../../components/SelectCompany";
import { useStores } from "../../utils/mobx";
import { SectionHeader } from "../../components/SectionHeader";
const Payroll = observer(() => {
    const { t } = useTranslation();
    const { history } = useStores();
    function navigateToPayrollImport(companyId) {
        history.push(`/importar-folha/${companyId}`);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: `${t("firing.chooseCompany")}...`, description: React.createElement("p", null, t("hiring.chooseCompanyDescription")) }),
        React.createElement(SelectCompany, { onCompanyClick: navigateToPayrollImport })));
});
export default Payroll;
