import { __rest } from "tslib";
import React from "react";
import { Select as SelectAnt } from "antd";
import s from "./style.scss";
function SelectComp(props) {
    const { options, error, placeholder, loading, size, className } = props, rest = __rest(props, ["options", "error", "placeholder", "loading", "size", "className"]);
    return (React.createElement("div", { className: s.selectWrapper },
        React.createElement(SelectAnt, Object.assign({ className: s.select, placeholder: placeholder, size: "large", loading: loading }, rest), options.map((option) => (React.createElement(SelectAnt.Option, { key: option.value, value: option.value }, option.label))))));
}
export const Select = React.memo(SelectComp);
