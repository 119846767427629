exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".h2___27ffA {\n  font-weight: 700;\n  font-size: 24px;\n  line-height: 29px; }\n\n.h4___13OU_ {\n  font-weight: 700;\n  font-size: 13px; }\n\n.subtitle___1G8Mp {\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 19.5px; }\n\n.body___3Jg4q {\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 22px; }\n\n.modalTitle___4VEGC {\n  font-weight: bold;\n  font-size: 16px; }\n\n.modalBody___2YnQh {\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 24px; }\n", ""]);

// Exports
exports.locals = {
	"h2": "h2___27ffA",
	"h4": "h4___13OU_",
	"subtitle": "subtitle___1G8Mp",
	"body": "body___3Jg4q",
	"modalTitle": "modalTitle___4VEGC",
	"modalBody": "modalBody___2YnQh"
};