import { __rest } from "tslib";
import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { checkEmail } from "../../utils/formValidator";
import { Button, Form, Input, Spin, Alert } from "antd";
import s from "./style.scss";
import { Title } from "../Title";
const InnerEmailConfirmForm = observer((_a) => {
    var { title, form, loading, successMessage, hasEmailDoubleCheck } = _a, props = __rest(_a, ["title", "form", "loading", "successMessage", "hasEmailDoubleCheck"]);
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();
    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((errors, values) => {
            if (!errors) {
                props.handleSubmit(values.email);
            }
        });
    };
    const compareToFirstEmail = (_, emailConfirm, callback) => {
        const email = form.getFieldValue("email");
        if (email && !emailConfirm) {
            callback(t("authentication.confirmEmailText"));
        }
        if (emailConfirm && emailConfirm !== email) {
            callback(t("authentication.emailsDontMatch"));
        }
        else {
            callback();
        }
    };
    return (React.createElement(Form, { className: s.form, layout: "vertical", hideRequiredMark: true, onSubmit: handleSubmit },
        React.createElement(Spin, { spinning: loading },
            React.createElement(Title, { title: title || t("authentication.insertEmailText") }),
            React.createElement(Form.Item, { label: "E-mail" }, getFieldDecorator("email", {
                rules: [{ validator: checkEmail }],
            })(React.createElement(Input, { autoFocus: true, allowClear: true }))),
            hasEmailDoubleCheck && (React.createElement(Form.Item, { label: t("authentication.confirmEmailText") }, getFieldDecorator("emailConfirm", {
                rules: [{ validator: compareToFirstEmail }],
            })(React.createElement(Input, { allowClear: true })))),
            React.createElement(Form.Item, null, successMessage && React.createElement(Alert, { type: "success", message: successMessage, showIcon: true })),
            React.createElement(Form.Item, { className: s.centerContainer },
                React.createElement(Button, { type: "primary", htmlType: "submit" }, t("next"))))));
});
export const EmailConfirmForm = Form.create({ name: "email_confirm_form" })(InnerEmailConfirmForm);
