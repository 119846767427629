import { __awaiter } from "tslib";
import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import "../../styles/antd.less";
import { useStores } from "../../utils/mobx";
import LoanApplication from "../LoanApplications";
import PayRollReport from "../PayrollReport";
import { Authentication } from "../Authentication";
import { Main } from "../Main";
import { CompanyNotFound } from "../404";
import Contracts from "../Contracts";
import CompaniesPayrollImport from "../Payroll";
import PayrollImport from "../Payroll/PayrollImport";
import PayrollConflict from "../PayrollConflict";
import ChangePassword from "../Authentication/ChangePassword";
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n";
import ContactUs from "../ContactUs";
import Lottie from "react-lottie";
import s from "./style.scss";
import animationData from "../../../shared/assets/35756-user-profile-man.json";
import { CredifitLogo } from "../../../shared/components/Icons/CredifitLogo";
import { useClearCacheCtx } from "react-clear-cache";
import { ClearCache } from "../../../shared/components/ClearCache";
import Agreement from "../Agreement";
import CompaniesFiringEmployees from "../CompaniesFiringEmployees";
import FiringEmployees from "../FiringEmployees";
import Proposals from "../Proposals";
export const AppComp = () => {
    const { authStore, generalStore } = useStores();
    const { isLatestVersion } = useClearCacheCtx();
    if (!isLatestVersion) {
        return React.createElement(ClearCache, null);
    }
    if (generalStore.getSelectedLanguageFromStore() !== null) {
        i18n.changeLanguage(generalStore.getSelectedLanguageFromStore());
    }
    if (!authStore.isLoggedIn) {
        return (React.createElement(I18nextProvider, { i18n: i18n },
            React.createElement(Authentication, null)));
    }
    const [loading, setLoading] = React.useState(true);
    const reathenticate = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield authStore.updateUserFromApi();
        }
        catch (err) {
            yield authStore.logout();
        }
        finally {
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    }), []);
    React.useEffect(() => {
        reathenticate();
    }, [reathenticate]);
    return (React.createElement(React.Fragment, null, !loading ? (React.createElement(I18nextProvider, { i18n: i18n },
        React.createElement(Main, null,
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/", component: LoanApplication, exact: true }),
                React.createElement(Route, { path: "/relatorio-folha", component: PayRollReport, exact: true }),
                React.createElement(Route, { path: "/contratos", component: Contracts, exact: true }),
                React.createElement(Route, { path: "/propostas", component: Proposals, exact: true }),
                React.createElement(Route, { path: "/desligar-funcionarios", component: CompaniesFiringEmployees, exact: true }),
                React.createElement(Route, { path: "/desligar-funcionarios/:companyId", component: FiringEmployees, exact: true }),
                React.createElement(Route, { path: "/importar-folha", component: CompaniesPayrollImport, exact: true }),
                React.createElement(Route, { path: "/importar-folha/:companyId", component: PayrollImport }),
                React.createElement(Route, { path: "/atualizacoes-importar-folha/:companyId", component: PayrollConflict, exact: true }),
                React.createElement(Route, { path: "/alterar-senha", component: ChangePassword, exact: true }),
                React.createElement(Route, { path: "/contato", component: ContactUs, exact: true }),
                React.createElement(Route, { path: "/convenio", component: Agreement, exact: true }),
                React.createElement(Route, { component: CompanyNotFound }))))) : (React.createElement("div", { className: s.containerLoading },
        React.createElement(Lottie, { options: {
                loop: true,
                autoplay: true,
                animationData,
                rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                },
            }, height: 250, width: 250, isStopped: false, isPaused: false }),
        React.createElement(CredifitLogo, { width: 158, height: 42 }),
        React.createElement("div", { className: s.titleLoading }, "Autenticando...")))));
};
export const App = withRouter(AppComp);
