import { __awaiter } from "tslib";
import { api } from "../index";
export class FiringsStore {
    constructor() {
        this.getCompanyFirings = (companyId, page, rowsPerPage) => __awaiter(this, void 0, void 0, function* () { return api.getCompanyFirings({ companyId, page, rowsPerPage }); });
        this.getFiringEmployeesPreview = (companyId, usersIds) => __awaiter(this, void 0, void 0, function* () { return api.getFiringEmployeesPreview({ companyId, usersIds }); });
        this.fireEmployees = (companyId, firings) => __awaiter(this, void 0, void 0, function* () { return api.fireEmployees({ companyId, firings }); });
        this.simulateFireEmployees = (companyId, firing) => __awaiter(this, void 0, void 0, function* () {
            return api.simulateFireEmployee({ companyId, firing });
        });
    }
}
