import React from "react";
import { observer } from "mobx-react-lite";
import { SectionHeader } from "../../components/SectionHeader";
import s from "./style.scss";
import { useTranslation } from "react-i18next";
import { Row } from "antd";
import { ContactUsCard } from "../../components/ContactUsCard";
const ContactUs = observer(() => {
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { className: s.header, title: t("contactUs.contactUsLabel") }),
        React.createElement(Row, { gutter: 15 },
            React.createElement(ContactUsCard, { icon: "suporte", title: t("contactUs.suportTitle"), textLink: t("contactUs.suportLink"), link: `https://${t("contactUs.suportLink")}` }),
            React.createElement(ContactUsCard, { icon: "email", title: t("contactUs.emailTitle"), textLink: t("contactUs.email"), link: `mailto:${t("contactUs.email")}` }),
            React.createElement(ContactUsCard, { icon: "site", title: t("contactUs.websiteTitle"), textLink: t("contactUs.website"), link: `https://${t("contactUs.website")}` })),
        React.createElement("div", { style: { marginTop: 50 } },
            React.createElement(SectionHeader, { className: s.header, title: t("contactUs.socialMediaTitle") })),
        React.createElement(Row, null,
            React.createElement(ContactUsCard, { icon: "twitter", title: t("contactUs.twitter"), textLink: t("contactUs.twitterNickname"), link: t("contactUs.twitterLink") }),
            React.createElement(ContactUsCard, { icon: "instagram", title: t("contactUs.instagram"), textLink: t("contactUs.instagramNickname"), link: t("contactUs.instagramLink") }),
            React.createElement(ContactUsCard, { icon: "facebook", title: t("contactUs.facebook"), textLink: t("contactUs.facebookNickname"), link: t("contactUs.facebookLink") }),
            React.createElement(ContactUsCard, { icon: "linkedin", title: t("contactUs.linkedin"), textLink: t("contactUs.linkedinNickname"), link: t("contactUs.linkedinLink") }))));
});
export default ContactUs;
