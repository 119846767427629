import React from "react";
import { DatePicker, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { formatCurrencyNoPrefix, toInt } from "../../../../shared/utils/formatters";
import { firingRequiredFieldsIsFilled, } from "../../../containers/PayrollConflict/types";
import { updateConflictValue } from "../../../utils/payrollConflict.utils";
import styles from "./style.scss";
const FiringConflictForm = ({ firingsInfo, setFiringsInfo, userId, }) => {
    const { t } = useTranslation();
    const dateIsFilled = () => {
        const firingInfo = firingsInfo.find((resignationInfo) => resignationInfo.userId === userId);
        return firingRequiredFieldsIsFilled(firingInfo);
    };
    const handleResignationDateChange = (resignationDate) => {
        setFiringsInfo((oldValue) => updateConflictValue({
            field: "resignationDate",
            conflictsValue: oldValue,
            userId,
            value: resignationDate,
        }));
    };
    const handlePaymentDateChange = (rescissionPaymentDate) => {
        setFiringsInfo((oldValue) => updateConflictValue({
            field: "rescissionPaymentDate",
            conflictsValue: oldValue,
            userId,
            value: rescissionPaymentDate,
        }));
    };
    const handleRescissionValueChange = (rescissionValue) => {
        setFiringsInfo((oldValue) => updateConflictValue({
            field: "rescissionValue",
            conflictsValue: oldValue,
            userId,
            value: rescissionValue !== null && rescissionValue !== void 0 ? rescissionValue : 0,
        }));
    };
    return (React.createElement("div", { className: dateIsFilled() ? styles.dateFilledWrapper : styles.dateWrapper },
        React.createElement("div", null,
            React.createElement(DatePicker, { onChange: (date) => {
                    handleResignationDateChange(date);
                }, placeholder: t("firing.resignationDate"), format: "DD/MM/YYYY" })),
        React.createElement("div", null,
            React.createElement(InputNumber, { formatter: (value) => (value ? formatCurrencyNoPrefix(`${value}`) : ""), parser: (value) => toInt(value), placeholder: t("firing.rescissionValue"), onChange: (value) => handleRescissionValueChange(value) })),
        React.createElement("div", null,
            React.createElement(DatePicker, { placeholder: t("firing.rescissionPaymentDate"), format: "DD/MM/YYYY", onChange: (date) => {
                    handlePaymentDateChange(date);
                } }))));
};
export default FiringConflictForm;
