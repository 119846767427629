const baseConfig = {
    apiKey: "AIzaSyBIfaER8CkjHgTdIhd6mybl_5lQ3mEwTwg",
    authDomain: "credifit-33cb9.firebaseapp.com",
    databaseURL: "https://credifit-33cb9.firebaseio.com",
    projectId: "credifit-33cb9",
    storageBucket: "credifit-33cb9.appspot.com",
    messagingSenderId: "223699317955",
};
export const FIREBASE_CONFIG_ADMIN = Object.assign(Object.assign({}, baseConfig), { appId: "1:223699317955:web:f9c48eefde48f26d219e76", measurementId: "G-5PZ4HXDGKC" });
export const FIREBASE_CONFIG_PORTAL = Object.assign(Object.assign({}, baseConfig), { appId: "1:223699317955:web:af35ec25a7172f60219e76", measurementId: "G-D7BZHW4T6L" });
