import { __awaiter } from "tslib";
import i18n from "i18next";
export const RequestFn = (request) => __awaiter(void 0, void 0, void 0, function* () {
    const resp = {
        status: "pending",
        message: null,
        data: null,
        errorType: null,
    };
    try {
        resp.data = yield request();
        resp.status = "success";
    }
    catch (e) {
        const error = e;
        resp.status = "error";
        resp.message = error.message;
        resp.errorType = error.type;
    }
    return resp;
});
export const defaultConfirmProps = {
    okText: i18n.t("confirmLabel"),
    cancelText: i18n.t("cancelLabel"),
    icon: null,
};
export const capitalize = (value) => { var _a; return ((_a = value.charAt(0)) === null || _a === void 0 ? void 0 : _a.toUpperCase()) + value.slice(1); };
export const getUserInitials = (name) => {
    const names = name.split(" ");
    const usefulNames = [names[0], names.length > 1 ? names[names.length - 1] : " "];
    const initials = usefulNames.reduce((acc, curr) => { var _a; return (acc += (_a = curr[0]) === null || _a === void 0 ? void 0 : _a.toUpperCase()); }, "").trim();
    return initials;
};
