import React from "react";
import { Redirect } from "react-router";
import { Switch, Route, withRouter } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { CredifitLogo } from "../../../shared/components/Icons/CredifitLogo";
import { Register } from "./Register";
import { RecoveryPassword } from "./RecoveryPassword";
import s from "./style.scss";
import { RedefinePassword } from "./RedefinePassword";
import { Login } from "./Login";
export const AuthenticationComp = observer(() => {
    return (React.createElement("div", { className: s.loginContainer },
        React.createElement("div", { className: s.loginImage }),
        React.createElement("div", { className: s.formContainer },
            React.createElement("div", { className: s.logo },
                React.createElement(CredifitLogo, { height: 102 })),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/login", component: Login, exact: true }),
                React.createElement(Route, { path: "/cadastro/:id", component: Register, exact: true }),
                React.createElement(Route, { path: "/recuperar-senha/:id", component: RedefinePassword, exact: true }),
                React.createElement(Route, { path: "/esqueci-minha-senha", component: RecoveryPassword, exact: true }),
                React.createElement(Route, { component: () => React.createElement(Redirect, { to: "/login" }) })))));
});
export const Authentication = withRouter(React.memo(AuthenticationComp));
