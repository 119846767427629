import { Form, Input, Modal, Spin } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import InputCurrency from "../../../shared/components/InputCurrency";
import { CustomSlider } from "../../../shared/components/Slider";
import { Typograph } from "../../../shared/components/Typograph";
import { formatCurrency } from "../../../shared/utils/formatters";
import s from "./style.scss";
const LoanValueModal = ({ form, visible, onOk, onCancel, requestedAmountKey, onChangeLoanValue, nameUser, cpf, minimumValue, maximumValue, sliderValue, loading, }) => {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const { t } = useTranslation();
    React.useEffect(() => {
        setFieldsValue({ [requestedAmountKey]: sliderValue });
    }, [sliderValue, setFieldsValue, requestedAmountKey]);
    const handleSliderChange = (value) => {
        setFieldsValue({ [requestedAmountKey]: value });
        onChangeLoanValue(value);
    };
    const handleInputCurrencyChange = (value) => {
        setFieldsValue({ [requestedAmountKey]: value });
        onChangeLoanValue(value);
    };
    const handleOk = () => {
        const requestedAmount = getFieldValue(requestedAmountKey);
        onOk(requestedAmount);
    };
    return (React.createElement(Modal, { visible: visible, title: React.createElement("div", { className: s.modalHeaderDialogLoan },
            React.createElement("h2", { className: s.textTitleDialogLoan }, "DEFINIR VALOR")), className: s.containerDialog, okText: t("simulationLabel"), cancelText: t("backLabel"), closable: false, width: 463, centered: true, maskClosable: true, style: { height: "500px" }, okButtonProps: {
            icon: "check",
            type: "primary",
            disabled: false,
            id: "simulate-installments-button",
        }, onOk: handleOk, onCancel: onCancel },
        React.createElement(Form, { form: form },
            React.createElement(Spin, { spinning: loading },
                React.createElement("div", { className: s.modal },
                    React.createElement("div", { className: s.labelCpfValue },
                        React.createElement(Typograph, { className: s.textCpf, variant: "modalTitle" }, "CPF"),
                        React.createElement(Input, { "data-cy": "valueCpfLoanDataCy", allowClear: true, value: cpf, disabled: true })),
                    React.createElement("div", { className: s.labelName },
                        React.createElement(Typograph, { className: s.textName, variant: "modalTitle" }, "Nome"),
                        React.createElement(Input, { "data-cy": "valueNameUserLoanDataCy", allowClear: true, value: nameUser, disabled: true })),
                    React.createElement("div", { className: s.labelBodyInformation },
                        React.createElement("div", { className: s.textBodyInformation }, "Informe abaixo o valor do empr\u00E9stimo")),
                    React.createElement("div", { className: s.bodyInputValue },
                        React.createElement(Form.Item, null,
                            getFieldDecorator(requestedAmountKey, {
                                initialValue: 10000,
                                rules: [{ required: true, message: "Campo obrigatório" }],
                            })(React.createElement(InputCurrency, { className: s.sliderInput, min: minimumValue, step: 1, max: maximumValue, formatter: (value) => formatCurrency(`${value}`), onChange: handleInputCurrencyChange })),
                            React.createElement(CustomSlider, { className: s.slider, min: minimumValue, step: 1, max: maximumValue, value: getFieldValue(requestedAmountKey), onChange: handleSliderChange }))))))));
};
export default Form.create()(LoanValueModal);
