import { createDomainInfo } from "./domainInfoFactory";
/**
 * Factory method to create new instances of ApiClient <companyApi | adminApi>
 *
 * Just use:
 *  const api = new ApiFactory().create(ApiClient, "company");
 */
export class ApiFactory {
    /**
     * Create a new instance of ApiClient
     * @param ApiClient companyApi || adminApi
     * @param portal admin || company
     */
    create(apiClient, portal) {
        const domainInfo = createDomainInfo(portal);
        return new apiClient(domainInfo.baseUrl);
    }
}
