import { format } from "date-fns";
import pt from "date-fns/locale/pt";
import en from "date-fns/locale/en";
import { capitalize } from ".";
import i18n from "../../admin/i18n";
import moment from "moment-timezone";
import { TIMEZONE_SP } from "../constants/systemEnvironmet.contants";
export const getYearFromDateString = (date) => new Date(date).getFullYear();
export var DateFormat;
(function (DateFormat) {
    DateFormat["ISO"] = "YYYY-MM-DD";
    DateFormat["BR"] = "DD/MM/YYYY";
    DateFormat["FILE"] = "DD_MM_YYYY";
    DateFormat["ABR"] = "MMM/YY";
    DateFormat["BR_WITH_TIME"] = "DD/MM/YYYY - hh:mm:ss";
    DateFormat["BR_SHORT_YEAR"] = "DD/MM/YY";
})(DateFormat || (DateFormat = {}));
export const getMonths = (fromDate, toDate) => {
    if (toDate < fromDate) {
        toDate = fromDate;
    }
    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const months = [];
    for (let year = fromYear; year <= toYear; year++) {
        const endMonth = year !== toYear ? 11 : toMonth;
        const startMonth = year === fromYear ? fromMonth : 0;
        for (let month = startMonth; month <= endMonth; month = month > 12 ? month % 12 || 11 : month + 1) {
            const nextMonth = month + 1;
            months.push({ month: nextMonth, year });
        }
    }
    return months;
};
export const formatDate = (date, myFormat) => format(date, myFormat, { locale: i18n.language === "br" ? pt : en });
export const formatFullDateTime = (date) => {
    const parts = formatDate(date, i18n.t("updatesDate")).split(" ");
    const month = parts[2];
    parts[2] = capitalize(month);
    return parts.join(" ");
};
export const formatMonthYear = (date) => capitalize(formatDate(date, i18n.t("lastUpdateDate")));
export const monthNames = [
    null,
    i18n.t("januaryLabel"),
    i18n.t("februaryLabel"),
    i18n.t("marsLabel"),
    i18n.t("aprilLabel"),
    i18n.t("mayLabel"),
    i18n.t("juneLabel"),
    i18n.t("julyLabel"),
    i18n.t("augustLabel"),
    i18n.t("septemberLabel"),
    i18n.t("octoberLabel"),
    i18n.t("novemberLabel"),
    i18n.t("decemberLabel"),
    ,
];
/**
 * Convert data or string date to moment object
 * @param date date to be converted
 */
export function toMoment(date) {
    if (date) {
        return moment(date).tz(TIMEZONE_SP);
    }
    return null;
}
/**
 * Get only Date (without hour) from Local timezone (non-utc)
 * @param date date to be converted
 * @param dateFormat format that the date will be returned
 */
export function getDateLocalTimezoneFormat(date, dateFormat = DateFormat.BR) {
    const dateParsedToMoment = toMoment(date);
    if (dateParsedToMoment) {
        return dateParsedToMoment.tz(TIMEZONE_SP).format(dateFormat);
    }
    return "--";
}
/**
 * Returns today's formatted date
 * @param dateFormat format that the date will be returned
 */
export function getTodayDateLocalTimezoneFormat(dateFormat = DateFormat.BR) {
    return getToday().format(dateFormat);
}
/**
 * Returns the current UTC date
 */
export function getToday() {
    return moment().tz(TIMEZONE_SP);
}
export function getFirstDayInNextMonth() {
    const now = new Date();
    let date = now;
    if (now.getMonth() === 11) {
        date = new Date(now.getFullYear() + 1, 0, 1);
    }
    else {
        date = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    }
    return date;
}
export function getDurationFromNow(finalDate) {
    const finalDateMoment = moment.utc(finalDate);
    const nowMoment = moment().utc();
    const duration = moment.duration(finalDateMoment === null || finalDateMoment === void 0 ? void 0 : finalDateMoment.diff(nowMoment.utc()));
    return duration;
}
export function getDurationFromNowAsHours(dueDate) {
    if (!dueDate) {
        throw Error("Parâmetro inválido. O parâmetro dueDate não pode ser nulo.");
    }
    const duration = getDurationFromNow(dueDate);
    const normalizedHours = duration.asHours();
    if (normalizedHours <= 0) {
        return 0;
    }
    return normalizedHours.toFixed(0);
}
export function parseStringToMoment(dateString) {
    return moment.utc(dateString);
}
export function parseMomentToDate(momentDate) {
    if (momentDate) {
        return momentDate.toDate();
    }
    return null;
}
export function parseMomentToString(momentDate) {
    if (momentDate) {
        return moment.utc(momentDate).format();
    }
    return null;
}
export function parseToBigInt(value) {
    return BigInt((value === null || value === void 0 ? void 0 : value.toString()) || 0);
}
export function getCurrentYear() {
    return getToday().year();
}
export function isAfterTodayDayPrecision(date) {
    return date.isAfter(getToday(), "day");
}
export function getDateIsoFormat(date) {
    return moment(date).format(DateFormat.ISO);
}
