import React from "react";
const getContractsStatusLabels = (t) => {
    return [
        {
            value: "pendingHr",
            label: t("contracts.pendingHrLabel"),
        },
        {
            value: "pendingCredifit",
            label: t("contracts.pendingCredifitLabel"),
        },
        {
            value: "deniedHr",
            label: t("contracts.deniedHrLabel"),
        },
        {
            value: "deniedCredifit",
            label: t("contracts.deniedCredifitLabel"),
        },
        {
            value: "canceledByUser",
            label: React.createElement("span", { "data-cy": "canceledByUser-data-cy" }, t("contracts.canceledByUserLabel")),
        },
        {
            value: "canceledByCredifit",
            label: (React.createElement("span", { "data-cy": "canceledByCredifit-data-cy" }, t("contracts.canceledByCredifitLabel"))),
        },
        {
            value: "closed",
            label: t("contracts.closedLabel"),
        },
    ];
};
export const getCompanyContractsStatusLabels = (t) => {
    return [
        {
            value: "active",
            label: t("contracts.activeCompanyLabel"),
        },
        ...getContractsStatusLabels(t),
        {
            value: "",
            label: "Status",
        },
    ];
};
const sortLabels = (firstItem, secondItem) => firstItem.label.toString().localeCompare(secondItem.label.toString());
export const getAdminContractsStatusLabel = (t) => {
    const labels = getContractsStatusLabels(t);
    return [
        {
            value: "active",
            label: t("contracts.activeLabel"),
        },
        ...labels,
        {
            value: "given",
            label: t("contracts.givenLabel"),
        },
        {
            value: "partialGiven",
            label: t("contracts.partialGivenLabel"),
        },
        {
            value: "pendingCession",
            label: React.createElement("span", { "data-cy": "pendingCession-data-cy" }, t("contracts.pendingCessionLabel")),
        },
        {
            value: "pendingIdentityValidations",
            label: (React.createElement("span", { "data-cy": "pendingIdentityValidations-data-cy" }, t("contracts.pendingIdentityValidationsLabel"))),
        },
    ].sort(sortLabels);
};
export const getStatusLabels = (t) => ({
    active: t("contracts.activeCompanyLabel"),
    pendingHr: t("contracts.pendingHrLabel"),
    pendingCredifit: t("contracts.pendingCredifitLabel"),
    deniedHr: t("contracts.deniedHrLabel"),
    deniedCredifit: t("contracts.deniedCredifitLabel"),
    closed: t("contracts.closedLabel"),
    canceledByUser: t("contracts.canceledByUserLabel"),
    canceledByCredifit: t("contracts.canceledByCredifitLabel"),
    partialGiven: t("contracts.partialGivenLabel"),
    pendingCession: t("contracts.pendingCessionLabel"),
    given: t("contracts.givenLabel"),
    pendingIdentityValidations: t("contracts.pendingIdentityValidationsLabel"),
});
export const getStatusAdminLabels = (t) => (Object.assign(Object.assign({}, getStatusLabels(t)), { active: t("contracts.activeLabel") }));
