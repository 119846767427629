import { __awaiter } from "tslib";
import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../utils/mobx";
import { LoginForm } from "../../../../shared/components/LoginForm";
export const Login = observer(() => {
    const { authStore, generalStore: { catchErrors }, } = useStores();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();
    const login = (email, password) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            yield authStore.login(email.toLowerCase(), password);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("authentication.unauthorizedLogin"));
        }
        finally {
            setLoading(false);
        }
    });
    return (React.createElement(LoginForm, { loading: loading, handleLogin: login, setErrorMessage: setErrorMessage, error: errorMessage }));
});
