import { __awaiter } from "tslib";
import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { useStores } from "../../utils/mobx";
import { formatCnpj, formatCpf, formatStringCurrencyNoPrefix, zeroFill, } from "../../../shared/utils/formatters";
import ActionTable from "../../../shared/components/ActionTable";
import { SectionHeader } from "../../components/SectionHeader";
import { HeaderColumn } from "../../../shared/components/HeaderColumn";
import { SectionContainer } from "../../../shared/components/SectionContainer";
import { SectionFooter } from "../../../shared/components/SectionFooter";
import { Pagination } from "../../../shared/components/Pagination";
import { defaultConfirmProps } from "../../../shared/utils";
import { filterOption } from "../../../shared/utils/select";
import { ListColumnInfo } from "../../../shared/components/ListColumnInfo";
import { FilterForm } from "../../../shared/components/FilterForm";
import { mapCompaniesToSelectOptions } from "../../../shared/utils/mappers";
import s from "./style.scss";
import { getDateLocalTimezoneFormat } from "../../../shared/utils/date";
import { BlockUserForm } from "../../../shared/components/BlockUserForm";
import TooltipDisplay from "../../../shared/components/TooltipDisplay";
const LoanApplication = observer(() => {
    var _a;
    const { t, ready } = useTranslation("", { useSuspense: false });
    const { authStore } = useStores();
    const items = [
        {
            key: "companies",
            name: "companies",
            type: "select",
            placeholder: "Empresa",
            selectOptions: mapCompaniesToSelectOptions((_a = authStore.user) === null || _a === void 0 ? void 0 : _a.companies),
            mode: "multiple",
            props: {
                filterOption,
            },
        },
    ];
    const columns = [
        {
            title: t("nameLabel"),
            dataIndex: "userName",
            key: "userName",
            width: "250px",
        },
        {
            title: "CPF",
            dataIndex: "cpf",
            key: "cpf",
        },
        {
            title: t("companyLabel"),
            dataIndex: "companyDisplay",
            key: "companyDisplay",
            width: "210px",
        },
        {
            title: (React.createElement(HeaderColumn, { title: React.createElement("div", null,
                    React.createElement("div", null, t("remunerationLabel")),
                    " ",
                    t("availableLabel"),
                    " (R$)"), description: t("availableRemunerationDescriptionText") })),
            dataIndex: "salary",
            key: "salary",
            align: "right",
        },
        {
            title: (React.createElement(HeaderColumn, { title: React.createElement("div", null,
                    React.createElement("div", null, t("valueLabel")),
                    " ",
                    t("fundedLabel"),
                    " (R$)"), description: t("requestedValueDescriptionText") })),
            dataIndex: "valueRequested",
            key: "valueRequested",
            align: "right",
        },
        {
            title: React.createElement(HeaderColumn, { title: "%", description: t("percentualInfoText") }),
            dataIndex: "percentage",
            key: "percentage",
            align: "right",
        },
        {
            title: t("contractModalInstallmentsLabel"),
            dataIndex: "numberOfInstallments",
            key: "installments",
            align: "right",
        },
        {
            title: "Data de solicitação",
            dataIndex: "emittedDate",
            key: "emittedDate",
            align: "right",
        },
    ];
    const loanLabels = {
        cpf: "CPF",
        salary: `${t("loanApplication.salary")}(R$)`,
        totalAmount: `${t("loanApplication.totalAmount")}(R$)`,
        percentage: `${t("loanApplication.percentual")}(%)`,
        numberOfInstallments: t("loanApplication.installmentsLabel"),
        emittedDate: t("loanApplication.emittedDate"),
        ccbTotalValue: `${t("loanApplication.ccbTotalValue")}(R$)`,
        netValueCreditedToIssuer: `${t("loanApplication.netValueCreditedToIssuer")}(R$)`,
        installmentsValue: `${t("loanApplication.installmentsValue")}(R$)`,
        firstInstallmentDue: t("loanApplication.firstInstallmentDue"),
        lastInstallmentDue: t("loanApplication.lastInstallmentDue"),
        monthlyInterestRate: `${t("loanApplication.monthlyInterestRate")}(%)`,
        monthlyEffectiveCost: t("loanApplication.monthlyEffectiveCost"),
        admissionDate: t("loanApplication.admissionDate"),
    };
    const confirm = Modal.confirm;
    const statusMessages = {
        approved: {
            confirmTile: (total) => ` ${t("acceptLabel")} ${zeroFill(2, total)} ${total > 1 ? t("requestPluralLabel") : t("requestLabel")} `,
            confirmContent: (total) => ` ${t("confirmationAcceptText")} ${total > 1 ? t("everyRequestText") : t("singleRequestText")}?`,
            successMessage: (total) => `${zeroFill(2, total)} ${total > 1
                ? t("acceptedRequestsConfirmationMessage")
                : t("acceptedSingleRequestConfirmationMessage")} ${t("confirmationSuccessfullyText")}`,
        },
        denied: {
            confirmTile: (total) => `${t("denyLabel")} ${zeroFill(2, total)} ${total > 1 ? t("requestPluralLabel") : t("requestLabel")} `,
            confirmContent: (total) => (React.createElement("div", null,
                React.createElement("div", null, `${t("confirmationDenyText")} ${total > 1 ? t("everyRequestText") : t("singleRequestText")}?`),
                React.createElement("div", null,
                    React.createElement(BlockUserForm, { onChange: onChange })))),
            successMessage: (total) => `${zeroFill(2, total)} ${total > 1
                ? t("deniedRequestsConfirmationMessage")
                : t("deniedSingleRequestConfirmationMessage")} ${t("confirmationSuccessfullyText")}`,
        },
    };
    const { generalStore: { setErrorMessage, setSuccessMessage, clearMessage, catchErrors }, loanApplicationStore, } = useStores();
    const [loading, setLoading] = React.useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [loanRequests, setLoanRequests] = useState([]);
    const [totalRequests, setTotalRequests] = useState(0);
    const [selectedRequestsKeys, setSelectedRequestsKeys] = useState([]);
    const [currentFilter, setCurrentFilter] = useState(null);
    const [blockTemporaryUser, setBlockTemporaryUser] = useState(null);
    const blockTemporaryUserRef = useRef(null);
    blockTemporaryUserRef.current = blockTemporaryUser;
    const getLoanRequests = (currentPageRequests, rowsPerPageRequests, filter) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const response = yield loanApplicationStore.getLoanRequests(currentPageRequests, rowsPerPageRequests, filter);
            setLoanRequests(response.requests);
            setCurrentPage(response.currentPage);
            setRowsPerPage(response.rowsPerPage);
            const total = yield loanApplicationStore.getLoanRequestTotal(filter || null);
            setTotalRequests(total);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("loanApplicationsApiErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    React.useEffect(() => {
        getLoanRequests(currentPage, rowsPerPage);
    }, [currentPage, rowsPerPage]);
    const showDynamicInfos = (loan) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const { dynamicData: { creditInfo }, } = yield loanApplicationStore.getCcbData(loan.id);
            setLoading(false);
            confirm(Object.assign(Object.assign({}, defaultConfirmProps), { title: (React.createElement("div", { className: s.modalHeaderDialog },
                    React.createElement("h2", { className: s.modalTitleDialog }, loan.userName),
                    React.createElement("div", { className: s.companyDialog },
                        loan.company.name,
                        " - ",
                        formatCnpj(loan.company.cnpj)))), content: (React.createElement("div", { className: s.modal },
                    React.createElement("div", { className: s.modalBodyDialog },
                        React.createElement(ListColumnInfo, { labels: [
                                loanLabels.cpf,
                                loanLabels.salary,
                                loanLabels.totalAmount,
                                loanLabels.percentage,
                                loanLabels.numberOfInstallments,
                                loanLabels.emittedDate,
                                loanLabels.admissionDate,
                            ], values: [
                                loan.cpf,
                                formatStringCurrencyNoPrefix(loan.salary),
                                formatStringCurrencyNoPrefix(creditInfo.totalValue),
                                loan.percentage,
                                creditInfo.numberOfInstallments,
                                creditInfo.emittedDate,
                                getDateLocalTimezoneFormat(loan.admissionDate),
                            ] }),
                        React.createElement("div", { className: s.separation }),
                        React.createElement(ListColumnInfo, { labels: [
                                loanLabels.ccbTotalValue,
                                loanLabels.netValueCreditedToIssuer,
                                loanLabels.installmentsValue,
                                loanLabels.firstInstallmentDue,
                                loanLabels.lastInstallmentDue,
                                loanLabels.monthlyInterestRate,
                                loanLabels.monthlyEffectiveCost,
                            ], values: [
                                formatStringCurrencyNoPrefix(creditInfo.loanTotalValue),
                                formatStringCurrencyNoPrefix(creditInfo.netValueCreditedToIssuer),
                                formatStringCurrencyNoPrefix(creditInfo.installmentsValue),
                                creditInfo.firstInstallmentDue,
                                creditInfo.lastInstallmentDue,
                                creditInfo.monthlyInterestRate,
                                creditInfo.monthlyEffectiveCost,
                            ] })))), className: s.containerDialog, width: "50%", maskClosable: true, onOk: () => handleSelectChange([...selectedRequestsKeys, loan.id], null), okText: "Selecionar", okButtonProps: {
                    icon: "check",
                    type: "primary",
                    disabled: selectedRequestsKeys.includes(loan.id),
                }, cancelText: "Fechar" }));
        }
        catch (error) {
            catchErrors(error, setErrorMessage, "Oops, ocorreu um erro inesperado.");
        }
        finally {
            setLoading(false);
        }
    });
    const handleSelectChange = (selectedRowKeys, _) => {
        setSelectedRequestsKeys(selectedRowKeys);
    };
    const onChange = (shouldBlock) => {
        setBlockTemporaryUser(shouldBlock);
    };
    const handleOpenConfirm = (status, onOk, selectedKeys) => {
        clearMessage();
        const totalSelectedApplications = selectedKeys ? selectedKeys.length : 0;
        if (selectedKeys === undefined || totalSelectedApplications === 0) {
            setErrorMessage(t("emptySelectionText"));
        }
        else {
            confirm(Object.assign(Object.assign({}, defaultConfirmProps), { title: statusMessages[status].confirmTile(selectedKeys.length), content: statusMessages[status].confirmContent(selectedKeys.length), onOk }));
        }
    };
    const changeStatusFromLoanRequest = (status, currentPageRequests, rowsPerPageRequests, selectedRequestsKeysRequests, numberOfDaysToBlockUser) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        if (selectedRequestsKeysRequests) {
            try {
                yield loanApplicationStore.changeStatusFromLoanRequest(status, selectedRequestsKeysRequests, currentFilter, numberOfDaysToBlockUser);
                yield getLoanRequests(currentPageRequests, rowsPerPageRequests);
                setSelectedRequestsKeys([]);
                setSuccessMessage(statusMessages[status].successMessage(selectedRequestsKeysRequests.length));
            }
            catch (error) {
                catchErrors(error, setErrorMessage, "Oops, ocorreu um erro inesperado.");
            }
            finally {
                setLoading(false);
            }
        }
    });
    const applyFilter = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const filter = { companiesId: (values === null || values === void 0 ? void 0 : values.companies) || null };
        setCurrentFilter(filter);
        getLoanRequests(currentPage, rowsPerPage, filter);
    });
    const hasLoanRequests = !loading && loanRequests.length > 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("loanApplicationTitle") }),
        React.createElement(SectionContainer, { className: s.container },
            React.createElement(FilterForm, { items: items, handleSubmit: applyFilter }),
            React.createElement(ActionTable, { actionsOutsideFooter: true, locale: { emptyText: t("emptyLoanApplicationText") }, className: s.table, loading: loading && ready, rowClassName: () => {
                    if (s.rowClick) {
                        return s.rowClick;
                    }
                    return "";
                }, onRow: (loan) => {
                    return {
                        onClick: () => showDynamicInfos(loan),
                    };
                }, rowSelection: {
                    selectedRowKeys: selectedRequestsKeys,
                    onChange: handleSelectChange,
                }, dataSource: loanRequests.map((loan) => (Object.assign(Object.assign({}, loan), { companyDisplay: (React.createElement(React.Fragment, null,
                        loan.company.name,
                        React.createElement(TooltipDisplay, { classname: s.tooltipContainer, title: React.createElement("div", { className: s.tooltipContainer },
                                React.createElement("p", null,
                                    "CNPJ: ",
                                    formatCnpj(loan.company.cnpj))) }))), cpf: formatCpf(loan.cpf), salary: formatStringCurrencyNoPrefix(loan.salary), valueRequested: formatStringCurrencyNoPrefix(loan.valueRequested), key: loan.id }))), columns: columns, actions: [
                    {
                        value: "approved",
                        label: "acceptRequestLabel",
                        onClick: (selectedKeys, currentPageRequests) => {
                            handleOpenConfirm("approved", () => {
                                changeStatusFromLoanRequest("approved", currentPageRequests, rowsPerPage, selectedKeys);
                            }, selectedKeys);
                        },
                    },
                    {
                        value: "denied",
                        label: "denyRequestLabel",
                        onClick: (selectedKeys, currentPageRequests) => {
                            handleOpenConfirm("denied", () => {
                                var _a;
                                changeStatusFromLoanRequest("denied", currentPageRequests, rowsPerPage, selectedKeys, (_a = blockTemporaryUserRef.current) !== null && _a !== void 0 ? _a : undefined);
                            }, selectedKeys);
                        },
                    },
                ], scroll: { y: 460, x: 1800 } })),
        React.createElement(SectionFooter, null, hasLoanRequests && (React.createElement(Pagination, { current: currentPage, total: totalRequests, pageSize: rowsPerPage, onChange: (page) => setCurrentPage(page), onShowSizeChange: (current, size) => {
                setCurrentPage(current);
                setRowsPerPage(size);
            } })))));
});
export default LoanApplication;
