import React from "react";
import { formatCpf, formatCurrencyNullIF } from "../../../shared/utils/formatters";
import styles from "./style.scss";
export const getEmployeesTableColumns = (t) => [
    {
        title: t("nameLabel"),
        dataIndex: "name",
        key: "name",
    },
    {
        title: t("cpfLabel"),
        dataIndex: "cpf",
        key: "cpf",
        render: (cpf) => formatCpf(cpf),
    },
    {
        title: t("sectorLabel"),
        dataIndex: "sector",
        key: "sector",
        render: (sector) => formatCurrencyNullIF(sector),
    },
    {
        title: t("contractedLoansLabel"),
        dataIndex: "contractedLoansNumber",
        key: "contractedLoansNumber",
        render: (contractedLoansNumber) => contractedLoansNumber > 0 ? React.createElement("span", { className: styles.yes }, "Sim") : "Não",
    },
];
