import { __awaiter } from "tslib";
import React from "react";
import { observer } from "mobx-react-lite";
import { PasswordConfirmForm } from "../../../../shared/components/PasswordConfirmForm";
import { useStores } from "../../../utils/mobx";
import { SectionHeader } from "../../../components/SectionHeader";
import { SectionContainer } from "../../../../shared/components/SectionContainer";
import { useTranslation } from "react-i18next";
import s from "./style.scss";
const ChangePassword = observer(() => {
    const { authStore, generalStore: { catchErrors }, } = useStores();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();
    const changePassword = (password, passwordConfirm, currentPassword) => __awaiter(void 0, void 0, void 0, function* () {
        setErrorMessage("");
        setLoading(true);
        try {
            yield authStore.changePassword(currentPassword || "", password, passwordConfirm);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("unexpectedErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    return (React.createElement("div", { className: s.mainContainer },
        React.createElement(SectionHeader, { className: s.header, title: t("authentication.changePasswordText") }),
        React.createElement("div", { className: s.container },
            React.createElement(SectionContainer, { className: s.container },
                React.createElement(PasswordConfirmForm, { handleSubmit: changePassword, error: errorMessage, loading: loading, hasCurrentPassword: true, hideExtraText: true, buttonText: t("authentication.confirmPasswordChangingButtonText") })))));
});
export default ChangePassword;
