import { __awaiter, __decorate } from "tslib";
import { api } from "../index";
import { action, observable } from "mobx";
export class LoanApplicationStore {
    constructor() {
        this.totalLoanRequests = 0;
        this.getLoanRequests = (page, rowsPerPage, filter = null) => __awaiter(this, void 0, void 0, function* () { return api.getLoanRequests({ page, rowsPerPage, filter }); });
        this.changeStatusFromLoanRequest = (status, requestIds, filter, blockTemporaryUser) => __awaiter(this, void 0, void 0, function* () {
            if (requestIds) {
                this.getLoanRequestTotal(filter);
                return api.changeStatusFromLoanRequest({ requestIds, status, blockTemporaryUser });
            }
        });
        this.getLoanRequestTotal = (filter) => __awaiter(this, void 0, void 0, function* () {
            try {
                this.totalLoanRequests = yield api.getLoanRequestTotal({ filter });
                return this.totalLoanRequests;
            }
            catch (err) {
                throw err;
            }
        });
        this.getCcbData = (loanId) => __awaiter(this, void 0, void 0, function* () { return yield api.getCcbDataByLoanId({ loanId }); });
    }
}
__decorate([
    observable
], LoanApplicationStore.prototype, "totalLoanRequests", void 0);
__decorate([
    action
], LoanApplicationStore.prototype, "getLoanRequestTotal", void 0);
__decorate([
    action
], LoanApplicationStore.prototype, "getCcbData", void 0);
