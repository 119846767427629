import { __rest } from "tslib";
import React from "react";
import { Select } from "antd";
import classNames from "classnames";
import { filterOption } from "../../../shared/utils/select";
import styles from "./style.scss";
const { Option } = Select;
const SelectSearchField = (_a) => {
    var { mode, placeholder, selectOptions, className, onChange, defaultValue } = _a, rest = __rest(_a, ["mode", "placeholder", "selectOptions", "className", "onChange", "defaultValue"]);
    return (React.createElement(Select, Object.assign({ mode: mode, className: classNames(styles.select, className), showSearch: true, placeholder: placeholder, onChange: onChange, filterOption: filterOption, value: defaultValue }, rest), selectOptions &&
        selectOptions.map((option) => {
            return (React.createElement(Option, { key: option.value, value: option.value },
                option.label,
                (option === null || option === void 0 ? void 0 : option.description) && (React.createElement("div", { className: styles.optionDescription }, option.description))));
        })));
};
export default SelectSearchField;
