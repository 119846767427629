import { formatCnpj } from "./formatters";
export function mapCompaniesToSelectOptions(companies) {
    if (!companies) {
        return [];
    }
    return companies.map((company) => ({
        label: company.name,
        description: formatCnpj(company.cnpj),
        value: company.id,
    }));
}
