import React from "react";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { Navbar } from "../Navbar";
import s from "./style.scss";
import { CredifitWhiteLogo } from "../../../shared/components/Icons/CredifitWhiteLogo";
export const Header = ({ user, loanRequests }) => {
    const { t } = useTranslation();
    return (React.createElement("header", { className: s.header },
        React.createElement(Row, { type: "flex", justify: "center" },
            React.createElement(Col, { span: 23, className: s.headerContainer },
                React.createElement(CredifitWhiteLogo, { width: 158, height: 42 }),
                React.createElement("div", null,
                    React.createElement(Navbar, { itens: [
                            {
                                label: t("requestsLabel"),
                                to: "/",
                            },
                            {
                                label: t("employees"),
                                to: "",
                                itens: [
                                    {
                                        label: t("importSheetLabel"),
                                        to: "/importar-folha",
                                    },
                                    {
                                        label: t("firingEmployees"),
                                        to: "/desligar-funcionarios",
                                    },
                                ],
                            },
                            {
                                label: t("reportsLabel"),
                                to: "",
                                itens: [
                                    {
                                        label: t("payrollReportLabel"),
                                        to: "/relatorio-folha",
                                    },
                                    {
                                        label: t("contractsLabel"),
                                        to: "/contratos",
                                    },
                                    {
                                        label: t("proposalsLabel"),
                                        to: "/propostas",
                                    },
                                ],
                            },
                            {
                                label: "Convênio",
                                to: "/convenio",
                            },
                            {
                                label: t("changePasswordLabel"),
                                to: "/alterar-senha",
                                isUserSubmenu: true,
                            },
                            {
                                label: t("contactUsLabel"),
                                to: "/contato",
                                isUserSubmenu: true,
                            },
                        ], user: user, loanRequests: loanRequests }))))));
};
