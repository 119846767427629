import React from "react";
import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";
import { Button, Form, Input, Spin, Alert } from "antd";
import { checkEmail } from "../../utils/formValidator";
import s from "./style.scss";
import { useTranslation } from "react-i18next";
const InnerForm = observer(({ loading, error, setErrorMessage, handleLogin, form }) => {
    const { getFieldDecorator, validateFields } = form;
    const handleSubmit = (e) => {
        setErrorMessage("");
        e.preventDefault();
        validateFields((errors, values) => {
            if (!errors) {
                handleLogin(values.email, values.password);
            }
        });
    };
    const { t } = useTranslation();
    return (React.createElement(Form, { className: s.form, layout: "vertical", hideRequiredMark: true, onSubmit: handleSubmit },
        React.createElement(Spin, { spinning: loading },
            React.createElement(Form.Item, { label: "E-mail" }, getFieldDecorator("email", {
                rules: [{ validator: checkEmail }],
            })(React.createElement(Input, { autoFocus: true, allowClear: true }))),
            React.createElement(Form.Item, { label: t("authentication.password") }, getFieldDecorator("password", {
                rules: [
                    {
                        required: true,
                        message: React.createElement(React.Fragment, null, t("authentication.insertPasswordText")),
                    },
                ],
            })(React.createElement(Input, { type: "password", allowClear: true }))),
            React.createElement(Form.Item, null,
                React.createElement(NavLink, { to: "esqueci-minha-senha" }, t("authentication.forgotMyPasswordText"))),
            React.createElement(Form.Item, null, error && React.createElement(Alert, { message: error, type: "error", showIcon: true, closable: true })),
            React.createElement(Form.Item, { className: s.centerContainer },
                React.createElement(Button, { type: "primary", htmlType: "submit" }, t("authentication.login"))))));
});
export const LoginForm = Form.create({ name: "login_form" })(InnerForm);
