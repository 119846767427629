import { __rest } from "tslib";
import React from "react";
import classNames from "classnames";
import { Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import FilterItem from "../FilterItem";
import { convertUndefinedPropertiesToNull } from "../../../shared/utils/formatters";
import s from "./style.scss";
const InnerFilterForm = (_a) => {
    var { form } = _a, props = __rest(_a, ["form"]);
    const { t } = useTranslation();
    const { getFieldsValue } = form;
    const handleSubmit = (e) => {
        e.preventDefault();
        const values = convertUndefinedPropertiesToNull(getFieldsValue());
        props.handleSubmit(values);
    };
    const handleChange = (e) => {
        if (props.handleChange) {
            e.preventDefault();
            const values = convertUndefinedPropertiesToNull(getFieldsValue());
            props.handleChange(values);
        }
    };
    const dispatchOnChangeWhenClearField = (fieldName, value) => {
        if (props.handleChange && !value) {
            const values = convertUndefinedPropertiesToNull(getFieldsValue());
            props.handleChange(Object.assign(Object.assign({}, values), { [fieldName]: undefined }));
        }
    };
    return (React.createElement(Form, { className: classNames(s.form, props.className), layout: "inline", onSubmit: handleSubmit, onChange: handleChange },
        React.createElement("div", { className: s.titleWrapper },
            React.createElement("p", null,
                t("filterByText"),
                " ")),
        React.createElement("div", { className: s.filtersWrapper }, props.items.map((item) => (React.createElement(FilterItem, Object.assign({ form: form, key: item.key, name: item.name, type: item.type, className: s.item, placeholder: item.placeholder, selectOptions: item.selectOptions, inputMask: item.inputMask, selectDefaultValue: item.selectDefaultValue, inputDefaultValue: item.inputDefaultValue, mode: item === null || item === void 0 ? void 0 : item.mode, onChange: (e) => dispatchOnChangeWhenClearField(item.name, e.target.value), "data-cy": `${item.name}-item-data-cy` }, item.props))))),
        React.createElement(Form.Item, { className: s.button }, props.filterButton ? (props.filterButton) : (React.createElement(Button, { type: "primary", htmlType: "submit", id: "filter_button", "data-cy": "filter-button" }, t("filterLabel"))))));
};
export const FilterForm = Form.create({
    name: "filter_form",
})(InnerFilterForm);
