import React from "react";
import { observer } from "mobx-react-lite";
import Table from "antd/lib/table";
import ActionSelect from "../ActionSelect";
const ActionTable = observer((props) => {
    const { rowSelection, columns, dataSource, actions, actionsOutsideFooter, handlePageChange, currentPage, totalRecords, } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, Object.assign({ rowSelection: rowSelection, dataSource: dataSource, columns: columns, footer: actions && !actionsOutsideFooter
                ? () => (React.createElement(ActionSelect, { actions: actions, currentPage: currentPage || 1, selectedKeys: rowSelection ? rowSelection.selectedRowKeys : [] }))
                : undefined }, props, { pagination: handlePageChange
                ? {
                    onChange: handlePageChange,
                    total: totalRecords || 0,
                }
                : false })),
        actionsOutsideFooter && (React.createElement(ActionSelect, { actions: actions || [], currentPage: currentPage || 1, selectedKeys: rowSelection ? rowSelection.selectedRowKeys : [] }))));
});
export default ActionTable;
