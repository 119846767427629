export const getProposalsTableColumns = (t) => [
    {
        title: t("proposalDataCreated"),
        dataIndex: "createdAt",
        key: "createdAt",
    },
    {
        title: t("proposalNumber"),
        dataIndex: "proposalNumber",
        key: "proposalNumber",
    },
    {
        title: t("proposalStatus"),
        dataIndex: "status",
        key: "status",
    },
    {
        title: t("proposalLabelName"),
        dataIndex: "name",
        key: "name",
        width: 200,
    },
    {
        title: t("proposalLabelCpf"),
        dataIndex: "cpf",
        key: "cpf",
        width: 200,
    },
    {
        title: t("proposalCompany"),
        dataIndex: "companyName",
        key: "companyName",
        width: 200,
    },
    {
        title: t("proposalLabelResponsableCorban"),
        dataIndex: "corbanName",
        key: "corbanName",
        width: 200,
    },
    {
        title: t("proposalNumberCcb"),
        dataIndex: "ccbNumber",
        key: "ccbNumber",
    },
    {
        title: t("proposalStatusLoan"),
        dataIndex: "loanStatus",
        key: "loanStatus",
    },
];
