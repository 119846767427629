import React, { useEffect, useState } from "react";
import { Form, Input, Radio } from "antd";
import i18n from "i18next";
import styles from "./style.scss";
import TooltipDisplay from "../TooltipDisplay";
export const BlockUserForm = ({ onChange }) => {
    const [blockTemporaryUser, setBlockTemporaryUser] = useState(null);
    const [shouldBlockTemporaryUser, setShouldBlockTemporaryUser] = useState(false);
    useEffect(() => {
        onChange(blockTemporaryUser);
    }, [blockTemporaryUser]);
    return (React.createElement("div", { "data-cy": "block-user-form-data-cy" },
        React.createElement("div", { className: styles.labelBlock },
            i18n.t("labelBlock"),
            React.createElement(TooltipDisplay, { "data-cy": "tooltip-block-user-form-data-cy", title: React.createElement("div", null,
                    React.createElement("p", null, i18n.t("blockUserFormTooltipDisplayLabel"))) })),
        React.createElement("div", null,
            React.createElement(Radio.Group, { defaultValue: false, onChange: (e) => {
                    setShouldBlockTemporaryUser(e.target.value);
                    if (!e.target.value) {
                        setBlockTemporaryUser(null);
                    }
                } },
                React.createElement(Radio, { "data-cy": "no-radio-data-cy", value: false }, i18n.t("noLabel")),
                React.createElement(Radio, { "data-cy": "yes-radio-data-cy", value: true }, i18n.t("yesLabel"))),
            shouldBlockTemporaryUser && (React.createElement(Form.Item, { "data-cy": "block-user-form-item-data-cy", label: i18n.t("blockuserTitle") },
                React.createElement(Input, { min: 1, max: 90, type: "number", "data-cy": "block-user-form-input-data-cy", placeholder: i18n.t("placeholderBlock"), onChange: (e) => {
                        setBlockTemporaryUser(Number(e.target.value));
                    } }))))));
};
