import isEmail from "validator/lib/isEmail";
import isValidCnpj from "@brazilian-utils/is-valid-cnpj";
import isValidCPF from "@brazilian-utils/is-valid-cpf";
import { isValidCEP } from "@brazilian-utils/brazilian-utils";
import { isValidPhone } from "@brazilian-utils/brazilian-utils";
import i18n from "i18next";
export const checkEmail = (_, value, callback) => {
    if (!value || value === "") {
        callback(i18n.t("authentication.insertEmailText"));
        return;
    }
    if (isEmail(value)) {
        callback();
        return;
    }
    callback(i18n.t("authentication.invalidEmailText"));
};
export const checkCNPJ = (_, value, callback) => {
    if (!value || value === "") {
        callback(i18n.t("authentication.mandatoryCNPJText"));
        return;
    }
    if (isValidCnpj(value)) {
        callback();
        return;
    }
    callback(i18n.t("authentication.invalidCNPJText"));
};
export const checkCPF = (_, value, callback) => {
    if (!value || value === "") {
        callback(i18n.t("authentication.mandatoryCPFText"));
        return;
    }
    if (isValidCPF(value)) {
        callback();
        return;
    }
    callback(i18n.t("authentication.invalidCPFText"));
};
export const checkRG = (_, rg, callback) => {
    if (!rg || rg === "") {
        callback(i18n.t("validation.mandatoryRGText"));
        return;
    }
    if (isValidRg(rg)) {
        callback();
        return;
    }
    callback(i18n.t("validation.invalidRGText"));
};
export const checkPhone = (_, phoneNumber, callback) => {
    if (isValidPhone(phoneNumber) || !phoneNumber || phoneNumber === "") {
        callback();
        return;
    }
    callback(i18n.t("validation.invalidPhoneNumberText"));
};
export const checkPhoneAllowEmpty = (_, phoneNumber, callback) => {
    if (!phoneNumber || phoneNumber === "") {
        callback();
        return;
    }
    if (isValidPhone(phoneNumber)) {
        callback();
        return;
    }
    callback(i18n.t("validation.invalidPhoneNumberText"));
};
export const isOnlyNumbers = (value) => /^\d+$/.test(value);
export const isValidRg = (value) => /^(\d{1,2}).?(\d{1,3}).?(\d{1,3})-?(\d{1}|X|x)$/.test(value);
export const isOnlyAlphanumeric = (value) => /^[a-z0-9]+$/gi.test(value);
export const isValidPassword = (value) => /^[a-zA-Z0-9$-/:-@{-~!¨¨¨¨¨¨"^_`¬°´ ªº¢£³²¹#\\\\À-ÎÒ-ÕÙ-Ûà-îò-÷ù-û\[\]/\"¨¨¨¨¨¨¨]*$/gi.test(value);
export const checkCep = (_, cep, callback) => {
    if (!cep || cep === "") {
        callback(i18n.t("validation.mandatoryCep"));
        return;
    }
    if (isValidCEP(cep)) {
        callback();
        return;
    }
    callback(i18n.t("validation.cepInvalid"));
};
