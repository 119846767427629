import { __awaiter, __decorate } from "tslib";
import { action, computed, observable } from "mobx";
import { api } from "../index";
export class PayrollImportStore {
    constructor() {
        this.payrolls = [];
        this.lastPayroll = null;
        this.currentPayrollSubmission = null;
        this.getPayrolls = (companyId, page, rowsPerPage) => __awaiter(this, void 0, void 0, function* () {
            const paginatedPayrolls = yield api.getPayrolls({ companyId, page, rowsPerPage });
            let payrolls = this.payrolls;
            payrolls = page > 1 ? payrolls.concat(paginatedPayrolls.payrolls) : paginatedPayrolls.payrolls;
            if (page === 1) {
                const lastPayroll = payrolls[0];
                this.lastPayroll = payrolls.length ? lastPayroll || this.lastPayroll : null;
                this.payrolls = payrolls;
            }
            else {
                this.payrolls = payrolls;
            }
            return paginatedPayrolls;
        });
        this.getPayrollFile = (id) => api.getPayrollFile({ id });
        this.uploadPayroll = (companyId, file, filename) => __awaiter(this, void 0, void 0, function* () {
            this.currentPayrollSubmission = yield api.uploadPayroll({ companyId, file, filename });
            if (this.hasConflicts === false) {
                this.updateLastPayroll(this.currentPayrollSubmission.payroll);
            }
        });
        this.confirmsPayrollSubmission = (companyId, payrollId, resignationsInfo, inssRemovalsInfo) => __awaiter(this, void 0, void 0, function* () {
            const payroll = yield api.confirmPayrollSubmission({
                companyId,
                payrollId,
                resignationsInfo,
                inssRemovalsInfo,
            });
            this.updateLastPayroll(payroll);
        });
        this.updateLastPayroll = (payroll) => {
            if (this.lastPayroll) {
                this.payrolls.unshift(this.lastPayroll);
            }
            this.lastPayroll = payroll;
        };
        this.getPayrollFileUpdated = (companyId) => api.getPayrollFileUpdated({ companyId });
    }
    get hasConflicts() {
        return this.currentPayrollSubmission && this.currentPayrollSubmission.conflicts.length > 0;
    }
}
__decorate([
    observable
], PayrollImportStore.prototype, "payrolls", void 0);
__decorate([
    observable
], PayrollImportStore.prototype, "lastPayroll", void 0);
__decorate([
    observable
], PayrollImportStore.prototype, "currentPayrollSubmission", void 0);
__decorate([
    computed
], PayrollImportStore.prototype, "hasConflicts", null);
__decorate([
    action
], PayrollImportStore.prototype, "getPayrolls", void 0);
__decorate([
    action
], PayrollImportStore.prototype, "updateLastPayroll", void 0);
