import React from "react";
import { HiringIcon } from "../../../shared/components/Icons/Hiring";
import { InssReturnIcon } from "../../../shared/components/Icons/InssReturn";
import { InssRemovalIcon } from "../../../shared/components/Icons/InssRemoval";
import { SalaryUpgradeIcon } from "../../../shared/components/Icons/SalaryUpdade";
import { ChangingNameIcon } from "../../../shared/components/Icons/ChangingName";
import { FiredIcon } from "../../../shared/components/Icons/Fired";
import i18n from "../../i18n";
import s from "./style.scss";
import { MonthlyPayrollDiscountChange } from "../../../shared/components/Icons/MonthlyPayrollDiscountChange";
export const ConflictStatusComponent = ({ status }) => {
    const conflictStatusElements = {
        hired: {
            label: i18n.t("conflictStatusHiringLabel"),
            icon: React.createElement(HiringIcon, null),
        },
        fired: {
            label: i18n.t("conflictStatusFiringLabel"),
            icon: React.createElement(FiredIcon, null),
        },
        salaryUpgrade: {
            label: i18n.t("conflictStatusWageAdjustmentLabel"),
            icon: React.createElement(SalaryUpgradeIcon, null),
        },
        InssRemoval: {
            label: i18n.t("conflictStatusInssAwayLabel"),
            icon: React.createElement(InssRemovalIcon, null),
        },
        InssReturn: {
            label: i18n.t("conflictStatusInssReturnLabel"),
            icon: React.createElement(InssReturnIcon, null),
        },
        nameChange: {
            label: i18n.t("conflictStatusNameChangeLabel"),
            icon: React.createElement(ChangingNameIcon, null),
        },
        monthlyPayrollDiscountChange: {
            label: i18n.t("conflictsStatusMonthlyParollChangeLabel"),
            icon: React.createElement(MonthlyPayrollDiscountChange, null),
        },
    };
    return (React.createElement("div", { className: s.status },
        conflictStatusElements[status].icon,
        React.createElement("span", null, conflictStatusElements[status].label)));
};
