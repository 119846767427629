import { __awaiter, __decorate } from "tslib";
import { observable, computed } from "mobx";
import { api } from "../index";
import i18n from "i18next";
import { sendFirebaseAnalyticsEvent } from "../../shared/utils/firebase";
export class GeneralStore {
    constructor(rootStore) {
        this.message = "";
        this.messageType = "success";
        this.selectedLanguage = "";
        this.selectedCompanyId = "";
        this.setErrorMessage = (message) => {
            var _a, _b;
            sendFirebaseAnalyticsEvent({
                message: "Erorr",
                event: {
                    errorMessage: message,
                    url: window.location.href,
                    user: {
                        name: (_a = this.rootStore.authStore.user) === null || _a === void 0 ? void 0 : _a.name,
                        email: (_b = this.rootStore.authStore.user) === null || _b === void 0 ? void 0 : _b.email,
                    },
                    selectedCompany: this.selectedCompanyId,
                },
            });
            this.setMessage(message, "error");
        };
        this.catchErrors = (error, callback, message) => {
            var _a;
            if (error.type === "Fatal" ||
                error.type === "Connection" ||
                error.type === "BadFormattedResponse") {
                callback ? callback(i18n.t("unexpectedErrorMessage")) : this.setErrorMessage(message);
            }
            else if (error.type === "NotLoggedIn") {
                this.rootStore.authStore.localLogout();
            }
            else {
                callback(((_a = error.data) === null || _a === void 0 ? void 0 : _a.code) ? error.message : message);
            }
        };
        this.setSuccessMessage = (message) => {
            this.setMessage(message, "success");
        };
        this.setMessage = (message, type) => {
            this.message = message;
            this.messageType = type;
            this.scrollToTop();
        };
        this.setSelectedLanguage = (language) => {
            localStorage.setItem("selectedLanguage", language);
            this.selectedLanguage = localStorage.getItem("selectedLanguage");
        };
        this.getSelectedLanguageFromStore = () => {
            return localStorage.getItem("selectedLanguage");
        };
        this.getSelectedCompany = () => {
            var _a;
            const urlPath = window.location.pathname;
            const authRoutes = /cadastro|login|recuperar-senha|esqueci-minha-senha/;
            if (authRoutes.exec(urlPath))
                return "";
            let selectedCompany = localStorage.getItem("selectedCompanyId") || "";
            if (!selectedCompany) {
                const { companies } = JSON.parse(localStorage.getItem("user") || "{}");
                if ((companies === null || companies === void 0 ? void 0 : companies.length) >= 1) {
                    selectedCompany =
                        ((_a = companies === null || companies === void 0 ? void 0 : companies[0]) === null || _a === void 0 ? void 0 : _a.id) || this.setMessageErrorAndLogout(i18n.t("error.noSelectedCompany"));
                }
            }
            return selectedCompany;
        };
        this.scrollToTop = () => window.scrollTo(0, 0);
        this.clearMessage = () => (this.message = "");
        this.setSelectedCompany = (companyId) => __awaiter(this, void 0, void 0, function* () {
            yield api.updateSelectedCompany({ companyId });
            this.selectedCompanyId = companyId;
            localStorage.setItem("selectedCompanyId", companyId);
            return this.selectedCompanyId;
        });
        this.rootStore = rootStore;
        this.selectedCompanyId = this.getSelectedCompany();
    }
    get isMultiCompany() {
        var _a, _b, _c;
        if ((_a = this.rootStore.authStore.user) === null || _a === void 0 ? void 0 : _a.companies) {
            return ((_c = (_b = this.rootStore.authStore.user) === null || _b === void 0 ? void 0 : _b.companies) === null || _c === void 0 ? void 0 : _c.length) > 1;
        }
        return false;
    }
    setMessageErrorAndLogout(message, delay = 5000) {
        this.setErrorMessage(message);
        setTimeout(() => {
            this.rootStore.authStore.logout();
        }, delay);
    }
}
__decorate([
    observable
], GeneralStore.prototype, "message", void 0);
__decorate([
    observable
], GeneralStore.prototype, "messageType", void 0);
__decorate([
    observable
], GeneralStore.prototype, "selectedLanguage", void 0);
__decorate([
    observable
], GeneralStore.prototype, "selectedCompanyId", void 0);
__decorate([
    computed
], GeneralStore.prototype, "isMultiCompany", null);
