export const downloadFileFromBuffer = (file, filename) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    download(url, filename);
};
export const openFileInNewTab = (url) => {
    const link = createTempLink(url);
    link.target = "_blank";
    link.click();
    document.body.removeChild(link);
};
const createTempLink = (url) => {
    const link = document.createElement("a");
    link.style.display = "none";
    document.body.appendChild(link);
    link.href = url;
    return link;
};
export const download = (url, filename) => {
    const link = createTempLink(url);
    link.setAttribute("download", filename);
    link.click();
    document.body.removeChild(link);
};
export function bufferToPDFFileUrl(ccb) {
    const blobFile = new Blob([ccb], { type: "application/pdf" });
    return URL.createObjectURL(blobFile);
}
export const getDocumentsWithFileCount = (documents) => documents.reduce((acc, document) => (document.file ? acc + 1 : acc), 0);
