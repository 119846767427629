exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.firingDetails___1nmvs {\n  overflow: auto;\n  height: 100%; }\n\n.cardsWrapper___YnHhr {\n  padding: 15px;\n  height: 390px;\n  overflow: auto; }\n\n.firingCard___EDRSd {\n  border-radius: 16px;\n  border: 1.5px solid #E8E8E8;\n  padding: 15px;\n  margin-bottom: 20px;\n  display: flex;\n  column-gap: 7%;\n  align-items: center;\n  width: 100%; }\n\n.firingDetailsFooter___1M1-T {\n  width: 100%;\n  padding: 15px;\n  display: flex;\n  justify-content: space-between; }\n", ""]);

// Exports
exports.locals = {
	"firingDetails": "firingDetails___1nmvs",
	"cardsWrapper": "cardsWrapper___YnHhr",
	"firingCard": "firingCard___EDRSd",
	"firingDetailsFooter": "firingDetailsFooter___1M1-T"
};