import { __awaiter } from "tslib";
import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { CredifitWhiteLogo } from "../../../shared/components/Icons/CredifitWhiteLogo";
import { useStores } from "../../utils/mobx";
import { NotFound } from "../../../shared/components/404";
import s from "./style.scss";
export const CompanyNotFound = () => {
    const { t } = useTranslation();
    const { pathname } = window.location;
    const { generalStore, authStore } = useStores();
    // Logout function
    const logout = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield authStore.logout();
        }
        catch (error) {
            generalStore.catchErrors(error, generalStore.setErrorMessage, t("unexpectedErrorMessage"));
        }
    });
    return (React.createElement(React.Fragment, null, authStore.isLoggedIn && pathname.startsWith("/cadastro") ? (React.createElement("div", { className: s.container },
        React.createElement("div", { className: s.blurBackground }),
        React.createElement("div", { className: s.box },
            React.createElement(CredifitWhiteLogo, null),
            React.createElement(React.Fragment, null,
                React.createElement("h1", { className: s.loginOn },
                    t("error404.hasLogin"),
                    "."),
                React.createElement("p", { className: s.completeActionMessage },
                    t("error404.completeActionMessage"),
                    "."),
                React.createElement(Button, { className: s.button, onClick: logout }, t("error404.logoutAndContinue")))))) : (React.createElement(React.Fragment, null,
        React.createElement("h1", null, "testes"),
        React.createElement(NotFound, null)))));
};
