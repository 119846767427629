import React from "react";
import { Col } from "antd";
import s from "./style.scss";
const AgreementItem = ({ title, value = "0", description, prefix }) => {
    return (React.createElement(Col, { span: 6 },
        React.createElement("div", { className: s.container },
            React.createElement("div", { className: s.title }, title),
            React.createElement("div", { className: s.value },
                prefix,
                value,
                description))));
};
export default AgreementItem;
