/* eslint-disable */
import { SdkgenError, SdkgenErrorWithData, SdkgenHttpClient } from "@sdkgen/browser-runtime";
export class DBInvalidQuery extends SdkgenErrorWithData {
}
export class InvalidCredentials extends SdkgenErrorWithData {
}
export class InvalidCredentialsCode extends SdkgenErrorWithData {
}
export class InvalidParam extends SdkgenErrorWithData {
}
export class NotFoundCode extends SdkgenErrorWithData {
}
export class InvalidOperationCode extends SdkgenErrorWithData {
}
export class NotLoggedIn extends SdkgenErrorWithData {
}
export class NotExpectedOperation extends SdkgenErrorWithData {
}
export class CancelReservationError extends SdkgenErrorWithData {
}
export class ForbiddenAccessError extends SdkgenErrorWithData {
}
export class MetadadosRequestError extends SdkgenErrorWithData {
}
export class ConsultMarginError extends SdkgenErrorWithData {
}
export class Fatal extends SdkgenError {
}
export class ApiClient extends SdkgenHttpClient {
    constructor(baseUrl) {
        super(baseUrl, astJson, errClasses);
    }
    getCcbDataByLoanId(args) { return this.makeRequest("getCcbDataByLoanId", args || {}); }
    getCurrentUser(args) { return this.makeRequest("getCurrentUser", args || {}); }
    login(args) { return this.makeRequest("login", args || {}); }
    logout(args) { return this.makeRequest("logout", args || {}); }
    requestPasswordChange(args) { return this.makeRequest("requestPasswordChange", args || {}); }
    registerNewPassword(args) { return this.makeRequest("registerNewPassword", args || {}); }
    changePassword(args) { return this.makeRequest("changePassword", args || {}); }
    getEmailFromRequest(args) { return this.makeRequest("getEmailFromRequest", args || {}); }
    getLoanRequestTotal(args) { return this.makeRequest("getLoanRequestTotal", args || {}); }
    getLoanRequests(args) { return this.makeRequest("getLoanRequests", args || {}); }
    changeStatusFromLoanRequest(args) { return this.makeRequest("changeStatusFromLoanRequest", args || {}); }
    getContractTotal(args) { return this.makeRequest("getContractTotal", args || {}); }
    getContracts(args) { return this.makeRequest("getContracts", args || {}); }
    exportContracts(args) { return this.makeRequest("exportContracts", args || {}); }
    updateSelectedCompany(args) { return this.makeRequest("updateSelectedCompany", args || {}); }
    getAgreement(args) { return this.makeRequest("getAgreement", args || {}); }
    getAgreementFile(args) { return this.makeRequest("getAgreementFile", args || {}); }
    simulateInstallments(args) { return this.makeRequest("simulateInstallments", args || {}); }
    getLoanSimulationSummary(args) { return this.makeRequest("getLoanSimulationSummary", args || {}); }
    getReferenceDate(args) { return this.makeRequest("getReferenceDate", args || {}); }
    getOldestMonthWithActiveInstallment(args) { return this.makeRequest("getOldestMonthWithActiveInstallment", args || {}); }
    getPayrollSummary(args) { return this.makeRequest("getPayrollSummary", args || {}); }
    getPayrollReport(args) { return this.makeRequest("getPayrollReport", args || {}); }
    exportPayrollReport(args) { return this.makeRequest("exportPayrollReport", args || {}); }
    getPayrolls(args) { return this.makeRequest("getPayrolls", args || {}); }
    getPayrollFile(args) { return this.makeRequest("getPayrollFile", args || {}); }
    uploadPayroll(args) { return this.makeRequest("uploadPayroll", args || {}); }
    confirmPayrollSubmission(args) { return this.makeRequest("confirmPayrollSubmission", args || {}); }
    getPayrollFileUpdated(args) { return this.makeRequest("getPayrollFileUpdated", args || {}); }
    getCompanyEmployeesTotal(args) { return this.makeRequest("getCompanyEmployeesTotal", args || {}); }
    getCompanyEmployees(args) { return this.makeRequest("getCompanyEmployees", args || {}); }
    getFiringEmployeesPreview(args) { return this.makeRequest("getFiringEmployeesPreview", args || {}); }
    fireEmployees(args) { return this.makeRequest("fireEmployees", args || {}); }
    getFiredEmployeesDetails(args) { return this.makeRequest("getFiredEmployeesDetails", args || {}); }
    simulateFireEmployee(args) { return this.makeRequest("simulateFireEmployee", args || {}); }
    getUserLoanInfos(args) { return this.makeRequest("getUserLoanInfos", args || {}); }
    getCompanyFirings(args) { return this.makeRequest("getCompanyFirings", args || {}); }
    saveLoanProposalPF(args) { return this.makeRequest("saveLoanProposalPF", args || {}); }
    getAssociatedLoansProposalsPF(args) { return this.makeRequest("getAssociatedLoansProposalsPF", args || {}); }
    updateLoansProposalsStatus(args) { return this.makeRequest("updateLoansProposalsStatus", args || {}); }
}
const errClasses = {
    DBInvalidQuery,
    InvalidCredentials,
    InvalidCredentialsCode,
    InvalidParam,
    NotFoundCode,
    InvalidOperationCode,
    NotLoggedIn,
    NotExpectedOperation,
    CancelReservationError,
    ForbiddenAccessError,
    MetadadosRequestError,
    ConsultMarginError,
    Fatal
};
const astJson = {
    annotations: {
        "type.CcbDataPreviewDynamicDataCreditInfo.loanTotalValue": [
            {
                type: "description",
                value: "Valor de Presente"
            }
        ],
        "type.BankAccount.bankName": [
            {
                type: "description",
                value: "Nome do banco"
            }
        ],
        "type.BankAccount.bankNumber": [
            {
                type: "description",
                value: "Numero do banco"
            }
        ],
        "type.BankAccount.branchNumber": [
            {
                type: "description",
                value: "Agencia"
            }
        ],
        "type.BankAccount.accountNumber": [
            {
                type: "description",
                value: "Numero da conta"
            }
        ],
        "type.UserLoanInfo.availableValue": [
            {
                type: "description",
                value: "Valor disponivel para pegar o emprestimo"
            }
        ],
        "type.UserLoanInfoForCompany.availableValue": [
            {
                type: "description",
                value: "Valor disponivel para pegar o emprestimo"
            }
        ],
        "type.UserLoanSummary.loanTotalValue": [
            {
                type: "description",
                value: "Valor Total do Emprestimo"
            }
        ],
        "type.UserLoanSummary.totalValue": [
            {
                type: "description",
                value: "Valor Total do Credito"
            }
        ],
        "type.UserLoanSummary.monthlyInterestRate": [
            {
                type: "description",
                value: "A taxa de juros efetiva a.m."
            }
        ],
        "type.UserLoanSummary.monthlyEffectiveCost": [
            {
                type: "description",
                value: "Custo Efetivo Total a.m."
            }
        ],
        "type.UserLoanSummary.balance": [
            {
                type: "description",
                value: "\"valor presente\" (saldo devedor teórico) em aberto"
            }
        ],
        "type.UserLoanSummary.structuringValue": [
            {
                type: "description",
                value: "Tarifa de cadastro"
            }
        ],
        "type.UserLoanSummary.thirdPartyValue": [
            {
                type: "description",
                value: "Valor do Serviço de Terceiros"
            }
        ],
        "type.AllInstallments.isOverdue": [
            {
                type: "description",
                value: "WHEN status = 'open', Verify if due_date < TodayLocalTimezone()"
            }
        ],
        "type.AllInstallments.dueDate": [
            {
                type: "description",
                value: "Needs to be string because the JSONB_BUILD_OBJECT() do bind only to string. @see user.loans.getUserLoanSummary()"
            }
        ],
        "type.AllInstallments.paidValue": [
            {
                type: "description",
                value: "WHEN status = 'paid'"
            }
        ],
        "type.AllInstallments.paidAt": [
            {
                type: "description",
                value: "WHEN status = 'paid'. Needs to be string because the JSONB_BUILD_OBJECT() do bind only to string. @see user.loans.getUserLoanSummary()"
            }
        ],
        "fn.getCcbDataByLoanId": [
            {
                type: "description",
                value: "get CCB after App loan request by user"
            },
            {
                type: "throws",
                value: "InvalidParam"
            },
            {
                type: "throws",
                value: "NotFoundCode"
            }
        ],
        "fn.getLoanRequestTotal": [
            {
                type: "description",
                value: "Get the quantity of loan requests that is pending to RH employees"
            }
        ],
        "fn.getReferenceDate.solicitationDate": [
            {
                type: "description",
                value: "the date to be calculated. Needs to be today() but should be another to tests porpose."
            }
        ],
        "fn.getReferenceDate": [
            {
                type: "description",
                value: "Get the reference month of the payroll report (\"mês de referência da folha de pagemento\") by payroll close date (\"data de corte\"). @example considering company's payrollCloseDate equals 20 if solicitationDate is equals or greather than payrollCloseDate, the reference date needs to be the next month. @description It is usefull to show Payroll Report by company's close date instead of current month."
            }
        ]
    },
    errors: [
        [
            "DBInvalidQuery",
            "DBInvalidQueryData"
        ],
        [
            "InvalidCredentials",
            "InvalidCredentialsData"
        ],
        [
            "InvalidCredentialsCode",
            "InvalidCredentialsCodeData"
        ],
        [
            "InvalidParam",
            "InvalidParamData"
        ],
        [
            "NotFoundCode",
            "NotFoundCodeData"
        ],
        [
            "InvalidOperationCode",
            "InvalidOperationCodeData"
        ],
        [
            "NotLoggedIn",
            "NotLoggedInData"
        ],
        [
            "NotExpectedOperation",
            "NotExpectedOperationData"
        ],
        [
            "CancelReservationError",
            "CancelReservationErrorData"
        ],
        [
            "ForbiddenAccessError",
            "ForbiddenAccessErrorData"
        ],
        [
            "MetadadosRequestError",
            "MetadadosRequestErrorData"
        ],
        [
            "ConsultMarginError",
            "ConsultMarginErrorData"
        ],
        "Fatal"
    ],
    functionTable: {
        getCcbDataByLoanId: {
            args: {
                loanId: "string"
            },
            ret: "CcbDataPreview"
        },
        getCurrentUser: {
            args: {},
            ret: "HREmployee?"
        },
        login: {
            args: {
                email: "string",
                password: "string"
            },
            ret: "HREmployee"
        },
        logout: {
            args: {},
            ret: "void"
        },
        requestPasswordChange: {
            args: {
                email: "string"
            },
            ret: "void"
        },
        registerNewPassword: {
            args: {
                requestId: "string",
                password: "string",
                confirmPassword: "string"
            },
            ret: "void"
        },
        changePassword: {
            args: {
                currentPassword: "string",
                newPassword: "string",
                confirmNewPassword: "string"
            },
            ret: "void"
        },
        getEmailFromRequest: {
            args: {
                requestId: "string"
            },
            ret: "string"
        },
        getLoanRequestTotal: {
            args: {
                filter: "LoanRequestsFilter?"
            },
            ret: "int"
        },
        getLoanRequests: {
            args: {
                page: "int",
                rowsPerPage: "int",
                filter: "LoanRequestsFilter?"
            },
            ret: "LoanRequestsPaginated"
        },
        changeStatusFromLoanRequest: {
            args: {
                requestIds: "string[]",
                status: "ApprovalDecision",
                solicitationDate: "datetime?",
                blockTemporaryUser: "int?"
            },
            ret: "bool"
        },
        getContractTotal: {
            args: {
                filter: "ContractFilter"
            },
            ret: "uint"
        },
        getContracts: {
            args: {
                filter: "ContractFilter",
                page: "uint",
                rowsPerPage: "uint"
            },
            ret: "ContractReport"
        },
        exportContracts: {
            args: {
                filter: "ContractFilter"
            },
            ret: "bytes"
        },
        updateSelectedCompany: {
            args: {
                companyId: "string"
            },
            ret: "void"
        },
        getAgreement: {
            args: {},
            ret: "Agreement?"
        },
        getAgreementFile: {
            args: {
                id: "string"
            },
            ret: "bytes"
        },
        simulateInstallments: {
            args: {
                loanValue: "uint",
                userId: "string?",
                companyId: "string?",
                solicitationDate: "datetime?",
                withPrestamista: "bool"
            },
            ret: "SimulatedInstallments[]"
        },
        getLoanSimulationSummary: {
            args: {
                loan: "LoanSimulationRequestParams",
                cpf: "string?",
                companyId: "string?",
                solicitationDate: "datetime?",
                withPrestamista: "bool"
            },
            ret: "SimulatedLoanSummary"
        },
        getReferenceDate: {
            args: {
                solicitationDate: "datetime"
            },
            ret: "datetime"
        },
        getOldestMonthWithActiveInstallment: {
            args: {
                filter: "PayrollFilter?"
            },
            ret: "MonthAndYear?"
        },
        getPayrollSummary: {
            args: {
                month: "int",
                year: "int",
                filter: "PayrollFilter?"
            },
            ret: "PayrollSummary"
        },
        getPayrollReport: {
            args: {
                month: "int",
                year: "int",
                page: "int",
                rowsPerPage: "int",
                filter: "PayrollFilter?"
            },
            ret: "PayrollReport"
        },
        exportPayrollReport: {
            args: {
                month: "int",
                year: "int",
                filter: "PayrollFilter?"
            },
            ret: "bytes"
        },
        getPayrolls: {
            args: {
                companyId: "string",
                page: "int",
                rowsPerPage: "int"
            },
            ret: "PayrollsPaginated"
        },
        getPayrollFile: {
            args: {
                id: "string"
            },
            ret: "bytes"
        },
        uploadPayroll: {
            args: {
                companyId: "uuid",
                file: "bytes",
                filename: "string"
            },
            ret: "PayrollSubmission"
        },
        confirmPayrollSubmission: {
            args: {
                companyId: "uuid",
                payrollId: "string",
                resignationsInfo: "FiringsInfo[]",
                inssRemovalsInfo: "InssRemovalsInfo[]"
            },
            ret: "PayrollFromFile"
        },
        getPayrollFileUpdated: {
            args: {
                companyId: "string"
            },
            ret: "bytes"
        },
        getCompanyEmployeesTotal: {
            args: {
                filter: "CompanyEmployesFilter"
            },
            ret: "uint"
        },
        getCompanyEmployees: {
            args: {
                filter: "CompanyEmployesFilter",
                page: "uint",
                rowsPerPage: "uint"
            },
            ret: "CompanyEmployeeReport"
        },
        getFiringEmployeesPreview: {
            args: {
                companyId: "string",
                usersIds: "string[]"
            },
            ret: "EmployeeFiringPreview[]"
        },
        fireEmployees: {
            args: {
                companyId: "string",
                firings: "FiringsInfoInDB[]"
            },
            ret: "string"
        },
        getFiredEmployeesDetails: {
            args: {
                changeId: "string",
                companyId: "string"
            },
            ret: "EmployeeFiredDetails[]"
        },
        simulateFireEmployee: {
            args: {
                companyId: "string",
                firing: "FiringsInfo"
            },
            ret: "FiringsInfoInDB"
        },
        getUserLoanInfos: {
            args: {
                companyId: "string",
                userCpf: "string",
                solicitationDate: "datetime?"
            },
            ret: "UserLoanInfoForCompany"
        },
        getCompanyFirings: {
            args: {
                companyId: "string",
                page: "int",
                rowsPerPage: "int"
            },
            ret: "Firings[]"
        },
        saveLoanProposalPF: {
            args: {
                cpf: "string",
                companyId: "string",
                summary: "SimulatedLoanSummary"
            },
            ret: "LoanProposalPF"
        },
        getAssociatedLoansProposalsPF: {
            args: {
                filter: "ProposalFilter"
            },
            ret: "AssociatedLoanProposal[]"
        },
        updateLoansProposalsStatus: {
            args: {
                proposalsId: "string[]",
                status: "LoanProposalPFStatus"
            },
            ret: "void"
        }
    },
    typeTable: {
        DBInvalidQueryData: {
            code: "string"
        },
        InvalidCredentialsData: {
            code: "string"
        },
        InvalidCredentialsCodeData: {
            code: "string"
        },
        InvalidParamData: {
            code: "string"
        },
        NotFoundCodeData: {
            code: "string"
        },
        InvalidOperationCodeData: {
            code: "string"
        },
        NotLoggedInData: {
            code: "string"
        },
        NotExpectedOperationData: {
            code: "string"
        },
        CancelReservationErrorData: {
            code: "string"
        },
        ForbiddenAccessErrorData: {
            code: "string"
        },
        MetadadosRequestErrorData: {
            code: "string"
        },
        ConsultMarginErrorData: {
            code: "string"
        },
        SignatureGeneralParams: {
            email: "string",
            name: "string",
            cpf: "string?",
            changedAt: "datetime",
            ip: "string",
            hash: "string"
        },
        Signature: {
            status: "LoanStatusReport",
            email: "string",
            name: "string",
            cpf: "string?",
            changedAt: "datetime",
            ip: "string",
            hash: "string"
        },
        BirthdayWithdrawalSignature: {
            status: "LoanFgtsStatus",
            email: "string",
            name: "string",
            cpf: "string?",
            changedAt: "datetime",
            ip: "string",
            hash: "string"
        },
        ConflictSalaryChange: {
            from: "int",
            to: "int"
        },
        ConflictMonthlyPayrollDiscountChange: {
            from: "int",
            to: "int"
        },
        Conflict: {
            userId: "string?",
            userName: "string",
            userNewName: "string?",
            change: "ConflictStatus",
            salaryChange: "ConflictSalaryChange?",
            hasActiveLoan: "bool?",
            monthlyPayrollDiscountChange: "ConflictMonthlyPayrollDiscountChange?"
        },
        FiringsInfo: {
            userId: "string",
            resignationDate: "string?",
            rescissionValue: "int?",
            discountedValueOverride: "int?",
            rescissionPaymentDate: "string?"
        },
        FiringsInfoInDB: {
            discountedValue: "int?",
            userDueBalance: "int?",
            userId: "string",
            resignationDate: "string?",
            rescissionValue: "int?",
            discountedValueOverride: "int?",
            rescissionPaymentDate: "string?"
        },
        InssRemovalsInfo: {
            userId: "string",
            date: "string?"
        },
        EmployeeFiredDetails: {
            id: "string",
            name: "string",
            firstName: "string",
            cpf: "string",
            email: "string?",
            phoneNumber: "string?",
            activeLoans: "int",
            resignationDate: "string?",
            rescissionValue: "int?",
            rescissionPaymentDate: "string?",
            discountedValue: "int?",
            discountedValueOverride: "int?",
            userDueBalance: "int?",
            assigneCompanyFromCession: "string"
        },
        Agreement: {
            id: "string?",
            active: "bool",
            minWorkTime: "int",
            maxLoanInSalaries: "int",
            maxPercentAllowed: "int",
            maxInterestRate: "int",
            minInterestRate: "int",
            maximumInstallments: "int",
            minimumLoanAmount: "int",
            structuringRate: "int",
            dueDate: "string",
            filename: "string?",
            createdAt: "datetime?",
            tedValue: "int",
            custodyValue: "int",
            thirdPartyValue: "int",
            agreementNumber: "string?",
            minStructuringRate: "int?",
            denyParallelLoans: "bool",
            extendedMaximumInstallments: "int?",
            minWorkTimeForTermExtension: "int?",
            creditCalculationAlgorithm: "CreditCalculationAlgorithm"
        },
        CompanyBasicInfo: {
            id: "string",
            name: "string",
            cnpj: "string"
        },
        CompaniesGroupBasicInfo: {
            id: "string",
            name: "string"
        },
        HREmployee: {
            id: "string",
            name: "string",
            email: "string",
            cpf: "string",
            companies: "CompanyBasicInfo[]?",
            companiesGroups: "CompaniesGroupBasicInfo[]?"
        },
        MonthAndYear: {
            month: "int",
            year: "int"
        },
        Definition: {
            id: "string",
            title: "string?",
            description: "string?"
        },
        Declaration: {
            id: "string",
            paragraphs: "string[]"
        },
        Clause: {
            id: "string",
            number: "int?",
            title: "string?",
            intro: "IntroClause",
            paragraphs: "string[]"
        },
        IntroClause: {
            title: "string?",
            subparagraphs: "string[]"
        },
        LoanRequest: {
            numberOfInstallments: "int",
            totalValue: "int",
            installmentValue: "int",
            reasonForLoanRequest: "string?"
        },
        SimulatedInstallments: {
            numberOfInstallments: "uint",
            value: "uint",
            highlight: "bool"
        },
        Creditor: {
            name: "string",
            email: "string",
            phone: "string",
            cnpj: "string",
            site: "string",
            address: "Address"
        },
        CreditorBankInfo: {
            bankNumber: "string?",
            bankName: "string?",
            agency: "string?",
            accountNumber: "string?",
            accountType: "string?"
        },
        StaticFields: {
            operationFinality: "string",
            additionalInformation: "string?",
            interestModality: "string",
            interestIndex: "string",
            interestCapitalizationFrequency: "string",
            formOfPayment: "string",
            yearBase: "string",
            orderCalculation: "string",
            resourcesReleaseForm: "string"
        },
        Individual: {
            name: "string",
            cpf: "string",
            rg: "string",
            dispatcher: "string",
            uf: "string",
            rgIssuanceDate: "string",
            mothersName: "string",
            fathersName: "string",
            companySector: "string",
            companyPosition: "string",
            birthDate: "string",
            nationality: "string",
            cityOfBirth: "string",
            admissionDate: "string?",
            registration: "string",
            maritalStatus: "string",
            gender: "string",
            homePhone: "string",
            commercialPhone: "string",
            cellPhone: "string",
            address: "Address",
            email: "string"
        },
        BankReleaseInfo: {
            bankNumber: "string?",
            bankName: "string?",
            agency: "string?",
            accountNumber: "string?",
            accountType: "string?"
        },
        CreditInfoValue: {
            emittedDate: "string",
            creditDate: "string",
            paymentLocal: "string",
            totalValue: "string",
            iofValue: "string",
            prizeValue: "string",
            netValueCreditedToIssuer: "string",
            firstInstallmentDue: "string",
            lastInstallmentDue: "string",
            monthlyInterestRate: "string",
            annualInterestRate: "string",
            monthlyEffectiveCost: "string",
            thirdPartyValue: "string",
            thirdPartyPercentage: "float",
            tedValue: "string"
        },
        CcbDataPreviewDynamicDataIndividualTimeOfService: {
            years: "int?",
            months: "int?"
        },
        CcbDataPreviewDynamicDataIndividual: {
            salary: "string",
            timeOfService: "CcbDataPreviewDynamicDataIndividualTimeOfService",
            name: "string",
            cpf: "string",
            rg: "string",
            dispatcher: "string",
            uf: "string",
            rgIssuanceDate: "string",
            mothersName: "string",
            fathersName: "string",
            companySector: "string",
            companyPosition: "string",
            birthDate: "string",
            nationality: "string",
            cityOfBirth: "string",
            admissionDate: "string?",
            registration: "string",
            maritalStatus: "string",
            gender: "string",
            homePhone: "string",
            commercialPhone: "string",
            cellPhone: "string",
            address: "Address",
            email: "string"
        },
        CcbDataPreviewDynamicDataCompany: {
            socialName: "string",
            cnpj: "string",
            address: "Address",
            agreementNumber: "string"
        },
        CcbDataPreviewDynamicDataCreditInfo: {
            agreementId: "string",
            type: "string",
            structuringValue: "string",
            structuringRate: "string",
            insurance: "bool",
            insuranceType: "string",
            policyNumber: "string",
            loanTotalValue: "string",
            amountForEarlySettlementOtherFinancialInstitutions: "string",
            descriptionsOfPrepaidFinancialTransactions: "string",
            advanceSettlementPaymentMeansOther: "string",
            debtPortability: "string",
            originalCreditingInstitution: "string",
            originalContractNumber: "string",
            netValue: "string",
            numberOfInstallments: "string",
            installmentsValue: "string",
            lastInstallmentDue: "string",
            annualEffectiveCost: "string",
            prizePercentage: "int",
            custodyValue: "string",
            emittedDate: "string",
            creditDate: "string",
            paymentLocal: "string",
            totalValue: "string",
            iofValue: "string",
            prizeValue: "string",
            netValueCreditedToIssuer: "string",
            firstInstallmentDue: "string",
            monthlyInterestRate: "string",
            annualInterestRate: "string",
            monthlyEffectiveCost: "string",
            thirdPartyValue: "string",
            thirdPartyPercentage: "float",
            tedValue: "string"
        },
        CcbDataPreviewDynamicDataCreditReleaseInfo: {
            userPaymentChoise: "UserPaymentChoise",
            pixKey: "string?",
            favored: "string",
            favoredCPF: "string",
            bankNumber: "string?",
            bankName: "string?",
            agency: "string?",
            accountNumber: "string?",
            accountType: "string?"
        },
        CcbDataPreviewDynamicData: {
            staticFields: "StaticFields",
            ccbNumber: "string?",
            creditor: "Creditor",
            individual: "CcbDataPreviewDynamicDataIndividual",
            company: "CcbDataPreviewDynamicDataCompany",
            creditInfo: "CcbDataPreviewDynamicDataCreditInfo",
            creditReleaseInfo: "CcbDataPreviewDynamicDataCreditReleaseInfo"
        },
        CcbDataPreviewStaticData: {
            definitions: "Definition[]",
            clauses: "Clause[]"
        },
        CcbDataPreview: {
            dynamicData: "CcbDataPreviewDynamicData",
            staticData: "CcbDataPreviewStaticData"
        },
        Spouse: {
            spouseName: "string",
            spouseRg: "string",
            spouseCpf: "string"
        },
        PersonalGuaranteeAddress: {
            cep: "string",
            city: "string",
            address: "string",
            state: "string"
        },
        GeneralPersonalGuarantee: {
            name: "string",
            cpf: "string",
            personalDocumentType: "PersonalDocumentType",
            documentNumber: "string",
            dispatcher: "string",
            uf: "string",
            issuanceDate: "string",
            nationality: "string",
            cityOfBirth: "string",
            address: "PersonalGuaranteeAddress",
            marriageRegime: "MarriageRegime?",
            spouse: "Spouse?",
            stableUnion: "bool?"
        },
        CcbPjParamsPersonalGuarantee: {
            civilState: "CivilState",
            name: "string",
            cpf: "string",
            personalDocumentType: "PersonalDocumentType",
            documentNumber: "string",
            dispatcher: "string",
            uf: "string",
            issuanceDate: "string",
            nationality: "string",
            cityOfBirth: "string",
            address: "PersonalGuaranteeAddress",
            marriageRegime: "MarriageRegime?",
            spouse: "Spouse?",
            stableUnion: "bool?"
        },
        CcbDataPreviewPjDynamicDataPersonalGuarantee: {
            civilState: "string",
            name: "string",
            cpf: "string",
            personalDocumentType: "PersonalDocumentType",
            documentNumber: "string",
            dispatcher: "string",
            uf: "string",
            issuanceDate: "string",
            nationality: "string",
            cityOfBirth: "string",
            address: "PersonalGuaranteeAddress",
            marriageRegime: "MarriageRegime?",
            spouse: "Spouse?",
            stableUnion: "bool?"
        },
        InstallmentPj: {
            number: "int",
            dueDate: "string",
            mainValue: "string",
            interestValue: "string",
            totalValue: "string",
            dueBalance: "string"
        },
        CcbDataPreviewPjDynamicDataStaticFields: {
            operationFinality: "string",
            additionalInformation: "string?",
            interestModality: "string",
            interestCapitalizationFrequency: "string",
            yearBase: "string",
            paymentLocal: "string"
        },
        CcbDataPreviewPjDynamicDataIssuer: {
            companyName: "string",
            cnpj: "string",
            address: "Address",
            bank: "BankReleaseInfo"
        },
        CcbDataPreviewPjDynamicDataOriginalCreditor: {
            name: "string",
            cnpj: "string",
            address: "Address"
        },
        CcbDataPreviewPjDynamicDataLoanData: {
            emittedDate: "string",
            creditDate: "string",
            totalValue: "string",
            iofValue: "string",
            prizeValue: "string",
            netValueCreditedToIssuer: "string",
            firstInstallmentDue: "string",
            lastInstallmentDue: "string",
            monthlyInterestRate: "string",
            annualInterestRate: "string",
            monthlyEffectiveCost: "string",
            tedValue: "string",
            formOfPayment: "string",
            orderCalculation: "string",
            ccbNumber: "string?",
            resourcesReleaseForm: "string",
            amortizationTerm: "string",
            emissionDate: "string",
            registrationAndStructuringValue: "string",
            interestIndex: "string",
            installments: "InstallmentPj[]"
        },
        CcbDataPreviewPjDynamicData: {
            staticFields: "CcbDataPreviewPjDynamicDataStaticFields",
            issuer: "CcbDataPreviewPjDynamicDataIssuer",
            originalCreditor: "CcbDataPreviewPjDynamicDataOriginalCreditor",
            personalGuarantee: "CcbDataPreviewPjDynamicDataPersonalGuarantee[]",
            realGuaranteeNumber: "int",
            loanData: "CcbDataPreviewPjDynamicDataLoanData"
        },
        CcbDataPreviewPjStaticData: {
            declaration: "Declaration",
            clauses: "Clause[]"
        },
        CcbDataPreviewPj: {
            dynamicData: "CcbDataPreviewPjDynamicData",
            staticData: "CcbDataPreviewPjStaticData"
        },
        CcbPjParamsConditions: {
            resourcesReleaseForm: "string",
            formOfPayment: "PaymentMethod",
            orderCalculation: "OrderCalculationType",
            issuerBank: "BankReleaseInfo"
        },
        CcbPjParams: {
            simulationId: "uuid",
            emittedDate: "datetime",
            personalGuarantee: "CcbPjParamsPersonalGuarantee[]",
            realGuaranteeNumber: "int",
            conditions: "CcbPjParamsConditions",
            opinion: "string?"
        },
        TermsOfUseClauses: {
            id: "string?",
            section: "int?",
            title: "string?",
            paragraphs: "string[]?",
            level2: "Level2[]?"
        },
        TermsOfUse: {
            updatedAt: "string?",
            clauses: "TermsOfUseClauses[]?"
        },
        Level2: {
            subsection: "int?",
            title: "string?",
            level3: "Level3[]?"
        },
        Level3: {
            subsection: "int?",
            title: "string?",
            level4: "Level4[]?"
        },
        Level4: {
            subsection: "int?",
            title: "string?"
        },
        CompanyAddress: {
            cep: "string?",
            street: "string?",
            number: "string?",
            neighborhood: "string?",
            city: "string?",
            state: "string?",
            complement: "string?"
        },
        CompanySupplier: {
            id: "string?",
            name: "string?",
            phone: "string?"
        },
        CompanySupplierParams: {
            companyId: "uuid",
            id: "string?",
            name: "string?",
            phone: "string?"
        },
        CompanyFinancialInstitution: {
            id: "string?",
            name: "string?",
            phone: "string?",
            agency: "string?",
            account: "string?"
        },
        CompanyInfoGeneralGeneral: {
            cnpj: "string",
            stateSubscription: "string?",
            inscricaoMunicipal: "string?",
            socialName: "string",
            fantasyName: "string?"
        },
        CompanyInfoGeneralContact: {
            email: "string?",
            phone: "string?",
            otherContacts: "string?"
        },
        CompanyInfoGeneralChargeAddress: {
            sameAddress: "bool?",
            otherContact: "string?",
            address: "CompanyAddress?"
        },
        CompanyInfoGeneralPatrimonialInfo: {
            constitutionDate: "string?",
            lastStaturaryChangeDate: "string?",
            changeAmount: "int?",
            socialCapital: "bigint?",
            netWorth: "bigint?",
            netWorthReferenceDate: "string?",
            economicSector: "EconomicSector?",
            capitalOrigin: "CapitalOrigin?"
        },
        CompanyInfoGeneralFinancialData: {
            activity: "string?",
            employeesTotal: "int?",
            averageShoppingValue: "bigint?",
            averageRevenueValue: "bigint?",
            averageTimeToReceiveSales: "int?",
            utilizationOfInstalledCapacity: "int?",
            marketShare: "int?",
            hasBranches: "bool?",
            branchesAmount: "int?",
            branchesStates: "string?",
            hasExport: "bool?",
            hasImport: "bool?"
        },
        CompanyInfoGeneralDocumentLink: {
            token: "string?",
            dueDate: "string?",
            link: "string?"
        },
        CompanyInfoGeneral: {
            id: "string?",
            codigoClienteOmie: "bigint?",
            general: "CompanyInfoGeneralGeneral",
            address: "CompanyAddress",
            contact: "CompanyInfoGeneralContact",
            chargeAddress: "CompanyInfoGeneralChargeAddress",
            patrimonialInfo: "CompanyInfoGeneralPatrimonialInfo",
            financialData: "CompanyInfoGeneralFinancialData",
            additionalInfo: "string?",
            documentLink: "CompanyInfoGeneralDocumentLink?"
        },
        LegalResponsibleGeneral: {
            cpf: "string",
            companyId: "uuid?",
            name: "string?",
            rg: "string?",
            societyPercent: "int?",
            administrator: "bool?",
            isolated: "bool?",
            sendSignatureEmail: "bool?",
            createdAt: "string?",
            updatedAt: "string?",
            email: "string?"
        },
        LegalResponsible: {
            pep: "bool?",
            acceptedTheTermsAt: "string?",
            addressCep: "string?",
            addressStreet: "string?",
            addressNumber: "string?",
            addressNeighborhood: "string?",
            addressComplement: "string?",
            addressState: "string?",
            addressCity: "string?",
            ip: "string?",
            cpf: "string",
            companyId: "uuid?",
            name: "string?",
            rg: "string?",
            societyPercent: "int?",
            administrator: "bool?",
            isolated: "bool?",
            sendSignatureEmail: "bool?",
            createdAt: "string?",
            updatedAt: "string?",
            email: "string?"
        },
        CompanyInfoEmployees: {
            totalValuePayroll: "bigint?",
            payrollCloseDate: "int?",
            paymentDay: "int?",
            averageSalary: "int?",
            averagePermanenceTime: "int?",
            totalFiredEmployees: "int?"
        },
        CompanyInfoEmployessByAge: {
            between18And22: "int?",
            between23And30: "int?",
            between31And40: "int?",
            between41And50: "int?",
            between50AndPlus: "int?"
        },
        CompanyInfoEmployeesByTime: {
            untilOneYear: "int?",
            oneTwoYears: "int?",
            twoThreeYears: "int?",
            threePlusYears: "int?"
        },
        CompanyInfo: {
            employees: "CompanyInfoEmployees",
            employessByAge: "CompanyInfoEmployessByAge",
            employeesByTime: "CompanyInfoEmployeesByTime",
            suppliers: "CompanySupplier[]",
            financialInstitutions: "CompanyFinancialInstitution[]",
            legalResponsibles: "LegalResponsible[]",
            id: "string?",
            codigoClienteOmie: "bigint?",
            general: "CompanyInfoGeneralGeneral",
            address: "CompanyAddress",
            contact: "CompanyInfoGeneralContact",
            chargeAddress: "CompanyInfoGeneralChargeAddress",
            patrimonialInfo: "CompanyInfoGeneralPatrimonialInfo",
            financialData: "CompanyInfoGeneralFinancialData",
            additionalInfo: "string?",
            documentLink: "CompanyInfoGeneralDocumentLink?"
        },
        FinancialInstitution: {
            companyId: "uuid?",
            bankNumber: "string?",
            id: "string?",
            name: "string?",
            phone: "string?",
            agency: "string?",
            account: "string?"
        },
        CompanyInfoPj: {
            legalResponsibles: "LegalResponsible[]?",
            financialInstitutions: "FinancialInstitution[]?",
            suppliers: "CompanySupplierParams[]?",
            id: "string?",
            codigoClienteOmie: "bigint?",
            general: "CompanyInfoGeneralGeneral",
            address: "CompanyAddress",
            contact: "CompanyInfoGeneralContact",
            chargeAddress: "CompanyInfoGeneralChargeAddress",
            patrimonialInfo: "CompanyInfoGeneralPatrimonialInfo",
            financialData: "CompanyInfoGeneralFinancialData",
            additionalInfo: "string?",
            documentLink: "CompanyInfoGeneralDocumentLink?"
        },
        ApiSecret: {
            id: "uuid",
            apiKey: "string",
            apiSecret: "string",
            profileId: "uuid?",
            companiesGroupId: "uuid?"
        },
        FgtsParameters: {
            id: "uuid",
            interestRate: "int",
            structuringRate: "int",
            tedValue: "int",
            thirdPartyValue: "int",
            antecipationPeriodsNumber: "int"
        },
        SimulatedLoanSummaryAdditionalInfo: {
            monthlyInterestRate: "string",
            firstInstallmentDue: "string",
            lastInstallmentDue: "string",
            additionalSeguroPrestamistaValue: "string",
            additionalSeguroPrestamistaValuePerInstallment: "string",
            insuranceMonthlyGrossPrize: "string"
        },
        SimulatedLoanSummary: {
            totalValue: "string",
            loanTotalValue: "string",
            numberOfInstallments: "string",
            installmentsValue: "string",
            taxesAndRates: "string",
            additionalInfo: "SimulatedLoanSummaryAdditionalInfo"
        },
        BrokerInfo: {
            name: "string",
            cnpjCpf: "string"
        },
        Stipulant: {
            name: "string",
            cnpj: "string"
        },
        SeguroPrestamistaPolicyDataPreviewDynamicDataDataForInsurer: {
            groupAndBranch: "string",
            policyNumber: "string",
            subgroupCode: "string",
            branchNumber: "string",
            stipulant: "Stipulant",
            subStipulant: "Stipulant"
        },
        SeguroPrestamistaPolicyDataPreviewDynamicDataCertificateData: {
            adhesionProposalNumber: "string?",
            emittedDatetime: "string",
            number: "string?",
            initialDate: "string",
            finalDate: "string"
        },
        SeguroPrestamistaPolicyDataPreviewDynamicDataInsuredUserData: {
            name: "string",
            birthDate: "string",
            cpf: "string",
            rgRne: "string",
            dispatcher: "string",
            rgIssuanceDate: "string",
            gender: "string",
            nationality: "string",
            phoneDDD: "string",
            phoneNumber: "string",
            pep: "string"
        },
        SeguroPrestamistaPolicyDataPreviewDynamicDataInsuredCapitalAndPrize: {
            obligationValue: "string",
            insuranceMonthlyNetPrize: "string",
            fixedIOF: "string",
            insuranceMonthlyGrossPrize: "string"
        },
        SeguroPrestamistaPolicyDataPreviewDynamicDataBrokerData: {
            broker: "BrokerInfo"
        },
        SeguroPrestamistaPolicyDataPreviewDynamicDataStaticFields: {
            insuranceCovers: "string",
            capitalInsuredDescription: "string",
            insuredCosting: "string",
            pepDescription: "string"
        },
        SeguroPrestamistaPolicyDataPreviewDynamicData: {
            dataForInsurer: "SeguroPrestamistaPolicyDataPreviewDynamicDataDataForInsurer",
            certificateData: "SeguroPrestamistaPolicyDataPreviewDynamicDataCertificateData",
            insuredUserData: "SeguroPrestamistaPolicyDataPreviewDynamicDataInsuredUserData",
            insuredCapitalAndPrize: "SeguroPrestamistaPolicyDataPreviewDynamicDataInsuredCapitalAndPrize",
            brokerData: "SeguroPrestamistaPolicyDataPreviewDynamicDataBrokerData",
            staticFields: "SeguroPrestamistaPolicyDataPreviewDynamicDataStaticFields"
        },
        SeguroPrestamistaPolicyDataPreviewStaticData: {
            definitions: "Definition[]?",
            clauses: "Clause[]"
        },
        SeguroPrestamistaPolicyDataPreview: {
            dynamicData: "SeguroPrestamistaPolicyDataPreviewDynamicData",
            staticData: "SeguroPrestamistaPolicyDataPreviewStaticData"
        },
        Amortization: {
            type: "AmortizationType",
            date: "string",
            installments: "uint?",
            value: "uint?",
            order: "AmortizationOrder"
        },
        AmortizationSimulation: {
            balance: "uint",
            payValue: "uint",
            remainingValue: "uint",
            remainingInstallments: "uint?",
            nextExpirationDate: "date?",
            nextInstallmentValue: "uint?",
            lastExpirationDate: "date?",
            lastInstallmentValue: "uint?"
        },
        AssociatedLoanProposal: {
            id: "string",
            name: "string",
            companyName: "string",
            cpf: "string",
            status: "LoanProposalPFStatus",
            summary: "SimulatedLoanSummary",
            createdAt: "string",
            proposalNumber: "string",
            ccbNumber: "string?",
            corbanResponsible: "string",
            loanStatus: "string?",
            userCompanyId: "string"
        },
        Address: {
            cep: "string",
            street: "string",
            number: "string",
            neighborhood: "string",
            city: "string",
            state: "string",
            complement: "string?"
        },
        BankAccount: {
            bankName: "string?",
            bankNumber: "string?",
            branchNumber: "string?",
            accountNumber: "string?",
            accountType: "AccountType?"
        },
        FinancialData: {
            userPaymentChoise: "UserPaymentChoise",
            bankAccount: "BankAccount?",
            pixKey: "string?"
        },
        DetailedInfo: {
            rg: "string",
            dispatcher: "string",
            uf: "string",
            rgIssuanceDate: "string",
            mothersName: "string",
            fathersName: "string",
            cityOfBirth: "string",
            nationality: "string",
            civilState: "CivilState",
            gender: "Gender",
            address: "Address",
            residentialPhone: "string?",
            mobilePhone: "string",
            secondaryEmail: "string?",
            financialData: "FinancialData",
            pep: "bool?"
        },
        UserGeneral: {
            email: "string?",
            companyName: "string?",
            companyFantasyName: "string?"
        },
        User: {
            id: "string",
            codigoClienteOmie: "bigint?",
            name: "string",
            cpf: "string",
            salary: "int?",
            mobilePhone: "string?",
            companyId: "string?",
            dateOfBirth: "date?",
            admissionDate: "date?",
            companySector: "string?",
            companyPosition: "string?",
            detailedInfo: "DetailedInfo?",
            updatedAt: "datetime?",
            deleted: "bool?",
            addressStreet: "string?",
            addressNumber: "string?",
            addressCity: "string?",
            addressState: "string?",
            addressCep: "string?",
            acceptedTheTermsAt: "date?",
            grossSalary: "int?",
            externalId: "string?",
            availableMarginValue: "int?",
            usersCompaniesDeleted: "bool?",
            email: "string?",
            companyName: "string?",
            companyFantasyName: "string?"
        },
        UserInfo: {
            cpf: "string",
            rg: "string",
            dispatcher: "string",
            uf: "string",
            rgIssuanceDate: "string",
            mothersName: "string",
            fathersName: "string",
            cityOfBirth: "string",
            nationality: "string",
            civilState: "UserInfoCivilState",
            gender: "UserInfoGender",
            address: "Address",
            mobilePhone: "string",
            pep: "bool",
            email: "string",
            acceptedTheTermsAt: "datetime",
            externalId: "string",
            bankAccount: "BankAccount",
            dateOfBirth: "string"
        },
        PublicEmployee: {
            state: "string",
            city: "string?",
            modality: "Modality"
        },
        UserRegistrationInfo: {
            employeeType: "EmployeeType",
            cpf: "string",
            publicEmployee: "PublicEmployee?"
        },
        UserLoanInfo: {
            isAllowedToMakeLoanRequest: "bool",
            notAllowedMessage: "string?",
            shouldDisplayWarningOnLoanRequest: "bool",
            loanRequestWarningMessage: "string?",
            userIsEmployed: "bool",
            isAllowedToSeguroPrestamistaRequest: "bool",
            availableValue: "uint?",
            minimumValueAllowedByAgreement: "uint?",
            loans: "UserLoanSummary[]",
            isAllowedSalaryAnticipation: "bool"
        },
        UserLoanInfoForCompany: {
            name: "string",
            userId: "string",
            isAllowedToMakeLoanRequest: "bool",
            notAllowedMessage: "string?",
            shouldDisplayWarningOnLoanRequest: "bool",
            loanRequestWarningMessage: "string?",
            userIsEmployed: "bool",
            isAllowedToSeguroPrestamistaRequest: "bool",
            availableValue: "uint?",
            minimumValueAllowedByAgreement: "uint?",
            loans: "UserLoanSummary[]",
            isAllowedSalaryAnticipation: "bool"
        },
        UserLoanSummary: {
            loanId: "string",
            companyName: "string",
            companyFantasyName: "string?",
            status: "LoanStatus?",
            statusV3: "LoanStatusV3?",
            statusV2: "LoanStatusV2?",
            ccbNumber: "string?",
            installmentValue: "uint",
            nextPayDate: "string",
            remainingInstallments: "uint",
            loanTotalValue: "uint",
            totalValue: "uint",
            monthlyInterestRate: "float",
            monthlyEffectiveCost: "float",
            balance: "uint?",
            totalIof: "float",
            structuringValue: "float",
            allInstallments: "AllInstallments[]?",
            thirdPartyValue: "int",
            responsible: "ResponsibleForPayment"
        },
        AllInstallments: {
            number: "int",
            status: "InstallmentStatus",
            statusV2: "InstallmentStatusV2",
            isOverdue: "bool?",
            dueDate: "string",
            paidValue: "uint?",
            paidAt: "string?"
        },
        LoanSimulationRequestParams: {
            numberOfInstallments: "uint",
            totalValue: "uint"
        },
        PayrollSummary: {
            totalAmount: "int",
            totalItems: "int"
        },
        Ccb: {
            id: "string",
            number: "string"
        },
        PayrollFilter: {
            companies: "string[]?",
            companySector: "string?"
        },
        PayrollInfo: {
            name: "string",
            cpf: "string",
            ccbLink: "string",
            companyName: "string",
            ccb: "Ccb",
            dueDate: "string?",
            value: "int",
            assigneCompanyFromCession: "string?",
            contractDate: "string",
            companySector: "string",
            currentInstallmentNumber: "uint",
            totalInstallments: "uint",
            loanGrossValue: "uint",
            firstInstallmentDate: "string",
            lastInstallmentDate: "string",
            extendedDueDate: "string?",
            extendedReason: "string?"
        },
        PayrollReport: {
            currentPage: "int",
            rowsPerPage: "int",
            payrollInfos: "PayrollInfo[]"
        },
        LoanRequestsCompany: {
            name: "string",
            cnpj: "string"
        },
        LoanRequests: {
            id: "string",
            userName: "string",
            cpf: "string",
            salary: "int",
            valueRequested: "int",
            percentage: "float",
            numberOfInstallments: "int",
            emittedDate: "string",
            admissionDate: "string?",
            company: "LoanRequestsCompany"
        },
        LoanRequestsFilter: {
            companiesId: "string[]?"
        },
        LoanRequestsPaginated: {
            currentPage: "int",
            rowsPerPage: "int",
            requests: "LoanRequests[]"
        },
        ContractReport: {
            currentPage: "uint",
            rowsPerPage: "uint",
            contracts: "Contract[]"
        },
        ContractCompany: {
            name: "string",
            cnpj: "string"
        },
        Contract: {
            id: "string",
            name: "string",
            cpf: "string",
            status: "ContractStatus",
            ccbId: "string?",
            ccbNumber: "string?",
            ccbLink: "string?",
            signDate: "date?",
            inssAway: "bool",
            totalValue: "uint",
            paidValue: "uint?",
            remainingValue: "uint?",
            totalInstallments: "uint",
            remainingInstallments: "uint?",
            balance: "uint?",
            company: "ContractCompany"
        },
        ContractFilterDate: {
            from: "date?",
            to: "date?"
        },
        ContractFilter: {
            cpf: "string?",
            status: "string?",
            date: "ContractFilterDate",
            companiesIds: "string[]?"
        },
        PayrollFromFile: {
            id: "string",
            uploadAt: "datetime",
            filename: "string",
            employeeName: "string"
        },
        PayrollSubmission: {
            payroll: "PayrollFromFile",
            conflicts: "Conflict[]"
        },
        PayrollsPaginated: {
            currentPage: "int",
            rowsPerPage: "int",
            payrolls: "PayrollFromFile[]"
        },
        CompanyEmployesFilter: {
            companyId: "string",
            value: "string?"
        },
        CompanyEmployee: {
            id: "string",
            name: "string",
            cpf: "string",
            sector: "string?",
            contractedLoansNumber: "int"
        },
        CompanyEmployeeReport: {
            currentPage: "uint",
            rowsPerPage: "uint",
            companyEmployees: "CompanyEmployee[]"
        },
        EmployeeFiringPreview: {
            id: "string",
            name: "string",
            cpf: "string",
            activeLoans: "int"
        },
        Firings: {
            changeId: "string",
            createdAt: "datetime",
            totalChanges: "int"
        },
        LoanProposalPF: {
            id: "string",
            createdBy: "string",
            userCompanyId: "string",
            status: "LoanProposalPFStatus",
            summary: "SimulatedLoanSummary",
            proposalNumber: "string"
        },
        ProposalFilterDate: {
            from: "date?",
            to: "date?"
        },
        ProposalFilter: {
            cpf: "string?",
            status: "string?",
            date: "ProposalFilterDate",
            companiesIds: "string[]?",
            proposalNumber: "string?",
            cpfResponsibleCorban: "string?",
            loanStatus: "string?"
        },
        LoanStatusReport: [
            "pending_hr",
            "pending_credifit",
            "active",
            "denied_hr",
            "denied_credifit",
            "canceled_by_user",
            "given",
            "partial_given",
            "closed",
            "canceled_by_credifit",
            "pending_identity_validations"
        ],
        LoanFgtsStatus: [
            "processingCredifit",
            "active",
            "processingCaixa",
            "deniedCaixa",
            "failedCaixa",
            "canceledCredifit",
            "pendingIdentityValidations"
        ],
        PrecatoryStatus: [
            "active",
            "pendingIdentityValidations",
            "failed"
        ],
        ConflictStatus: [
            "fired",
            "hired",
            "salaryUpgrade",
            "InssRemoval",
            "InssReturn",
            "nameChange",
            "monthlyPayrollDiscountChange"
        ],
        CreditCalculationAlgorithm: [
            "default",
            "machine_learning"
        ],
        PersonalDocumentType: [
            "RG",
            "CNH",
            "CRC",
            "OAB",
            "CRA",
            "CRM",
            "CRO"
        ],
        MarriageRegime: [
            "partialCommunion",
            "universalCommunion",
            "completeSeparation"
        ],
        PaymentMethod: [
            "bankSlip",
            "electronicAvailableTransfer",
            "accountDebit",
            "directDebit"
        ],
        OrderCalculationType: [
            "debtBalanceIncident",
            "installmentAmortizationIncident"
        ],
        EconomicSector: [
            "private",
            "municipal",
            "state",
            "federal"
        ],
        CapitalOrigin: [
            "national",
            "foreign",
            "mix",
            "other"
        ],
        ResponsibleForPayment: [
            "user",
            "company"
        ],
        ValidationReportResult: [
            "valid",
            "invalid",
            "processing"
        ],
        AmortizationOrder: [
            "ascending",
            "descending"
        ],
        AmortizationType: [
            "installments",
            "value"
        ],
        LoanProposalPFStatus: [
            "created",
            "pending_signature",
            "signed",
            "refused",
            "canceled_corban"
        ],
        CivilState: [
            "single",
            "married",
            "widow",
            "divorcee",
            "separate"
        ],
        Gender: [
            "male",
            "female",
            "other"
        ],
        AccountType: [
            "checking",
            "saving"
        ],
        UserPaymentChoise: [
            "byPix",
            "byBankAccount"
        ],
        UserStatus: [
            "registered",
            "pendingEmail",
            "pendingPassword",
            "inexistent",
            "blocked",
            "pendingTermsOfUse",
            "unhired",
            "suspended",
            "pendingIdentityValidations"
        ],
        UserInfoCivilState: [
            "single",
            "married",
            "divorced",
            "widowed"
        ],
        UserInfoGender: [
            "male",
            "female"
        ],
        EmployeeType: [
            "public",
            "private"
        ],
        Modality: [
            "state",
            "municipal",
            "federal"
        ],
        LoginOrigin: [
            "SPCRED",
            "CREDIFIT"
        ],
        LoanStatusV2: [
            "pending_hr",
            "pending_credifit",
            "active"
        ],
        LoanStatusV3: [
            "pending_identity_validations",
            "pending_hr",
            "pending_credifit",
            "active"
        ],
        InstallmentStatus: [
            "open",
            "paid",
            "paidByAmortization"
        ],
        InstallmentStatusV2: [
            "open",
            "paid",
            "paidByAmortization",
            "processing"
        ],
        LoanStatus: [
            "pending",
            "active"
        ],
        ApprovalDecision: [
            "approved",
            "denied"
        ],
        ContractStatus: [
            "pendingHr",
            "pendingCredifit",
            "active",
            "deniedHr",
            "deniedCredifit",
            "closed",
            "canceledByUser",
            "canceledByCredifit"
        ]
    }
};
