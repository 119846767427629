import React, { useMemo } from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Form, Input, Spin, Alert } from "antd";
import s from "./style.scss";
import { isValidPassword } from "../../utils/formValidator";
import { useTranslation } from "react-i18next";
const InnerForm = observer((props) => {
    const { getFieldDecorator, validateFields, getFieldsError, getFieldError, isFieldTouched, getFieldValue, } = props.form;
    const [validated, setValidated] = useState("");
    const currentPasswordError = isFieldTouched("currentPassword") && getFieldError("currentPassword");
    const newPasswordError = isFieldTouched("password") && getFieldError("password");
    const passwordConfirmError = isFieldTouched("passwordConfirm") && getFieldError("passwordConfirm");
    const { t } = useTranslation();
    const handleSubmit = (e) => {
        setValidated("");
        e.preventDefault();
        validateFields((errors, values) => {
            if (!errors) {
                if (props.hasCurrentPassword) {
                    props.handleSubmit(values.password, values.passwordConfirm, values.currentPassword);
                }
                else {
                    props.handleSubmit(values.password, values.passwordConfirm);
                }
            }
        });
    };
    const compareToFirstPassword = (_, value, callback) => {
        const form = props.form;
        if (value && value !== form.getFieldValue("password")) {
            callback(t("authentication.passwordsDontMatch"));
        }
        else {
            callback();
        }
    };
    const passwordIsValid = (_, value, callback) => {
        if (value && !isValidPassword(value)) {
            callback(t("authentication.passwordValidation"));
        }
        else {
            callback();
        }
    };
    const hasExtraText = () => {
        if (!hasExtraText) {
            return (React.createElement(React.Fragment, null,
                t("authentication.passwordLimitCharactersText"),
                " ",
                React.createElement("br", null),
                t("authentication.caseSensitivePasswordText")));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    };
    const hasErrors = (fieldErrors) => {
        return Object.keys(fieldErrors).some((field) => fieldErrors[field]);
    };
    const shouldSubmit = useMemo(() => {
        const password = getFieldValue("password");
        const passwordConfirm = getFieldValue("passwordConfirm");
        return password && password === passwordConfirm && !hasErrors(getFieldsError());
    }, [getFieldValue("password"), getFieldValue("passwordConfirm")]);
    return (React.createElement(Form, { className: s.form, layout: "vertical", hideRequiredMark: true, onSubmit: handleSubmit },
        props.email && (React.createElement("h1", null,
            t("authentication.registeringToEmailText"),
            " ",
            props.email)),
        React.createElement(Spin, { spinning: props.loading },
            props.hasCurrentPassword && (React.createElement(Form.Item, { label: t("authentication.currentPassword"), validateStatus: currentPasswordError ? "error" : "", help: currentPasswordError || "" }, getFieldDecorator("currentPassword", {
                rules: [
                    {
                        required: true,
                        message: React.createElement(React.Fragment, null,
                            " ",
                            t("authentication.emptyCurrentPasswordMessage")),
                    },
                ],
            })(React.createElement(Input, { type: "password", autoFocus: true, allowClear: true })))),
            React.createElement(Form.Item, { label: t("authentication.newPassword"), extra: hasExtraText(), validateStatus: newPasswordError ? "error" : "", help: newPasswordError || "" }, getFieldDecorator("password", {
                rules: [
                    {
                        required: true,
                        message: React.createElement(React.Fragment, null, t("authentication.emptyPasswordMessage")),
                    },
                    {
                        min: 6,
                        message: React.createElement(React.Fragment, null, t("authentication.passwordMinCharactersText")),
                    },
                    {
                        max: 16,
                        message: React.createElement(React.Fragment, null, t("authentication.passwordMaxCharactersText")),
                    },
                    {
                        validator: passwordIsValid,
                    },
                ],
            })(React.createElement(Input, { type: "password", allowClear: true, placeholder: t("authentication.insertPasswordText") }))),
            React.createElement(Form.Item, { label: t("authentication.confirmNewPassword"), validateStatus: passwordConfirmError ? "error" : "", help: passwordConfirmError || "" }, getFieldDecorator("passwordConfirm", {
                rules: [
                    {
                        required: true,
                        message: React.createElement(React.Fragment, null, t("authentication.confirmPasswordText")),
                    },
                    {
                        validator: compareToFirstPassword,
                    },
                ],
            })(React.createElement(Input, { type: "password", placeholder: t("authentication.retypePassword"), allowClear: true }))),
            props.error && (React.createElement(Form.Item, null,
                React.createElement(Alert, { message: props.error, type: "error", showIcon: true, closable: true }))),
            validated && (React.createElement(Form.Item, null,
                React.createElement(Alert, { message: validated, type: "success", showIcon: true, closable: true }))),
            hasExtraText() && (React.createElement("h3", { className: s.text },
                t("authentication.passwordLimitCharactersText"),
                " ",
                React.createElement("br", null),
                t("authentication.caseSensitivePasswordText"))),
            React.createElement(Form.Item, { className: s.centerContainerButton },
                React.createElement(Button, { type: "primary", size: "large", disabled: !shouldSubmit, htmlType: "submit" }, props.buttonText || t("authentication.login"))))));
});
export const PasswordConfirmForm = Form.create({
    name: "password_confirm_form",
})(InnerForm);
