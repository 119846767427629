import { __rest } from "tslib";
import React from "react";
import MaskedInput from "antd-mask-input";
import { Input } from "antd";
import Form from "antd/lib/form";
import DateRangePicker from "../DateRangePicker";
import SelectSearchField from "../SelectSearchField";
import s from "./style.scss";
const FilterItem = (_a) => {
    var { form, selectOptions, selectDefaultValue, inputMask, name, type, placeholder, inputDefaultValue, mode, onChange } = _a, rest = __rest(_a, ["form", "selectOptions", "selectDefaultValue", "inputMask", "name", "type", "placeholder", "inputDefaultValue", "mode", "onChange"]);
    const { getFieldDecorator } = form;
    if (type === "input") {
        return inputMask ? (React.createElement(Form.Item, { className: s.itemInput, key: name }, getFieldDecorator(name, {})(React.createElement(MaskedInput, Object.assign({ allowClear: true, mask: inputMask, placeholder: placeholder, onChange: onChange }, rest))))) : (React.createElement(Form.Item, { className: s.itemInput, key: name }, getFieldDecorator(name, {
            initialValue: inputDefaultValue,
        })(React.createElement(Input, Object.assign({ allowClear: true, placeholder: placeholder, onChange: onChange }, rest)))));
    }
    if (type === "select") {
        return (React.createElement(Form.Item, { className: s.itemSelect, key: name }, getFieldDecorator(name, {
            initialValue: selectDefaultValue,
        })(React.createElement(SelectSearchField, Object.assign({ mode: mode, placeholder: placeholder, selectOptions: selectOptions }, rest)))));
    }
    if (type === "dateRange") {
        return (React.createElement(DateRangePicker, { className: s.date, form: form, keyName: name, dateFormat: "DD/MM/YYYY" }));
    }
    return null;
};
export default FilterItem;
