import { __awaiter } from "tslib";
import { onlyNumbers } from "@brazilian-utils/brazilian-utils";
import { Form, Modal, Spin } from "antd";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getProposalsTableColumns } from "../../../company/components/ProposalTable/column";
import ActionTable from "../../../shared/components/ActionTable";
import { FilterForm } from "../../../shared/components/FilterForm";
import { ListColumnInfo } from "../../../shared/components/ListColumnInfo";
import { SectionContainer } from "../../../shared/components/SectionContainer";
import { getCompanyContractsStatusLabels, getStatusLabels, } from "../../../shared/constants/contracts.constants";
import { getProposalStatusLabelsOptions } from "../../../shared/constants/proposals.constants";
import { defaultConfirmProps } from "../../../shared/utils";
import { formatDate, getTodayDateLocalTimezoneFormat } from "../../../shared/utils/date";
import { formatCpf } from "../../../shared/utils/formatters";
import { mapCompaniesToSelectOptions } from "../../../shared/utils/mappers";
import { filterOption } from "../../../shared/utils/select";
import InstallmentsModalStep from "../../components/InstallmentsModalStep";
import LoanInitModal from "../../components/LoanInitModal";
import LoanValueModal from "../../components/LoanValueModal";
import ResumeLoanModal from "../../components/ResumeLoanModal";
import { SectionHeader } from "../../components/SectionHeader";
import { useStores } from "../../utils/mobx";
import s from "./style.scss";
var ModalType;
(function (ModalType) {
    ModalType[ModalType["NONE"] = 0] = "NONE";
    ModalType[ModalType["LOAN_INIT"] = 1] = "LOAN_INIT";
    ModalType[ModalType["LOAN_VALUE"] = 2] = "LOAN_VALUE";
    ModalType[ModalType["RESUME_LOAN"] = 3] = "RESUME_LOAN";
    ModalType[ModalType["LOAN_INSTALLMENTS"] = 4] = "LOAN_INSTALLMENTS";
})(ModalType || (ModalType = {}));
const initialLoanState = {
    modalType: ModalType.NONE,
    isConsultLoanButtonDisabled: true,
    simulationValueCpf: "",
    minimumValue: 0,
    maximumValue: 0,
    userNameLoan: "",
    loadingConsult: false,
    requestedAmount: null,
    userId: null,
    selectedInstallment: { value: 0, numberOfInstallments: 0, highlight: false },
    userSummary: {
        additionalInfo: {
            additionalSeguroPrestamistaValue: "",
            additionalSeguroPrestamistaValuePerInstallment: "",
            firstInstallmentDue: "",
            insuranceMonthlyGrossPrize: "",
            lastInstallmentDue: "",
            monthlyInterestRate: "",
        },
        installmentsValue: "",
        loanTotalValue: "",
        numberOfInstallments: "",
        taxesAndRates: "",
        totalValue: "",
    },
    selectedProposalsKeys: [],
    selectedProposals: [],
};
const Proposals = observer(({ form }) => {
    var _a;
    const { t } = useTranslation();
    const { authStore } = useStores();
    const { setFieldsValue } = form;
    const [loanState, setLoanState] = useState(initialLoanState);
    const [simulatedInstallments, setSimulatedInstallments] = useState([]);
    const [selectedInstallment, setSelectedInstallment] = useState(null);
    const [proposals, setProposals] = useState([]);
    const [loadingContracts, setLoadingProposals] = useState(true);
    const [renderKey, setRenderKey] = useState(0);
    const [currentFilter, setCurrentFilter] = useState({
        cpf: "",
        status: "",
        date: {
            from: null,
            to: null,
        },
        companiesIds: [],
        proposalNumber: "",
        cpfResponsibleCorban: "",
        loanStatus: "",
    });
    const { generalStore: { setErrorMessage, catchErrors, getSelectedCompany, setSuccessMessage }, proposalStore, } = useStores();
    const confirm = Modal.confirm;
    const proposalLabels = {
        name: t("contractModalNameLabel"),
        cpf: "CPF",
        status: t("contractModalStatusLabel"),
        ccb: "CCB",
        ccbNumber: t("ccbNumberLabel"),
        signDate: t("contractModalDateLabel"),
        inssAway: "STATUS",
        totalValue: t("contractModalTotalValueLabel"),
        paidValue: t("contractModalPaidValueLabel"),
        remainingValue: `${t("contractModalRemainingValueLabel")} ${getTodayDateLocalTimezoneFormat()}`,
        totalInstallments: t("contractModalInstallmentsLabel"),
        remainingInstallments: t("contractModalRemainingInstallmentsLabel"),
        installmentsValue: t("contractModalInstallmentsValueLabel"),
        taxesAndRates: t("contractModalTaxesAndRatesLabel"),
        loanTotalValue: t("contractModalLoanTotalValueLabel"),
        numberOfInstallments: t("numberOfInstallments"),
        additionalSeguroPrestamistaValuePerInstallment: t("additionalSeguroPrestamistaValuePerInstallment"),
        firstInstallmentDue: t("firstInstallmentDue"),
        lastInstallmentDue: t("lastInstallmentDue"),
        monthlyInterestRate: t("monthlyInterestRate"),
        insuranceMonthlyGrossPrize: t("insuranceMonthlyGrossPrize"),
        additionalSeguroPrestamistaValue: t("additionalSeguroPrestamistaValue"),
    };
    const loanStatusLabels = getStatusLabels(t);
    const showModal = (row) => {
        confirm(Object.assign(Object.assign({}, defaultConfirmProps), { title: (React.createElement("div", { className: s.modalHeaderDialog },
                React.createElement("h2", { className: s.modalTitleDialog }, row.name))), content: (React.createElement("div", { className: s.modal },
                React.createElement("div", { className: s.modalBodyDialog },
                    React.createElement(ListColumnInfo, { labels: [
                            proposalLabels.cpf,
                            proposalLabels.signDate,
                            proposalLabels.status,
                            proposalLabels.installmentsValue,
                            proposalLabels.totalValue,
                            proposalLabels.loanTotalValue,
                            proposalLabels.numberOfInstallments,
                            proposalLabels.taxesAndRates,
                        ], values: [
                            row.cpf,
                            row.createdAt,
                            row.status,
                            row.summary.installmentsValue,
                            row.summary.totalValue,
                            row.summary.loanTotalValue,
                            row.summary.numberOfInstallments,
                            row.summary.taxesAndRates,
                        ] })),
                React.createElement("div", { className: s.labelBodyInformation },
                    React.createElement("div", { className: s.textBodyInformation }, "Informa\u00E7\u00F5es Adicionais")),
                React.createElement("div", { className: s.modalBodyDialog },
                    React.createElement(ListColumnInfo, { labels: [
                            proposalLabels.additionalSeguroPrestamistaValue,
                            proposalLabels.additionalSeguroPrestamistaValuePerInstallment,
                            proposalLabels.firstInstallmentDue,
                            proposalLabels.lastInstallmentDue,
                            proposalLabels.monthlyInterestRate,
                            proposalLabels.insuranceMonthlyGrossPrize,
                        ], values: [
                            row.summary.additionalInfo.additionalSeguroPrestamistaValue,
                            row.summary.additionalInfo.additionalSeguroPrestamistaValuePerInstallment,
                            row.summary.additionalInfo.firstInstallmentDue,
                            row.summary.additionalInfo.lastInstallmentDue,
                            row.summary.additionalInfo.monthlyInterestRate,
                            row.summary.additionalInfo.insuranceMonthlyGrossPrize,
                        ] })))), className: s.containerDialog, cancelText: t("closeText"), width: "95%", okButtonProps: { style: { display: "none" } }, style: { maxWidth: 900 }, maskClosable: true }));
    };
    const items = [
        {
            key: "data",
            name: "data",
            type: "dateRange",
        },
        {
            key: "proposalNumber",
            name: "proposalNumber",
            type: "input",
            placeholder: "Nº Proposta",
        },
        {
            key: "status",
            name: "status",
            type: "select",
            placeholder: "Status proposta",
            selectOptions: getProposalStatusLabelsOptions(t),
        },
        {
            key: "cpf",
            name: "cpf",
            type: "input",
            placeholder: "CPF",
            inputMask: "111.111.111-11",
        },
        {
            key: "companiesIds",
            name: "companiesIds",
            type: "select",
            placeholder: "Empresa",
            selectOptions: mapCompaniesToSelectOptions((_a = authStore.user) === null || _a === void 0 ? void 0 : _a.companies),
            mode: "multiple",
            props: {
                filterOption,
            },
        },
        {
            key: "cpfResponsibleCorban",
            name: "cpfResponsibleCorban",
            type: "input",
            placeholder: "CPF Responsável Corban",
            inputMask: "111.111.111-11",
        },
        {
            key: "loanStatus",
            name: "loanStatus",
            type: "select",
            placeholder: "Status empréstimo",
            selectOptions: getCompanyContractsStatusLabels(t),
        },
    ];
    const getProposals = (filter) => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingProposals(true);
        try {
            const response = yield proposalStore.getAssociatedLoansProposalsPF(filter);
            setProposals(response);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("proposalApiErrorMessage"));
        }
        finally {
            setLoadingProposals(false);
        }
    });
    const applyFilter = (values) => {
        const filter = {
            cpf: onlyNumbers(values.cpf),
            status: values.status,
            date: {
                from: values.dataInitial ? values.dataInitial.toDate() : null,
                to: values.dataFinal ? values.dataFinal.toDate() : null,
            },
            companiesIds: (values === null || values === void 0 ? void 0 : values.companiesIds) || [],
            proposalNumber: values.proposalNumber,
            cpfResponsibleCorban: onlyNumbers(values.cpfResponsibleCorban),
            loanStatus: values.loanStatus,
        };
        setCurrentFilter(filter);
    };
    React.useEffect(() => {
        getProposals(currentFilter);
    }, [currentFilter]);
    const handleLoanValueModalOk = (requestedAmount) => {
        if (requestedAmount !== undefined) {
            setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { requestedAmount, loadingConsult: true })));
            openInstallmentsModal(requestedAmount);
        }
        else {
            setErrorMessage(t("requestedAmountCannotBeEmpty"));
        }
    };
    const handleInstallmentsModalOk = (selectedCardInstallment) => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedCardInstallment) {
            setLoanState(Object.assign(Object.assign({}, loanState), { loadingConsult: true }));
            const userSummary = yield proposalStore.getLoanSimulationSummary({
                totalValue: loanState.requestedAmount,
                numberOfInstallments: selectedCardInstallment.numberOfInstallments,
            }, true, new Date(), loanState.simulationValueCpf, getSelectedCompany());
            closeModal(ModalType.NONE);
            setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { modalType: ModalType.RESUME_LOAN, loadingConsult: false, selectedInstallment: selectedCardInstallment, userSummary })));
        }
    });
    const handleCheckBoxPrestamista = (isSelectedCheck) => __awaiter(void 0, void 0, void 0, function* () {
        setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { loadingConsult: true })));
        try {
            const userSummary = yield proposalStore.getLoanSimulationSummary({
                totalValue: loanState.requestedAmount,
                numberOfInstallments: loanState.selectedInstallment.numberOfInstallments,
            }, isSelectedCheck, new Date(), loanState.simulationValueCpf, getSelectedCompany());
            setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { userSummary })));
        }
        finally {
            setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { loadingConsult: false })));
        }
    });
    const openInstallmentsModal = (requestedAmount) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const companyId = localStorage.getItem("selectedCompanyId");
            const installments = yield proposalStore.simulateInstallments(requestedAmount, loanState.userId, companyId, new Date(), true);
            setSimulatedInstallments(installments);
            setSelectedInstallment(null);
            setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { modalType: ModalType.LOAN_INSTALLMENTS, loadingConsult: false })));
        }
        catch (error) {
            closeModal(ModalType.NONE);
            catchErrors(error, setErrorMessage, t("installmentsApiErrorMessage"));
        }
    });
    const getUserLoan = (valueCpf) => __awaiter(void 0, void 0, void 0, function* () {
        if (!valueCpf) {
            setErrorMessage(t("cpfCannotBeEmpty"));
            return;
        }
        const companyId = getSelectedCompany();
        setLoanState(Object.assign(Object.assign({}, loanState), { loadingConsult: true }));
        try {
            const userLoan = yield proposalStore.getUserLoanInfos(companyId, valueCpf, null);
            setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { maximumValue: userLoan.availableValue || 0, minimumValue: userLoan.minimumValueAllowedByAgreement || 0, userNameLoan: userLoan.name, userId: userLoan.userId })));
            openModal(ModalType.LOAN_VALUE);
            setErrorMessage(null);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("userLoanApiErrorMessage"));
            closeModal(ModalType.NONE);
        }
        finally {
            setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { loadingConsult: false })));
        }
    });
    const handleLoanValueChange = (value) => {
        setFieldsValue({ requestedAmount: value });
    };
    const defineValueLoan = () => {
        if (loanState.simulationValueCpf) {
            getUserLoan(loanState.simulationValueCpf);
        }
        else {
            setErrorMessage(t("cpfCannotBeEmpty"));
        }
    };
    const openModal = (type) => {
        setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { modalType: type })));
    };
    const closeModal = (type) => {
        setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { modalType: type })));
    };
    const updateCpfInLoanState = (cpf) => {
        setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { simulationValueCpf: cpf })));
    };
    const updateButtonLoanState = (button) => {
        setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { isConsultLoanButtonDisabled: button })));
    };
    const saveLoanProposal = (cpf, companyId, summary) => __awaiter(void 0, void 0, void 0, function* () {
        setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { loadingConsult: true })));
        try {
            yield proposalStore.saveLoanProposalPF(cpf, companyId, summary);
            getProposals(currentFilter);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("userLoanSimulationApiErrorMessage"));
        }
        finally {
            setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { loadingConsult: false })));
            closeModal(ModalType.NONE);
        }
    });
    const handleSelectedChange = (selectedRowKeys, selectedRows) => {
        setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { selectedProposalsKeys: selectedRowKeys, selectedProposals: selectedRows })));
        setRenderKey((prevKey) => prevKey + 1);
    };
    const updateLoanProposalsStatus = (status, successMessage, errorMessage) => __awaiter(void 0, void 0, void 0, function* () {
        if (loanState.selectedProposalsKeys.length === 0) {
            setErrorMessage(t("noProposalsSelectedError"));
            return;
        }
        setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { loadingConsult: true })));
        try {
            const proposalIds = loanState.selectedProposals.map((proposal) => proposal.id);
            yield proposalStore.updateLoansProposalsStatus(proposalIds, status);
            setSuccessMessage(successMessage);
            setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { selectedProposalsKeys: [], selectedProposals: [] })));
            setRenderKey((prevKey) => prevKey + 1);
            getProposals(currentFilter);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, errorMessage);
        }
        finally {
            setLoanState((prevLoanState) => (Object.assign(Object.assign({}, prevLoanState), { loadingConsult: false })));
        }
    });
    const handleGenerateLoanContract = () => {
        updateLoanProposalsStatus("pending_signature", t("generatedSucessContractMessage"), t("updateStatusLoanApiErrorMessage"));
    };
    const handleCancelLoanProposal = () => {
        updateLoanProposalsStatus("canceled_corban", t("canceledSucessContractMessage"), t("updateStatusLoanApiErrorMessage"));
    };
    return (React.createElement("div", { className: s.containerContracts },
        React.createElement(SectionHeader, { title: t("proposalsLabel"), actions: [
                {
                    label: t("simulatedLoanLabel"),
                    handleAction: () => {
                        setLoanState(initialLoanState), openModal(ModalType.LOAN_INIT);
                    },
                },
            ] }),
        React.createElement(SectionContainer, { className: s.container },
            React.createElement(FilterForm, { items: items, handleSubmit: applyFilter }),
            React.createElement("div", { key: renderKey },
                React.createElement("div", { "data-cy": "tabble-wrapper-data-cy" },
                    React.createElement(Spin, { spinning: loanState.loadingConsult },
                        React.createElement(ActionTable, { className: s.table, rowClassName: () => {
                                if (s.rowClick) {
                                    return s.rowClick;
                                }
                                return "";
                            }, rowSelection: {
                                selectedRowKeys: loanState.selectedProposalsKeys,
                                onChange: handleSelectedChange,
                            }, columns: getProposalsTableColumns(t), loading: loadingContracts, onRow: (record) => {
                                return {
                                    onClick: () => showModal(record),
                                };
                            }, dataSource: proposals.map((contract) => {
                                var _a;
                                return (Object.assign(Object.assign({}, contract), { status: (React.createElement("span", { "data-cy": "statusLabels-wrapper-data-cy" }, (_a = getProposalStatusLabelsOptions(t).find((status) => status.value === contract.status)) === null || _a === void 0 ? void 0 : _a.label)), loanStatus: (React.createElement("span", { "data-cy": "statusLoanLabels-wrapper-data-cy" }, contract.loanStatus ? loanStatusLabels[contract.loanStatus] : "--")), createdAt: formatDate(new Date(contract.createdAt), "DD/MM/YYYY"), cpf: formatCpf(contract.cpf), corbanName: contract.corbanResponsible, ccbNumber: contract.ccbNumber ? contract.ccbNumber : "--" }));
                            }), actions: [
                                {
                                    value: "approved",
                                    label: t("generateLoanContract"),
                                    onClick: handleGenerateLoanContract,
                                },
                                {
                                    value: "canceled",
                                    label: t("cancelLoanProposal"),
                                    onClick: handleCancelLoanProposal,
                                },
                            ] }))))),
        loanState.modalType === ModalType.LOAN_INIT && (React.createElement(LoanInitModal, { visible: true, onOk: defineValueLoan, cpf: loanState.simulationValueCpf, setCpf: updateCpfInLoanState, setIsOkButtonDisabled: updateButtonLoanState, isOkButtonDisabled: loanState.isConsultLoanButtonDisabled, loading: loanState.loadingConsult, onCancel: () => closeModal(ModalType.NONE) })),
        loanState.modalType === ModalType.LOAN_VALUE && (React.createElement(LoanValueModal, { visible: true, onOk: handleLoanValueModalOk, onCancel: () => {
                setLoanState(initialLoanState), closeModal(ModalType.LOAN_INIT);
            }, requestedAmountKey: "requestedAmount", onChangeLoanValue: handleLoanValueChange, cpf: loanState.simulationValueCpf, minimumValue: loanState.minimumValue, maximumValue: loanState.maximumValue, nameUser: loanState.userNameLoan, sliderValue: loanState.requestedAmount || loanState.minimumValue, loading: loanState.loadingConsult })),
        loanState.modalType === ModalType.LOAN_INSTALLMENTS && (React.createElement(InstallmentsModalStep, { visible: true, onCancel: () => closeModal(ModalType.LOAN_VALUE), onOk: handleInstallmentsModalOk, cpf: loanState.simulationValueCpf, name: loanState.userNameLoan, value: `${loanState.requestedAmount}`, installments: simulatedInstallments, loading: loanState.loadingConsult, selectedInstallment: selectedInstallment, setSelectedInstallment: setSelectedInstallment })),
        loanState.modalType === ModalType.RESUME_LOAN && (React.createElement(ResumeLoanModal, { visible: true, onOk: () => {
                saveLoanProposal(loanState.simulationValueCpf, getSelectedCompany(), loanState.userSummary);
            }, onCancel: () => closeModal(ModalType.LOAN_INSTALLMENTS), cpf: loanState.simulationValueCpf, nameUser: loanState.userNameLoan, amountToCredit: loanState.userSummary.totalValue, taxes: loanState.userSummary.taxesAndRates, installment: `${loanState.selectedInstallment.numberOfInstallments}x ${loanState.userSummary.installmentsValue}`, additionalInformation: `Taxa: ${loanState.userSummary.additionalInfo.monthlyInterestRate} a.am., 1ª parcela: ${loanState.userSummary.additionalInfo.firstInstallmentDue},Última parcela: ${loanState.userSummary.additionalInfo.lastInstallmentDue}
          `, financedValue: loanState.userSummary.loanTotalValue, onCheckBoxPrestamista: handleCheckBoxPrestamista, loading: loanState.loadingConsult }))));
});
export default Form.create()(Proposals);
