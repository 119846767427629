import { __awaiter } from "tslib";
import React from "react";
import SubMenu from "antd/lib/menu/SubMenu";
import { NavLink, matchPath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Avatar, Badge, Menu } from "antd";
import { useStores } from "../../utils/mobx";
import { getUserInitials } from "../../../shared/utils";
import { Arrow } from "../../../shared/components/Icons/Arrow";
import s from "./style.scss";
import { DropdownCompanies } from "../../components/DropdownCompanies";
import { action } from "mobx";
const checkActive = (path) => {
    return !!matchPath(path, {
        path: window.location.pathname,
        exact: true,
    });
};
const activeItemKeysOfSubMenu = (menu) => {
    const isSubMenu = menu.itens && menu.itens.length > 0;
    if (isSubMenu) {
        return menu.itens.filter((item) => checkActive(item.to)).map((item) => item.label);
    }
    else {
        return menu.to && checkActive(menu.to) ? [menu.label] : [];
    }
};
const getActiveItems = (itens) => {
    var _a;
    if (!!((_a = Array.prototype) === null || _a === void 0 ? void 0 : _a.flatMap)) {
        return itens.flatMap((nav) => activeItemKeysOfSubMenu(nav));
    }
    else {
        return itens.reduce((acc, current) => acc.concat(activeItemKeysOfSubMenu(current)), []);
    }
};
export const Navbar = ({ itens, user, loanRequests }) => {
    const { t } = useTranslation();
    const { authStore, generalStore, generalStore: { catchErrors }, } = useStores();
    const activeItems = getActiveItems(itens);
    const isSubMenu = (item) => item.itens && item.itens.length;
    const logout = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield authStore.logout();
        }
        catch (error) {
            catchErrors(error, generalStore.setErrorMessage, t("unexpectedErrorMessage"));
        }
    });
    const changeSelectedCompanyId = action((companyId) => {
        const selectedCompanyId = generalStore.setSelectedCompany(companyId);
        return selectedCompanyId;
    });
    return (React.createElement(Menu, { className: s.navbar, mode: "horizontal", selectedKeys: activeItems },
        itens
            .filter((item) => !item.isUserSubmenu)
            .map((item) => isSubMenu(item) ? (React.createElement(SubMenu, { key: item.label, title: item.label },
            React.createElement(Arrow, { className: s.subMenuArrow }),
            item.itens &&
                item.itens.map((subItem) => (React.createElement(Menu.Item, { key: subItem.label },
                    React.createElement(NavLink, { to: subItem.to || "", exact: true }, subItem.label)))))) : (React.createElement(Menu.Item, { key: item.label },
            React.createElement(NavLink, { to: item.to || "", exact: true }, item.label === t("requestsLabel") ? (loanRequests && loanRequests > 0 ? (React.createElement(Badge, { count: "!", className: s.navBadgedItem }, item.label)) : (item.label)) : (item.label))))),
        user && (React.createElement(SubMenu, { title: React.createElement(Avatar, { className: s.avatar, size: "large" }, getUserInitials(user.name)) },
            React.createElement("div", { className: s.userInfo },
                React.createElement("div", { className: s.name }, user.name),
                React.createElement("div", null, user.email)),
            itens
                .filter((item) => item.isUserSubmenu)
                .map((item) => (React.createElement(Menu.Item, { key: item.label },
                React.createElement(NavLink, { to: item.to, exact: true }, item.label)))),
            React.createElement(Menu.Item, { onClick: logout }, "Logout"))),
        (user === null || user === void 0 ? void 0 : user.companies) && (React.createElement(Menu.Item, null,
            React.createElement(DropdownCompanies, { associateds: user.companies, companyId: changeSelectedCompanyId })))));
};
