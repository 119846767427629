import React from "react";
import { Form, Spin } from "antd";
import { getFormItemName } from "../../../shared/utils/form.utils";
import { EmployeesInfo } from "../EmployeeInfo";
import { FiringFormFields } from "../FiringEmployees/FiringFormFields";
import { RoundedButton } from "../RoundedButton";
import { useTranslation } from "react-i18next";
import styles from "./style.scss";
const FiredEmployeesDetailsComponent = ({ form, employeesDetails, loading, onBackButtonClick }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: styles.firedDetails },
        React.createElement(Spin, { spinning: loading },
            React.createElement("div", { className: styles.cardsWrapper, "data-cy": "cardsWrapper-data-cy" }, employeesDetails.map((employee, index) => {
                const fieldName = getFormItemName("firings", index);
                return (React.createElement("div", { key: employee.cpf, "data-cy": "firedCard-data-cy", className: styles.firedCard },
                    React.createElement(EmployeesInfo, { employee: employee }),
                    React.createElement(FiringFormFields, { employee: employee, employeeDetails: employee, fieldName: fieldName, form: form })));
            })),
            onBackButtonClick && (React.createElement("div", { className: styles.firingDetailsFooter },
                React.createElement(RoundedButton, { secondary: true, onClick: onBackButtonClick, "data-cy": "back-button" }, t("backLabel")))))));
};
export const FiredEmployeesDetails = Form.create({
    name: "fired_form",
})(FiredEmployeesDetailsComponent);
