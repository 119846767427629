import React from "react";
import { Progress } from "antd";
import { useClearCacheCtx } from "react-clear-cache";
import s from "./style.scss";
import { CredifitLogo } from "../Icons/CredifitLogo";
export const ClearCache = () => {
    const [percent, setPercent] = React.useState(0);
    const { emptyCacheStorage } = useClearCacheCtx();
    React.useEffect(() => {
        setTimeout(() => {
            emptyCacheStorage();
            window.location.reload();
        }, 1500);
    }, []);
    React.useEffect(() => {
        const timer = setInterval(() => {
            if (percent <= 100) {
                setPercent(percent + 100);
            }
            else {
                clearInterval(timer);
            }
        }, 700);
        return () => clearInterval(timer);
    }, [percent]);
    return (React.createElement("main", { className: s.container },
        React.createElement(CredifitLogo, { width: 310, height: 65 }),
        React.createElement("p", null, "Carregando atualiza\u00E7\u00F5es..."),
        React.createElement("div", { className: s.containerLoading },
            React.createElement(Progress, { percent: percent, strokeColor: "#FF7500" }))));
};
