import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonGroup } from "../../../../shared/components/ButtonGroup";
import { IconButton } from "../../../../shared/components/IconButton";
import { File } from "../../../../shared/components/Icons/File";
import XlsPicker from "../../../components/XlsPicker";
import { payrollSheetSchema } from "../types";
import s from "../style.scss";
const EmptyPayrolls = ({ downloadModelFile, onUploadSheet }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: s.container },
        React.createElement("h4", { className: s.title }, t("emptyPayrollImportTitle")),
        React.createElement(ButtonGroup, null,
            React.createElement(IconButton, { buttonIcon: File, onClick: downloadModelFile }, t("sampleDocumentLabel")),
            React.createElement(XlsPicker, { onChangeValue: onUploadSheet, schema: payrollSheetSchema }))));
};
export default EmptyPayrolls;
