import React from "react";
import { SemanticButton } from "../../../shared/components/SemanticButton";
import { CircledDownloadIcon } from "../../../shared/components/Icons/CircledDownload";
const CCBButton = (props) => {
    return (React.createElement(SemanticButton, { disabled: props.ccb === null, onClick: (e) => {
            props.handleClick(props.ccb, e);
        } },
        React.createElement(CircledDownloadIcon, null)));
};
export default CCBButton;
