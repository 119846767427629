/**
 * Informartion about the domain of the portal <Admin | Company>
 * It is requered to build the ApiClient object.
 */
class DomainInfoFactory {
    constructor(type) {
        this.type = type;
        this.sufix = {
            develop: "develop-",
            staging: "staging-",
            master: "",
            local: "develop-",
        }[process.env.NODE_ENV];
        this.buildUrl(type);
        this.baseUrl = `${this.url}`;
    }
    buildUrl(type) {
        this.url = {
            develop: `https://${this.sufix}${type}-api.credifit.com.br`,
            staging: `https://${this.sufix}${type}-api.credifit.com.br`,
            master: `https://${this.sufix}${type}-api.credifit.com.br`,
            local: `http://localhost:8000`,
        }[process.env.NODE_ENV];
    }
}
export function createDomainInfo(type) {
    return new DomainInfoFactory(type);
}
