import { Checkbox, Input, Modal, Spin } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ListColumnInfo } from "../../../shared/components/ListColumnInfo";
import { Typograph } from "../../../shared/components/Typograph";
import s from "./style.scss";
const ResumeModal = ({ visible, onOk, onCancel, cpf, nameUser, amountToCredit, taxes, installment, additionalInformation, financedValue, onCheckBoxPrestamista, loading, }) => {
    const { t } = useTranslation();
    const modalLabels = {
        amountToCredit: t("loanResumeModalAmountToCredit"),
        installment: t("loanResumeModalInstallment"),
        financedValue: t("loanResumeModalFinancedValue"),
        taxes: t("loanResumeModalTaxes"),
        additionalInformation: t("loanResumeModalInforesumo"),
    };
    const formatAdditionalInformation = (info) => {
        return info.replace(/, /g, ",\n").replace(/Última parcela:/g, "\nÚltima parcela:");
    };
    const [isChecked, setIsChecked] = useState(true);
    const toggleCheckbox = () => {
        const newChecked = !isChecked;
        setIsChecked(newChecked);
        onCheckBoxPrestamista(newChecked);
    };
    return (React.createElement(Modal, { visible: visible, title: React.createElement("div", { className: s.modalHeaderDialogLoan },
            React.createElement("h2", { className: s.textTitleDialogLoan }, "VER RESUMO DA SIMULA\u00C7\u00C3O")), className: s.containerDialog, okText: t("solicitationSimulationLoan"), cancelText: t("backLabel"), closable: false, onOk: onOk, onCancel: onCancel, okButtonProps: {
            type: "primary",
            disabled: loading,
        } },
        React.createElement("div", { className: s.modal },
            React.createElement(Spin, { spinning: loading },
                React.createElement("div", { className: s.modalBodyDialogLoan },
                    React.createElement("div", { className: s.labelCpfValue },
                        React.createElement(Typograph, { className: s.textCpf, variant: "modalTitle" }, "CPF"),
                        React.createElement(Input, { className: s.inputUserInfo, "data-cy": "valueCpfLoanDataCy", allowClear: true, value: cpf, disabled: true })),
                    React.createElement("div", { className: s.labelName },
                        React.createElement(Typograph, { className: s.textName, variant: "modalTitle" }, "Nome"),
                        React.createElement(Input, { className: s.inputUserInfo, "data-cy": "valueNameUserLoanDataCy", allowClear: true, value: nameUser, disabled: true })),
                    React.createElement("div", { className: s.labelBodyInformation },
                        React.createElement("div", { className: s.textBodyInformation }, "Ver resumo")),
                    React.createElement("div", { className: s.labelBodyLists },
                        React.createElement(ListColumnInfo, { labels: [
                                modalLabels.amountToCredit,
                                modalLabels.installment,
                                modalLabels.financedValue,
                                modalLabels.taxes,
                                modalLabels.additionalInformation,
                            ], values: [
                                amountToCredit,
                                installment,
                                financedValue,
                                taxes,
                                formatAdditionalInformation(additionalInformation),
                            ] }),
                        React.createElement(Checkbox, { className: s.customCheck, checked: isChecked, onClick: toggleCheckbox, "data-cy": "checkPrestamista" }, "Seguro prestamista incluso")))))));
};
export default ResumeModal;
