import { Modal, Spin } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatStringCurrency } from "../../../shared/utils/formatters";
import s from "./style.scss";
const InstallmentsModalStep = ({ visible, onCancel, onOk, cpf, name, value, installments, loading, selectedInstallment, setSelectedInstallment, }) => {
    const { t } = useTranslation();
    const handleCardClick = (numberOfInstallments) => {
        setSelectedInstallment(numberOfInstallments);
    };
    const handleOkClick = () => {
        const selected = installments.find((installment) => installment.numberOfInstallments === selectedInstallment);
        onOk(selected || null);
    };
    return (React.createElement(Modal, { visible: visible, title: React.createElement("div", { className: s.modalHeaderDialogLoan },
            React.createElement("h2", { className: s.textTitleDialogLoan }, t("pickInstallmentsHeader"))), closable: false, okText: t("startLoanRequestLabel"), cancelText: t("backLabel"), width: "50%", centered: true, maskClosable: true, style: { height: "382px", maxWidth: "900px" }, okButtonProps: {
            icon: "check",
            type: "primary",
            disabled: !selectedInstallment,
            id: "select-installments-button",
        }, onOk: handleOkClick, onCancel: onCancel, className: s.containerDialog },
        React.createElement(Spin, { spinning: loading },
            React.createElement("div", { className: s.modalBodyDialog },
                React.createElement("div", { className: s.threeColumnsDiv },
                    React.createElement("div", { className: s.divUserInfo },
                        React.createElement("label", null, t("cpfLabel")),
                        React.createElement("input", { className: s.inputInstallmentsModal, "data-cy": "installment-modal-cpf", value: cpf, disabled: true })),
                    React.createElement("div", { className: s.divUserInfo },
                        React.createElement("label", null, t("nameLabel")),
                        React.createElement("input", { className: s.inputInstallmentsModal, "data-cy": "installment-modal-name", value: name, disabled: true })),
                    React.createElement("div", { className: s.divUserInfo },
                        React.createElement("label", null, t("installmentValueLabel")),
                        React.createElement("input", { className: s.inputInstallmentsModal, "data-cy": "installment-modal-value", value: formatStringCurrency(value), disabled: true }))),
                React.createElement("div", { className: s.divInstallmentsInfo },
                    React.createElement("label", { className: s.installmentLabel }, t("selectInstallmentsLabel")),
                    React.createElement("div", { className: s.installmentHolder, "data-cy": "installment-modal-holder" }, installments.map((installment) => (React.createElement("div", { key: installment.numberOfInstallments, className: `${s.installmentCard} ${installment.highlight ? s.highlight : ""} ${selectedInstallment === installment.numberOfInstallments ? s.selected : ""}`, onClick: () => handleCardClick(installment.numberOfInstallments), "data-cy": "installment-card" },
                        React.createElement("div", { className: s.installmentCardHeader },
                            React.createElement("h3", { className: s.installmentCardTitle },
                                installment.numberOfInstallments,
                                "x de"),
                            React.createElement("p", { className: s.installmentCardSubtitle }, formatStringCurrency(installment.value))))))))))));
};
export default InstallmentsModalStep;
