import { __rest } from "tslib";
import React from "react";
import { Button, Icon } from "antd";
import classNames from "classnames";
import s from "./style.scss";
export const IconButton = (_a) => {
    var { children, buttonIcon, className } = _a, rest = __rest(_a, ["children", "buttonIcon", "className"]);
    return (React.createElement(Button, Object.assign({ className: classNames(s.iconButton, className) }, rest),
        React.createElement(Icon, { component: buttonIcon }),
        children));
};
