import React from "react";
import { useTranslation } from "react-i18next";
import Table from "antd/lib/table";
import styles from "./style.scss";
import { getEmployeesTableColumns } from "./column";
export const EmployeesTable = ({ handleSelectChange, selectedRequestsKeys, employees, }) => {
    const { t } = useTranslation();
    return (React.createElement(Table, { locale: { emptyText: t("emptyEmployeesText") }, className: styles.table, rowSelection: {
            selectedRowKeys: selectedRequestsKeys,
            onChange: handleSelectChange,
        }, dataSource: employees.map((employee) => (Object.assign(Object.assign({}, employee), { key: employee.id }))), columns: getEmployeesTableColumns(t), scroll: { y: 240, x: "100%" }, pagination: false }));
};
