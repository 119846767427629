exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.firedDetails___1Zjla {\n  overflow: auto;\n  height: 100%; }\n\n.cardsWrapper___1KOyL {\n  padding: 15px;\n  height: 390px;\n  overflow: auto; }\n\n.firedCard___1ZleV {\n  border-radius: 16px;\n  border: 1px solid #E8E8E8;\n  padding: 15px;\n  margin-bottom: 10px;\n  display: flex;\n  column-gap: 25px;\n  align-items: center;\n  justify-content: space-between;\n  min-width: 1150px; }\n\n.firingDetailsFooter___DTEk9 {\n  width: 100%;\n  padding: 15px;\n  display: flex;\n  justify-content: space-between; }\n", ""]);

// Exports
exports.locals = {
	"firedDetails": "firedDetails___1Zjla",
	"cardsWrapper": "cardsWrapper___1KOyL",
	"firedCard": "firedCard___1ZleV",
	"firingDetailsFooter": "firingDetailsFooter___DTEk9"
};