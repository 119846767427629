exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.field___1cgU8 {\n  margin-top: 20px;\n  width: 180px; }\n\n.forms___H7ni0 {\n  display: flex;\n  column-gap: 30px;\n  padding-right: 10px; }\n\n.modalTittleWrapper___1jzYg {\n  display: flex;\n  column-gap: 10px;\n  padding-right: 10px; }\n\n.modalTittle___2XRxK {\n  font: normal normal bold 22px/22px Montserrat;\n  color: #4d4d50; }\n\n.modalTitleDateTime___2ces8 {\n  font: normal normal 300 20px/22px Montserrat;\n  color: #4d4d50; }\n", ""]);

// Exports
exports.locals = {
	"field": "field___1cgU8",
	"forms": "forms___H7ni0",
	"modalTittleWrapper": "modalTittleWrapper___1jzYg",
	"modalTittle": "modalTittle___2XRxK",
	"modalTitleDateTime": "modalTitleDateTime___2ces8"
};