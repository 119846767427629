import { __awaiter, __rest } from "tslib";
import { saveAs } from "file-saver";
import { deburr } from "lodash";
import { toJS } from "mobx";
import XLSX from "xlsx-populate/browser/xlsx-populate";
import ExcelJs from "exceljs";
export function isNotNullish(value) {
    return value !== null && value !== undefined;
}
export function composeValidators(...validators) {
    return (value, row) => validators.every((fn) => fn(value, row));
}
const TRANSPARENT_FILL_STYLE = {
    type: "pattern",
    pattern: "none",
    fgColor: { argb: "" },
};
const INVALID_STYLE = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "ff0400" },
};
export function parseBooleanCell(value) {
    if (typeof value === "boolean") {
        return value;
    }
    else if (typeof value === "string") {
        if (["sim", "true", "verdadeiro", "x"].includes(sanitizeColumnHeader(value))) {
            return true;
        }
        else if (["nao", "false", "falso", ""].includes(sanitizeColumnHeader(value))) {
            return false;
        }
        else {
            return undefined;
        }
    }
    else {
        return undefined;
    }
}
export function cellToString(value) {
    if (typeof value === "string") {
        return value;
    }
    else if (typeof value === "number") {
        return value.toString();
    }
    else if (typeof value === "boolean") {
        return value ? "sim" : "não";
    }
    else if (value instanceof Date) {
        return value.toLocaleString();
    }
    else {
        return "";
    }
}
function parseColumns(schema) {
    return Object.entries(schema.columns);
}
function sanitizeColumnHeader(value) {
    return deburr(value).toLowerCase().trim();
}
export function generateSampleSheet(schema) {
    const cols = parseColumns(schema);
    return {
        columns: cols.map(([colId, colSchema]) => {
            return Object.assign({ id: colId }, colSchema);
        }),
        dataRows: [
            {
                cells: cols.reduce((acc, [colId, colSchema]) => {
                    acc[colId] = colSchema.sample;
                    return acc;
                }, {}),
                invalidFields: [],
            },
        ],
    };
}
function addHeaderAndSampleRows(sheet, schema) {
    const schemaColumns = Object.entries(schema.columns);
    const columns = [];
    const sampleRow = {};
    schemaColumns.forEach((column) => {
        const key = column[0];
        const values = column[1];
        columns.push({
            header: values.label,
            key,
            style: values.style,
            width: values.width,
        });
        sampleRow[key] = values.sample;
    });
    sheet.columns = [...columns];
    sheet.addRow(sampleRow);
}
function addCommentsAtHeaderCells(headerRow, columns) {
    headerRow.eachCell((cell, colNumber) => {
        const values = columns[colNumber - 1][1];
        if (values.comment) {
            cell.note = values.comment;
        }
    });
}
export function downloadSampleSheet(schema, filename) {
    const workbook = new ExcelJs.Workbook();
    const sheet = workbook.addWorksheet();
    addHeaderAndSampleRows(sheet, schema);
    const header = sheet.getRow(1);
    addCommentsAtHeaderCells(header, Object.entries(schema.columns));
    workbook.xlsx
        .writeBuffer()
        .then((buffer) => saveAs(new Blob([new Uint8Array(buffer)]), `${filename}.xlsx`));
}
export function downloadSheetAsync(sheetData, fileNameWithNoExtension) {
    return __awaiter(this, void 0, void 0, function* () {
        const { columns, dataRows } = sheetData;
        const workbook = new ExcelJs.Workbook();
        const sheet = workbook.addWorksheet();
        const headerRow = {};
        const sheetColumns = [];
        columns.forEach((column) => {
            sheetColumns.push({
                header: column.label,
                key: column.id,
                style: column.style,
                width: column.width,
            });
            headerRow[column.id] = column.label;
        });
        sheet.columns = [...sheetColumns];
        sheet.addRow(headerRow);
        addCommentsAtHeaderCells(sheet.getRow(1), Object.entries(columns));
        dataRows.forEach((row, rowNumber) => {
            // Get skip first row (sheet.row is 1-indexed)
            const sheetRow = sheet.getRow(rowNumber + 2);
            const { invalidFields } = row, rest = __rest(row, ["invalidFields"]);
            Object.entries(rest.cells).forEach((entry, colNumber) => {
                const columnName = entry[0];
                const columnValue = entry[1];
                const isInvalid = invalidFields.includes(columnName);
                const cell = sheetRow.getCell(colNumber + 1);
                cell.value = columnValue;
                cell.style.fill = isInvalid ? INVALID_STYLE : TRANSPARENT_FILL_STYLE;
            });
        });
        workbook.xlsx
            .writeBuffer()
            .then((buffer) => saveAs(new Blob([new Uint8Array(buffer)]), `${fileNameWithNoExtension}.xlsx`));
    });
}
function validateColumnsPresence(schemaColumns, headers) {
    schemaColumns.forEach((column) => {
        if (!headers.includes(column)) {
            throw new Error(`Planilha inválida. Coluna "${column}" não encontrada. verifique o modelo.`);
        }
    });
}
const validateColumnsOrder = (schemaColumns, headers) => {
    if (JSON.stringify(schemaColumns) !== JSON.stringify(headers)) {
        throw new Error("A ordem das colunas deve ser respeitada de acordo com o modelo da planilha.");
    }
};
/**
 * For the sheet it is empty it must be equal to undefined / null
 * or the rows count must be less than or equal to 1
 * @param sheet
 */
const isEmptySheet = (sheet) => {
    const HEADER_INDEX = 1;
    const firstCellValue = sheet === null || sheet === void 0 ? void 0 : sheet.row(HEADER_INDEX + 1).cell(1).value();
    return !firstCellValue;
};
export function verifyIfIsEmpty(file) {
    return __awaiter(this, void 0, void 0, function* () {
        const workbook = yield XLSX.fromDataAsync(file);
        const sheet = workbook.sheet(0);
        return isEmptySheet(sheet);
    });
}
export function uploadAsync(file, schema) {
    return __awaiter(this, void 0, void 0, function* () {
        const workbook = yield XLSX.fromDataAsync(file);
        const sheet = workbook.sheet(0);
        const range = sheet === null || sheet === void 0 ? void 0 : sheet.usedRange();
        const cells = (range && range.cells()) || [];
        const [firstRow, ...rows] = cells;
        const schemaColumns = Object.values(schema.columns).map((column) => column.label);
        const headers = firstRow.map((header) => (header.value() ? header.value().toString() : ""));
        headers.forEach((header) => {
            if (!header) {
                throw new Error("Empty header cell");
            }
        });
        validateColumnsPresence(schemaColumns, headers);
        validateColumnsOrder(schemaColumns, headers);
        const cols = parseColumns(schema);
        const columns = firstRow
            .map((x) => x.value())
            .map((headerValue) => {
            const colLabel = headerValue && headerValue.toString();
            if (!colLabel) {
                throw new Error("Empty header cell");
            }
            const col = cols.find(([_, { label, spellings }]) => [label, ...(spellings || [])]
                .map(sanitizeColumnHeader)
                .includes(sanitizeColumnHeader(colLabel)));
            if (col) {
                const [colId] = col;
                const otherProperties = col[1];
                return Object.assign(Object.assign({ id: colId }, otherProperties), { label: colLabel });
            }
        });
        const output = {
            columns: columns.filter((colum) => colum !== undefined),
            dataRows: rows.map((row) => ({
                cells: columns.reduce((acc, label, columnNumber) => {
                    if (label) {
                        const value = row[columnNumber].value();
                        const { transform } = schema.columns[label.id];
                        if (transform) {
                            const transformedValue = transform(value);
                            const isInvalidNumber = typeof transformedValue === "number" && isNaN(transformedValue);
                            const isInvalidDate = transformedValue instanceof Date && isNaN(transformedValue.getTime());
                            if (isInvalidNumber || isInvalidDate) {
                                acc[label.id] = value;
                            }
                            else {
                                acc[label.id] = transformedValue;
                            }
                        }
                    }
                    return acc;
                }, {}),
                invalidFields: [],
            })),
        };
        return output;
    });
}
const isNotEmptyRow = (row) => row && Object.values(row.cells).every((value) => !value) === false;
export function findInvalidFields(dataEntry, schema) {
    return __awaiter(this, void 0, void 0, function* () {
        const { columns, dataRows } = dataEntry;
        const notEmptyRows = dataRows.filter(isNotEmptyRow);
        const verifiedDataRows = notEmptyRows.map((row) => {
            const cols = Object.keys(row.cells);
            const areColumnsValid = cols.map((column) => schema.columns[column].validator(row.cells[column], row.cells));
            return Object.assign(Object.assign({}, row), { invalidFields: areColumnsValid
                    .map((isValid, colNumber) => (!isValid && cols[colNumber]) || undefined)
                    .filter(isNotNullish) });
        });
        const parsedRows = verifiedDataRows
            .filter((x) => x.invalidFields.length === 0)
            .map((x) => x.cells);
        const verifiedData = {
            columns,
            dataRows: verifiedDataRows,
            parsedRows,
        };
        return verifiedData;
    });
}
export function isSheetDataValid(data) {
    return toJS(data.dataRows).every((r) => r.invalidFields.length === 0);
}
