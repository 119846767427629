import React from "react";
import { Col } from "antd";
import s from "./style.scss";
import { Communication } from "../../../shared/components/Icons/Communication";
import { Email } from "../../../shared/components/Icons/Email";
import { Site } from "../../../shared/components/Icons/Site";
import { Twitter } from "../../../shared/components/Icons/Twitter";
import { Instagram } from "../../../shared/components/Icons/Instagram";
import { Facebook } from "../../../shared/components/Icons/Facebook";
import { Linkedin } from "../../../shared/components/Icons/Linkedin";
function getIcon(iconName) {
    switch (iconName) {
        case "suporte":
        default:
            return React.createElement(Communication, null);
        case "email":
            return React.createElement(Email, null);
        case "site":
            return React.createElement(Site, null);
        case "twitter":
            return React.createElement(Twitter, null);
        case "instagram":
            return React.createElement(Instagram, null);
        case "facebook":
            return React.createElement(Facebook, null);
        case "linkedin":
            return React.createElement(Linkedin, null);
    }
}
export const ContactUsCard = ({ icon, title, link, textLink }) => {
    return (React.createElement(Col, { className: s.container, span: 6 },
        React.createElement("a", { className: s.card, href: `${link}`, target: "blank" },
            React.createElement("div", { className: s.icon }, getIcon(icon)),
            React.createElement("h2", { className: s.title }, title),
            React.createElement("div", { className: s.link }, textLink))));
};
