import pipe from "ramda/es/pipe";
import replace from "ramda/es/replace";
import slice from "ramda/es/slice";
const brazilianDDI = "55";
const prefix = (str) => (value) => str + value;
const maskReducer = (skel) => (acc, digit, index, array) => {
    const { length } = array;
    if (index === 0 && skel[-1]) {
        return skel[-1] + digit;
    }
    if (index < length - 1 && skel[index]) {
        return acc + digit + skel[index];
    }
    return acc + digit;
};
const numberify = pipe(String, replace(/\D/g, ""));
const limit = (length) => slice(0, length);
const skelify = (skel) => (value) => value.split("").reduce(maskReducer(skel), "");
export const formatNumber = (decimals) => (value) => {
    const valueParsedToNumber = Number(value);
    const float = value ? valueParsedToNumber / Math.pow(10, decimals) : 0;
    return new Intl.NumberFormat("pt-BR", {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(float);
};
export const formatCpf = pipe(numberify, limit(11), skelify({
    2: ".",
    5: ".",
    8: "-",
}));
export const formatCnpj = pipe(numberify, limit(14), skelify({
    1: ".",
    4: ".",
    7: "/",
    11: "-",
}));
export const formatCurrency = (value) => formatStringCurrency(typeof value === "string" ? value : value.toFixed(2));
export const formatCurrencyNullIF = (value) => value ? formatCurrency(value) : "--";
export const formatStringCurrency = pipe(numberify, limit(16), formatNumber(2), prefix("R$ "));
export const formatToPercentageNullIf = (value) => value ? `${value}%` : "--";
export const formatCurrencyNoPrefix = (value) => formatStringCurrencyNoPrefix(typeof value === "string" ? value : value.toFixed(2));
export const formatCurrencyNoPrefixFiveDecimals = (value) => formatStringCurrencyNoPrefixFiveDecimals(typeof value === "string" ? value : value.toFixed(5));
export const formatStringCurrencyNoPrefix = pipe(numberify, limit(16), formatNumber(2));
export const formatStringCurrencyNoPrefixFiveDecimals = pipe(numberify, limit(16), formatNumber(5));
export const convertUndefinedPropertiesToNull = (values) => {
    const keys = Object.keys(values);
    const newValues = values;
    keys.forEach((key) => {
        if (values[key] === undefined) {
            newValues[key] = null;
        }
    });
    return newValues;
};
export const zeroFill = (width, value) => {
    const length = value.toString().length;
    if (width > 0) {
        const pad = width - length;
        if (pad > 0) {
            let count = 0;
            while (count < pad) {
                value = "0" + value;
                count++;
            }
        }
    }
    return value;
};
export const toInt = (value) => {
    const isString = typeof value === "string";
    if (value === "") {
        return null;
    }
    return value && isString ? Number(value.replace(/\D/g, "")) : value;
};
export const clearSymbols = (value) => {
    if (!value) {
        return "";
    }
    return value.replace(/\D/g, "");
};
export const parseBigIntToString = (value) => {
    if (value === 0 || !value) {
        return "";
    }
    return String(value);
};
export const formatBankAccountNumber = (value) => {
    var _a;
    const formatted = (_a = value.replace("-", "")) === null || _a === void 0 ? void 0 : _a.toUpperCase();
    const len = formatted.length;
    return len > 1 ? `${formatted.substring(0, len - 1)}-${formatted.substring(len - 1)}` : value;
};
export const formatBankAccountType = (value) => {
    const accountTypeDictionary = {
        checking: "Conta Corrente",
        saving: "Conta Poupança",
    };
    return accountTypeDictionary[value || "checking"];
};
export const splitHundered = (value) => {
    return toInt(value) / 100;
};
export function removeSpecialCharacters(word) {
    return word.replace(/[^a-z0-9]/gi, "");
}
export function cut(word, maxLength = 30, sufix = "...") {
    if (word.length <= maxLength) {
        return word;
    }
    return `${word.substr(0, maxLength)}${sufix}`;
}
export function formatAddress(address) {
    return `${address === null || address === void 0 ? void 0 : address.street}, ${address === null || address === void 0 ? void 0 : address.number}, ${address === null || address === void 0 ? void 0 : address.complement}, ${address === null || address === void 0 ? void 0 : address.cep} - ${address === null || address === void 0 ? void 0 : address.city}/${address === null || address === void 0 ? void 0 : address.state}`;
}
export function revertCurrencyFormat(value) {
    if (!value) {
        return 0;
    }
    const valueWithouPrefix = value.replace("R$", "");
    const valueNumberFormat = valueWithouPrefix.replace(",", ".");
    return parseFloat(valueNumberFormat.trim());
}
export function formatBoolean(isTrue) {
    if (isTrue) {
        return "Sim";
    }
    return "Não";
}
export function parseToString(value) {
    return (value === null || value === void 0 ? void 0 : value.toString()) || "";
}
export function formatCurrentInstallment(currentInstallment, totalInstallments) {
    return `${zeroFill(2, currentInstallment)}/${zeroFill(2, totalInstallments)}`;
}
export function formatPhoneNumberWithDDI(phone) {
    const newPhone = clearSymbols(phone);
    if (!newPhone) {
        return null;
    }
    return newPhone.replace(/^(\d{0,2})(\d{0,2})(\d{0,5})(\d{0,4})/u, "+$1 ($2) $3-$4");
}
export function formatPhoneNumberWithoutDDI(phone) {
    const newPhone = clearSymbols(phone);
    if (!newPhone) {
        return null;
    }
    return newPhone.replace(/^(\d{0,2})(\d{0,5})(\d{0,4})/u, "($1) $2-$3");
}
export function addPhoneNumberDDI(phone) {
    const phoneWhitoutSymbols = clearSymbols(phone);
    if (!phoneWhitoutSymbols) {
        return "";
    }
    if (phoneWhitoutSymbols.slice(0, 2) === brazilianDDI && phoneWhitoutSymbols.length > 11) {
        return phoneWhitoutSymbols;
    }
    return `${brazilianDDI}${phoneWhitoutSymbols}`;
}
export const formatCEP = (value) => value.replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
