import React from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { Select } from "../../components/Select";
import s from "./style.scss";
const ActionSelect = observer(({ actions, selectedKeys, currentPage }) => {
    const { t, i18n } = useTranslation();
    const [selectedAction, setSelectedAction] = useState(null);
    const handleActionChange = (value) => {
        const action = actions.find((action) => action.value === value);
        if (action) {
            setSelectedAction(action);
        }
    };
    return (React.createElement("div", { className: s.actionSelectContainer },
        React.createElement(Select, { placeholder: t("selectAnActionLabel"), onChange: handleActionChange, options: actions.map((option) => ({
                value: option.value,
                label: t(option.label),
            })), "data-cy": "select-field-action-select" }),
        React.createElement(Button, { type: "primary", disabled: selectedAction === null, size: "large", onClick: selectedAction ? () => selectedAction.onClick(selectedKeys, currentPage) : undefined, "data-cy": "action-button-action-select" }, i18n.language === "br"
            ? selectedAction
                ? `${t(selectedAction.label)} ${t("toSelectedLabel")}`
                : t("noActionSelectedLabel")
            : selectedAction
                ? `${t(selectedAction.label)}`
                : t("noActionSelectedLabel"))));
});
export default ActionSelect;
