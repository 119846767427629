import { __awaiter } from "tslib";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "antd";
import useReactRouter from "use-react-router";
import { useTranslation } from "react-i18next";
import styles from "./style.scss";
import { useStores } from "../../../company/utils/mobx";
import { formatFullDateTime } from "../../..//shared/utils/date";
import { FiredEmployeesDetails } from "../FiredEmployeesDetails";
const FiredDetailsMod = ({ changeId, visible, onCancel, firedDateTime, }) => {
    const { t } = useTranslation();
    const { match } = useReactRouter();
    const [employeesDetails, setEmployeesDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const { generalStore: { setErrorMessage, catchErrors }, firedStored, } = useStores();
    const fetchEmployeesDetails = () => __awaiter(void 0, void 0, void 0, function* () {
        setEmployeesDetails([]);
        setLoading(true);
        try {
            if (changeId) {
                const employees = yield firedStored.getFiredEmployeesDetails(match.params.companyId, changeId);
                setEmployeesDetails(employees);
            }
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("fired.firedDetailsError"));
        }
        finally {
            setLoading(false);
        }
    });
    useEffect(() => {
        fetchEmployeesDetails();
    }, [changeId, match.params.companyId]);
    return (React.createElement(Modal, { visible: visible, title: React.createElement("div", { className: styles.modalTittleWrapper },
            React.createElement("p", { className: styles.modalTitle },
                " ",
                t("fired.detailsModalTitle")),
            React.createElement("p", { className: styles.modalTitleDateTime }, formatFullDateTime(firedDateTime))), onCancel: onCancel, okButtonProps: { style: { display: "none" } }, cancelButtonProps: { style: { display: "none" } }, destroyOnClose: true, maskClosable: true, width: "65%" },
        React.createElement(FiredEmployeesDetails, { loading: loading, employeesDetails: employeesDetails })));
};
export const FiredDetailsModal = Form.create({ name: "fired_form" })(FiredDetailsMod);
