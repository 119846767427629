import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { DescriptionButton } from "../DescriptionButton";
import { useStores } from "../../utils/mobx";
import { cut, formatCnpj } from "../../../shared/utils/formatters";
import { getFirstCompanyId } from "../../utils/user.utils";
import styles from "./style.scss";
const SelectCompany = observer(({ onCompanyClick }) => {
    var _a;
    const { authStore: { user }, generalStore: { isMultiCompany }, } = useStores();
    useEffect(() => {
        if (!isMultiCompany) {
            onCompanyClick(getFirstCompanyId(user));
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { "data-cy": "select-company", className: styles.companiesWrapper }, (_a = user === null || user === void 0 ? void 0 : user.companies) === null || _a === void 0 ? void 0 : _a.map((company) => (React.createElement(DescriptionButton, { onClick: () => {
                onCompanyClick(company.id);
            }, key: company.id, title: cut(company.name, 24), description: formatCnpj(company.cnpj) }))))));
});
export default SelectCompany;
