exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.employeeInfo___2RiwQ {\n  width: 280px; }\n  .employeeInfo___2RiwQ .title___27FNK {\n    color: #FD7622;\n    font-size: 16px;\n    font-weight: bold;\n    display: -webkit-box;\n    -webkit-line-clamp: 1;\n    -webkit-box-orient: vertical;\n    overflow: hidden; }\n  .employeeInfo___2RiwQ .subtitle___2Vipc {\n    color: #4D4D50;\n    font-size: 15px; }\n  .employeeInfo___2RiwQ .contractedLoansCard___1bAdZ {\n    background-color: #F76174;\n    color: #ffffff;\n    font-weight: bold;\n    font-size: 13px;\n    padding: 5px;\n    border-radius: 5px;\n    width: 220px;\n    height: 35px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: 10px; }\n", ""]);

// Exports
exports.locals = {
	"employeeInfo": "employeeInfo___2RiwQ",
	"title": "title___27FNK",
	"subtitle": "subtitle___2Vipc",
	"contractedLoansCard": "contractedLoansCard___1bAdZ"
};