import React from "react";
import TruncateString from "react-truncate-string";
import { DownloadIcon } from "../../../../shared/components/Icons/Download";
import { SemanticButton } from "../../../../shared/components/SemanticButton";
import styles from "./style.scss";
export const UpdatesFile = ({ file, onClickDownloadPayroll }) => {
    return (React.createElement("div", { className: styles.content },
        React.createElement(TruncateString, { className: styles.title, text: file.filename, truncateAt: 63 }),
        React.createElement("div", null,
            React.createElement(SemanticButton, { onClick: () => onClickDownloadPayroll(file.id, file.filename) },
                React.createElement(DownloadIcon, null)))));
};
