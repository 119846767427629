import React, { useState, useEffect } from "react";
import { Select } from "antd";
import DropdownItem from "./DropdownItem";
import s from "./style.scss";
import { useStores } from "../../utils/mobx";
const { Option } = Select;
export const DropdownCompanies = ({ associateds, companyId }) => {
    const [associatedsCompanies, setAssociateds] = useState([]);
    const { generalStore } = useStores();
    useEffect(() => {
        associateds && setAssociateds(associateds);
    }, []);
    return (React.createElement("div", { id: "SelectCompany", className: s.companySelected }, associatedsCompanies.length &&
        (associatedsCompanies.length > 1 ? (React.createElement(Select, { style: { width: 270 }, className: s.selectCompany, onChange: companyId, defaultValue: generalStore.getSelectedCompany(), dropdownClassName: s.dropdownOptions, dropdownRender: (menu) => React.createElement("div", { className: s.menuRender }, menu) }, associatedsCompanies.map((company) => (React.createElement(Option, { key: company.id, className: s.option, value: company.id },
            React.createElement(DropdownItem, { companyName: company.name, cnpj: company.cnpj })))))) : (React.createElement(DropdownItem, { companyName: associatedsCompanies[0].name, cnpj: associatedsCompanies[0].cnpj })))));
};
