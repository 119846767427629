exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".container___W4xxA {\n  max-width: 310px; }\n\n.card___q3j_j {\n  padding: 20px;\n  border: 2px solid #f0f0f0;\n  border-radius: 8px;\n  display: block;\n  color: #8f8f8f;\n  text-decoration: none;\n  margin-bottom: 15px;\n  transition: all 0.3s ease;\n  display: flex;\n  flex-direction: column;\n  min-height: 190px; }\n  .card___q3j_j:hover {\n    box-shadow: 0 0 5px rgba(0, 0, 0, 0.08); }\n    .card___q3j_j:hover .title___3psqu {\n      color: #ff7600; }\n    .card___q3j_j:hover .icon___3ksQD svg {\n      fill: #ff7600; }\n\n.icon___3ksQD {\n  flex: 0.2;\n  text-align: center; }\n  .icon___3ksQD svg {\n    width: 45px;\n    height: auto;\n    transition: all 0.3s ease;\n    fill: #8f8f8f; }\n\n.title___3psqu {\n  font-size: 24px;\n  font-weight: bold;\n  margin-top: 15px;\n  color: #8f8f8f;\n  transition: all 0.5s ease;\n  text-align: center; }\n\n.text___1rGgI {\n  font-size: 15px;\n  color: #8f8f8f;\n  transition: all 0.5s ease;\n  flex: 1; }\n\n.link___3eTPL {\n  width: 100%;\n  padding: 5px;\n  border-radius: 5px;\n  background-color: #faf9f9;\n  color: #ff7600;\n  font-size: 14px;\n  text-align: center; }\n", ""]);

// Exports
exports.locals = {
	"container": "container___W4xxA",
	"card": "card___q3j_j",
	"title": "title___3psqu",
	"icon": "icon___3ksQD",
	"text": "text___1rGgI",
	"link": "link___3eTPL"
};