exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".itemInput___-FBty > div {\n  margin: 0px 8px 0px 4px;\n  max-width: 200px; }\n\n.itemSelect___3GWcb > div {\n  margin: 0px 8px 0px 4px;\n  min-width: 160px; }\n\n.itemInput___-FBty [class~=\"has-success\"],\n.itemSelect___3GWcb [class~=\"has-success\"] {\n  background-color: white; }\n\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus {\n  -webkit-box-shadow: 0 0 0px 1000px white inset; }\n\n.date___2-y23 {\n  margin: 0px;\n  width: 120px;\n  min-width: 120px !important; }\n\n.optionDescription___2xBjb {\n  font-size: 11px;\n  color: #ff7600; }\n", ""]);

// Exports
exports.locals = {
	"itemInput": "itemInput___-FBty",
	"itemSelect": "itemSelect___3GWcb",
	"date": "date___2-y23",
	"optionDescription": "optionDescription___2xBjb"
};