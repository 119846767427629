import { __awaiter } from "tslib";
import React from "react";
import { observer } from "mobx-react-lite";
import { EmailConfirmForm } from "../../../../shared/components/EmailConfirmForm";
import { useStores } from "../../../utils/mobx";
import { useTranslation } from "react-i18next";
export const RecoveryPassword = observer(() => {
    const { authStore } = useStores();
    const [loading, setLoading] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const { t } = useTranslation();
    const handleSubmitForgotPassword = (email) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        setSuccessMessage("");
        try {
            yield authStore.requestPasswordChange(email);
            setSuccessMessage(t("authentication.passwordRecoveryLinkMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(EmailConfirmForm, { loading: loading, successMessage: successMessage, title: t("authentication.insertEmailText"), handleSubmit: handleSubmitForgotPassword })));
});
