import React from "react";
import { DatePicker } from "antd";
import { inssRemovalRequiredFieldsIsFilled, } from "../../../containers/PayrollConflict/types";
import { updateConflictValue } from "../../../utils/payrollConflict.utils";
import styles from "./style.scss";
const InssRemovalForm = ({ inssRemovalsInfo, setInssRemovalsInfo, userId, fieldPlaceholder, }) => {
    const dateIsFilled = () => {
        const firingInfo = inssRemovalsInfo.find((inssRemovalInfo) => inssRemovalInfo.userId === userId);
        return inssRemovalRequiredFieldsIsFilled(firingInfo);
    };
    const handleDateChange = (date) => {
        setInssRemovalsInfo((oldValue) => updateConflictValue({
            field: "date",
            conflictsValue: oldValue,
            userId,
            value: date,
        }));
    };
    return (React.createElement("div", { className: dateIsFilled() ? styles.dateFilledWrapper : styles.dateWrapper },
        React.createElement("div", null,
            React.createElement(DatePicker, { onChange: (date) => {
                    handleDateChange(date);
                }, placeholder: fieldPlaceholder, format: "DD/MM/YYYY" }))));
};
export default InssRemovalForm;
