import { __awaiter } from "tslib";
import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "antd";
import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { defaultConfirmProps } from "../../../shared/utils";
import { useStores } from "../../utils/mobx";
import { SectionHeader } from "../../components/SectionHeader";
import { SectionContainer } from "../../../shared/components/SectionContainer";
import { SubSectionHeader } from "../../../shared/components/SubSectionHeader";
import { SectionFooter } from "../../../shared/components/SectionFooter";
import { ButtonGroup } from "../../../shared/components/ButtonGroup";
import ActionTable from "../../../shared/components/ActionTable";
import { ConflictStatusComponent } from "../../components/ConflictStatus";
import { ChangeDisplay } from "../../components/ChangeDisplay";
import MostRecentUpdates from "./MostRecentUpdates";
import { formatCnpj } from "../../../shared/utils/formatters";
import { getConflictsFiredUsersWithActiveLoan, getConflictsInssRemovedUsersWithActiveLoan, mapFiringsInfo, mapInssRemovalInfo, orderConflictsByChange, requiredInssChanges, } from "../../utils/payrollConflict.utils";
import { firingRequiredFieldsIsFilled, inssRemovalRequiredFieldsIsFilled, } from "./types";
import FiringConflictForm from "../../components/PayrollConflict/FiringConflictForm";
import InssRemovalForm from "../../components/PayrollConflict/InssRemovalForm";
import styles from "./style.scss";
const confirm = Modal.confirm;
const emptyFiringInfo = {
    rescissionPaymentDate: null,
    rescissionValue: 0,
    resignationDate: null,
    userId: "",
    discountedValueOverride: null,
};
function mapConflictsToFiringInfo(conflicts) {
    return conflicts.map((conflict) => (Object.assign(Object.assign({}, emptyFiringInfo), { userId: conflict.userId })));
}
function mapConflictsToInssRemovalInfo(conflicts) {
    return conflicts.map((conflict) => ({
        date: null,
        userId: conflict.userId,
    }));
}
const PayrollConflict = observer(({ match }) => {
    const { t } = useTranslation();
    const [saved, setSaved] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [firingsInfo, setFiringsInfo] = useState([]);
    const [inssRemovalsInfo, setInssRemovalsInfo] = useState([]);
    React.useEffect(() => {
        function listener(e) {
            if (!saved) {
                e.preventDefault();
                return "";
            }
        }
        window.addEventListener("beforeunload", listener);
        return () => {
            window.removeEventListener("beforeunload", listener);
        };
    }, [saved]);
    const confirmMessage = (conflicts) => `${t("hasLabel")} ${conflicts} ${conflicts > 1 ? t("changesPluralLabel") : t("changesMinorLabel")}`;
    const columns = [
        {
            title: t("nameLabel"),
            dataIndex: "userName",
            key: "userName",
            width: 350,
        },
        {
            title: t("changesLabel"),
            dataIndex: "changeStatus",
            key: "changeStatus",
        },
        {
            title: "",
            dataIndex: "change",
            key: "change",
            width: 550,
        },
    ];
    const { generalStore: { setErrorMessage, setSuccessMessage, clearMessage, catchErrors }, payrollImportStore: { lastPayroll, currentPayrollSubmission, hasConflicts, confirmsPayrollSubmission, }, authStore: { user }, routerStore, } = useStores();
    const orderedConflicts = useMemo(() => {
        if (!(currentPayrollSubmission === null || currentPayrollSubmission === void 0 ? void 0 : currentPayrollSubmission.conflicts)) {
            return [];
        }
        const { conflicts } = currentPayrollSubmission;
        return orderConflictsByChange(conflicts);
    }, [currentPayrollSubmission === null || currentPayrollSubmission === void 0 ? void 0 : currentPayrollSubmission.conflicts]);
    const companyId = match.params.companyId;
    const company = React.useMemo(() => {
        var _a;
        return (_a = user === null || user === void 0 ? void 0 : user.companies) === null || _a === void 0 ? void 0 : _a.filter((c) => c.id === companyId)[0];
    }, [companyId]);
    useEffect(() => {
        redirectToImportWithoutConflicts();
    }, []);
    useEffect(() => {
        setFiringsInfo(mapConflictsToFiringInfo(getConflictsFiredUsersWithActiveLoan(orderedConflicts)));
        setInssRemovalsInfo(mapConflictsToInssRemovalInfo(getConflictsInssRemovedUsersWithActiveLoan(orderedConflicts)));
    }, [orderedConflicts]);
    const redirectToImportWithoutConflicts = () => {
        if (!hasConflicts) {
            routerStore.push(`/importar-folha/${companyId}`);
        }
    };
    const handleOpenConfirm = () => {
        if (currentPayrollSubmission) {
            const { payroll, conflicts } = currentPayrollSubmission;
            confirm(Object.assign(Object.assign({}, defaultConfirmProps), { title: t("confirmReportSending"), content: (React.createElement("div", null,
                    t("theDocumentLabel"),
                    " ",
                    payroll.filename,
                    React.createElement("br", null),
                    confirmMessage(conflicts.length),
                    React.createElement("br", null),
                    " ",
                    t("confirmSendingDocumentText"))), onOk: confirmSubmission }));
        }
    };
    const confirmSubmission = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            clearMessage();
            setLoading(true);
            if (currentPayrollSubmission) {
                yield confirmsPayrollSubmission(companyId, currentPayrollSubmission.payroll.id, mapFiringsInfo(firingsInfo), mapInssRemovalInfo(inssRemovalsInfo));
                setSaved(true);
                routerStore.push(`/importar-folha/${companyId}`);
                setSuccessMessage(t("successfulImportMessage"));
            }
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("unexpectedErrorMessage"));
        }
        finally {
            setLoading(false);
        }
    });
    const checkConflictUpdates = (conflict) => {
        if (conflict.salaryChange !== null) {
            return React.createElement(ChangeDisplay, { upgrade: conflict.salaryChange });
        }
        if (conflict.userNewName !== null) {
            const upgrade = {
                from: conflict.userName,
                to: conflict.userNewName,
            };
            return React.createElement(ChangeDisplay, { upgrade: upgrade });
        }
        if (conflict.change === "fired" && conflict.hasActiveLoan) {
            return (React.createElement(FiringConflictForm, { key: conflict.userId, firingsInfo: firingsInfo, setFiringsInfo: setFiringsInfo, userId: conflict.userId }));
        }
        if (requiredInssChanges.includes(conflict.change) && conflict.hasActiveLoan) {
            const inssChangeName = conflict.change === "InssRemoval" ? t("inssRemoval.startDate") : t("inssRemoval.endDate");
            return (React.createElement(InssRemovalForm, { key: conflict.userId, inssRemovalsInfo: inssRemovalsInfo, setInssRemovalsInfo: setInssRemovalsInfo, userId: conflict.userId, fieldPlaceholder: inssChangeName }));
        }
    };
    const shouldSubmit = firingsInfo.every((firingInfo) => firingRequiredFieldsIsFilled(firingInfo)) &&
        inssRemovalsInfo.every((inssRemovalInfo) => inssRemovalRequiredFieldsIsFilled(inssRemovalInfo));
    const getRowClassByConflict = (conflict) => conflict.conflictChange === "fired" && conflict.hasActiveLoan
        ? String(styles.firedRowWrapper)
        : "";
    return currentPayrollSubmission ? (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("payrollImportTitle"), info: `<b>${company === null || company === void 0 ? void 0 : company.name}</b> <span>CNPJ: ${formatCnpj(company === null || company === void 0 ? void 0 : company.cnpj)}</span>`, description: React.createElement(React.Fragment, null,
                React.createElement("p", null, t("headerDescription")),
                React.createElement("p", null, t("headerDescriptionCheckChangeText")),
                React.createElement("p", null, t("headerDescriptionWrongDataText"))) }),
        React.createElement(SectionContainer, { className: styles.container },
            React.createElement(SubSectionHeader, { title: t("changesCapitalLabel") }),
            lastPayroll && (React.createElement(MostRecentUpdates, { lastPayroll: lastPayroll, currentPayroll: currentPayrollSubmission.payroll })),
            React.createElement(ActionTable, { className: classNames(styles.whiteTable, styles.table), columns: columns, loading: loading, dataSource: orderedConflicts.map((conflict, index) => (Object.assign(Object.assign({}, conflict), { key: `conf-${index}`, userName: conflict.userNewName !== null ? conflict.userNewName : conflict.userName, changeStatus: React.createElement(ConflictStatusComponent, { status: conflict.change }), change: checkConflictUpdates(conflict), conflictChange: conflict.change }))), rowClassName: getRowClassByConflict, scroll: { y: 400 } })),
        React.createElement("div", { className: styles.containerSectionFooter },
            React.createElement("div", { className: "ant-col ant-col-23" },
                React.createElement(SectionFooter, null,
                    React.createElement(ButtonGroup, { className: styles.buttons },
                        React.createElement(Button, null,
                            React.createElement(NavLink, { to: "/importar-folha" }, t("cancelLabel"))),
                        React.createElement(Button, { className: styles.confirmButton, type: "primary", onClick: handleOpenConfirm, disabled: !shouldSubmit, "data-cy": "confirm-button-wrapper" }, t("confirmLabel")))))))) : null;
});
export default PayrollConflict;
