exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../../shared/assets/404_background.png"));

// Module
exports.push([module.id, "/* Colors */\n/* Shadow */\n/* Sizes */\n/* Screen sizes */\n/* Mixins */\n.container___24fZG {\n  background: url(" + ___CSS_LOADER_URL___0___ + ") no-repeat center center fixed;\n  background-size: cover;\n  display: flex;\n  flex-direction: row;\n  min-height: 100vh;\n  width: 100%;\n  position: fixed;\n  top: 64px;\n  left: 0; }\n\n.blurBackground___kTBLp {\n  position: absolute;\n  width: 27.5rem;\n  height: 31.3rem;\n  filter: blur(5px);\n  background: #176869d1;\n  margin: auto 15rem auto auto;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0; }\n\n.box___3PLVW {\n  display: flex;\n  flex-flow: column;\n  align-items: flex-end;\n  margin: auto 19rem auto auto;\n  position: relative; }\n\n.title___3eAIp {\n  font-size: 110px;\n  font-weight: bold;\n  line-height: normal;\n  color: #ffffff;\n  width: 100%;\n  text-align: end;\n  margin: 1.8rem 0 1.8rem; }\n\n.loginOn___2L2_k,\n.completeActionMessage___hCX7X {\n  color: #ffffff; }\n\n.loginOn___2L2_k {\n  font-size: 18px;\n  font-weight: bold;\n  margin-top: 50px; }\n\n.completeActionMessage___hCX7X {\n  width: 300px;\n  text-align: right;\n  font-size: 16px;\n  margin-bottom: 0; }\n\n.message___3Mxfl {\n  font-size: 20px;\n  font-weight: bold;\n  font-stretch: normal;\n  line-height: 1.33;\n  text-align: end;\n  color: #ffffff;\n  width: 100%; }\n\n.button___3LDEE {\n  border: #01929f;\n  background-color: #01929f;\n  color: #ffffff;\n  border-radius: 0.2rem;\n  margin: 2.5rem 0;\n  cursor: pointer;\n  font-size: 14px; }\n\n.button___3LDEE:hover {\n  border: #FD7622;\n  background-color: #FD7622; }\n\n@media (max-width: 1100px) {\n  .box___3PLVW {\n    margin: auto; }\n  .container___24fZG {\n    justify-content: center; }\n  .blurBackground___kTBLp {\n    margin: 2rem auto; } }\n", ""]);

// Exports
exports.locals = {
	"container": "container___24fZG",
	"blurBackground": "blurBackground___kTBLp",
	"box": "box___3PLVW",
	"title": "title___3eAIp",
	"loginOn": "loginOn___2L2_k",
	"completeActionMessage": "completeActionMessage___hCX7X",
	"message": "message___3Mxfl",
	"button": "button___3LDEE"
};