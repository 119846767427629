export const getProposalStatusLabelsOptions = (t) => {
    return [
        {
            value: "created",
            label: t("proposals.created"),
        },
        {
            value: "pending_signature",
            label: t("proposals.pending_signature"),
        },
        {
            value: "signed",
            label: t("proposals.signed"),
        },
        {
            value: "refused",
            label: t("proposals.refused"),
        },
        {
            value: "canceled_corban",
            label: t("proposals.canceled"),
        },
        {
            value: "",
            label: "Status Proposta",
        },
    ];
};
