import { __awaiter, __decorate } from "tslib";
import { computed, action, observable } from "mobx";
import { api } from "../index";
export class AuthStore {
    constructor(rootStore) {
        this.selectedCompany = "";
        this.updateUserFromLocalStorage = () => {
            const user = localStorage.getItem("user")
                ? JSON.parse(localStorage.getItem("user"))
                : null;
            this.user = user;
        };
        this.setUser = (user) => {
            var _a, _b, _c, _d, _e, _f, _g;
            if (!user) {
                throw new Error("Usuário não encontrado");
            }
            this.user = user;
            localStorage.setItem("user", JSON.stringify(this.user));
            let selectedCompany = localStorage.getItem("selectedCompanyId") || "";
            const selectedCompanyIncludesInAssociatedCompanies = (_b = (_a = this.user) === null || _a === void 0 ? void 0 : _a.companies) === null || _b === void 0 ? void 0 : _b.filter((company) => company.id === selectedCompany).length;
            if (!selectedCompany || !selectedCompanyIncludesInAssociatedCompanies) {
                localStorage.setItem("selectedCompanyId", ((_d = (_c = this.user) === null || _c === void 0 ? void 0 : _c.companies) === null || _d === void 0 ? void 0 : _d[0].id) || "");
                ((_e = this.user) === null || _e === void 0 ? void 0 : _e.companies) &&
                    api
                        .updateSelectedCompany({
                        companyId: this.user.companies[0].id,
                    })
                        .then();
                this.rootStore.generalStore.selectedCompanyId = ((_g = (_f = this.user) === null || _f === void 0 ? void 0 : _f.companies) === null || _g === void 0 ? void 0 : _g[0].id) || "";
            }
        };
        this.updateUserFromApi = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const userFetched = yield api.getCurrentUser();
                this.setUser(userFetched);
            }
            catch (err) {
                throw err;
            }
        });
        this.login = (email, password) => __awaiter(this, void 0, void 0, function* () {
            try {
                const userFetched = yield api.login({ email, password });
                this.setUser(userFetched);
                this.rootStore.routerStore.push("/");
            }
            catch (err) {
                throw err;
            }
        });
        this.logout = () => __awaiter(this, void 0, void 0, function* () {
            yield api.logout();
            this.localLogout();
        });
        this.changePassword = (currentPassword, newPassword, passwordConfirm) => __awaiter(this, void 0, void 0, function* () {
            try {
                yield api.changePassword({
                    currentPassword,
                    newPassword,
                    confirmNewPassword: passwordConfirm,
                });
                this.rootStore.routerStore.push("/");
            }
            catch (err) {
                throw err;
            }
        });
        this.localLogout = () => {
            localStorage.removeItem("user");
            localStorage.removeItem("selectedCompanyId");
            this.user = null;
            this.selectedCompany = null;
            this.rootStore.routerStore.push("/login");
        };
        this.registerNewPassword = (requestId, password, confirmPassword) => __awaiter(this, void 0, void 0, function* () {
            try {
                yield api.registerNewPassword({ requestId, password, confirmPassword });
                this.rootStore.routerStore.push("/login");
            }
            catch (err) {
                throw err;
            }
        });
        this.emailFromRequestId = (requestId) => __awaiter(this, void 0, void 0, function* () {
            try {
                return yield api.getEmailFromRequest({ requestId });
            }
            catch (err) {
                throw err;
            }
        });
        this.requestPasswordChange = (email) => __awaiter(this, void 0, void 0, function* () {
            try {
                yield api.requestPasswordChange({ email });
            }
            catch (err) {
                throw err;
            }
        });
        this.rootStore = rootStore;
        this.updateUserFromLocalStorage();
    }
    get isLoggedIn() {
        return !!this.user;
    }
}
__decorate([
    observable
], AuthStore.prototype, "user", void 0);
__decorate([
    observable
], AuthStore.prototype, "selectedCompany", void 0);
__decorate([
    computed
], AuthStore.prototype, "isLoggedIn", null);
__decorate([
    action
], AuthStore.prototype, "updateUserFromLocalStorage", void 0);
__decorate([
    action
], AuthStore.prototype, "updateUserFromApi", void 0);
__decorate([
    action
], AuthStore.prototype, "login", void 0);
__decorate([
    action
], AuthStore.prototype, "logout", void 0);
__decorate([
    action
], AuthStore.prototype, "changePassword", void 0);
__decorate([
    action
], AuthStore.prototype, "localLogout", void 0);
__decorate([
    action
], AuthStore.prototype, "registerNewPassword", void 0);
__decorate([
    action
], AuthStore.prototype, "emailFromRequestId", void 0);
export default AuthStore;
