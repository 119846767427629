import { __awaiter } from "tslib";
import React from "react";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import useReactRouter from "use-react-router";
import { PasswordConfirmForm } from "../../../../shared/components/PasswordConfirmForm";
import { useStores } from "../../../utils/mobx";
export const Register = observer(() => {
    const { authStore } = useStores();
    const [email, setEmail] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const { match } = useReactRouter();
    const emailFromRequest = () => __awaiter(void 0, void 0, void 0, function* () {
        const requestId = match.params.id;
        setErrorMessage("");
        try {
            const emailFromId = yield authStore.emailFromRequestId(requestId);
            setEmail(emailFromId);
        }
        catch (e) {
            const error = e;
            setErrorMessage(error.message);
        }
        finally {
            setLoading(false);
        }
    });
    useEffect(() => {
        emailFromRequest();
    }, []);
    const register = (password, passwordConfirm) => __awaiter(void 0, void 0, void 0, function* () {
        const id = match.params.id;
        setErrorMessage("");
        setLoading(true);
        try {
            yield authStore.registerNewPassword(id, password, passwordConfirm);
        }
        catch (e) {
            const error = e;
            setErrorMessage(error.message);
        }
        finally {
            setLoading(false);
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(PasswordConfirmForm, { email: email, loading: loading, handleSubmit: register, error: errorMessage })));
});
