import React from "react";
import { useTranslation } from "react-i18next";
import { RightArrow } from "../../../../shared/components/Icons/RightArrow";
import { formatFullDateTime } from "../../../../shared/utils/date";
import s from "./style.scss";
import { PayrollSummary } from "../../../components/PayrollSummary";
const MostRecentUpdates = ({ lastPayroll, currentPayroll }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: s.mostRecentUpdates },
        React.createElement(PayrollSummary, { payroll: lastPayroll, title: `${t("lastUpdateLabel")} - ${formatFullDateTime(lastPayroll.uploadAt)}` }),
        React.createElement(RightArrow, null),
        React.createElement(PayrollSummary, { payroll: currentPayroll, title: t("newUpdateLabel") })));
};
export default MostRecentUpdates;
