import { DateFormat, getDateLocalTimezoneFormat } from "../../shared/utils/date";
export const requiredInssChanges = ["InssRemoval", "InssReturn"];
export const requiredFieldsConflicts = ["fired", ...requiredInssChanges];
export const orderConflictsByChange = (conflicts) => {
    return conflicts.sort((firstConflict, secondConflict) => {
        if (requiredFieldsConflicts.includes(firstConflict.change) && firstConflict.hasActiveLoan) {
            if (requiredFieldsConflicts.includes(firstConflict.change) && secondConflict.hasActiveLoan) {
                return 0;
            }
            return -1;
        }
        return 1;
    });
};
export const getConflictsFiredUsersWithActiveLoan = (conflicts) => conflicts.filter(({ change, hasActiveLoan }) => change === "fired" && hasActiveLoan);
export const getConflictsInssRemovedUsersWithActiveLoan = (conflicts) => conflicts.filter(({ change, hasActiveLoan }) => requiredInssChanges.includes(change) && hasActiveLoan);
export function mapFiringsInfo(firingsInfo) {
    return firingsInfo.map((firingInfo) => ({
        resignationDate: getDateLocalTimezoneFormat(firingInfo.resignationDate, DateFormat.ISO),
        rescissionPaymentDate: getDateLocalTimezoneFormat(firingInfo.rescissionPaymentDate, DateFormat.ISO),
        rescissionValue: firingInfo.rescissionValue,
        userId: firingInfo.userId,
        discountedValueOverride: firingInfo.discountedValueOverride,
    }));
}
export function mapFiringsInfoInDb(firingsInfo) {
    return firingsInfo.map((firingInfo) => {
        const discountedValue = getDiscountedValue(firingInfo);
        const discountedValueOverride = getDiscountedValueOverride(firingInfo);
        return {
            resignationDate: getDateLocalTimezoneFormat(firingInfo.resignationDate, DateFormat.ISO),
            rescissionPaymentDate: getDateLocalTimezoneFormat(firingInfo.rescissionPaymentDate, DateFormat.ISO),
            rescissionValue: firingInfo.rescissionValue,
            userId: firingInfo.userId,
            discountedValueOverride,
            discountedValue,
            userDueBalance: firingInfo.userDueBalance,
        };
    });
}
function getDiscountedValueOverride(firingInfo) {
    return firingInfo.discountedValueRescissionOverride ? firingInfo.discountedValue : null;
}
function getDiscountedValue(firingInfo) {
    return firingInfo.discountedValueRadio
        ? firingInfo.discountedValue
        : firingInfo.discountedValueRescissionOverride;
}
export function mapInssRemovalInfo(firingsInfo) {
    return firingsInfo.map((firingInfo) => (Object.assign(Object.assign({}, firingInfo), { date: firingInfo.date ? getDateLocalTimezoneFormat(firingInfo.date, DateFormat.ISO) : null })));
}
export function updateConflictValue(args) {
    return args.conflictsValue.map((conflictValue) => {
        if (conflictValue.userId !== args.userId) {
            return conflictValue;
        }
        return Object.assign(Object.assign({}, conflictValue), { [args.field]: args.value });
    });
}
