import React from "react";
import s from "./style.scss";
import { formatStringCurrency } from "../../../shared/utils/formatters";
import { RightArrow } from "../../../shared/components/Icons/RightArrow";
import { Tooltip } from "antd";
export const ChangeDisplay = ({ upgrade }) => upgrade ? (React.createElement("div", { className: s.upgrade },
    typeof upgrade.from === "number" ? (React.createElement("div", { className: s.value }, formatStringCurrency(upgrade.from))) : (React.createElement(Tooltip, { title: upgrade.from },
        React.createElement("div", { className: s.truncate }, upgrade.from))),
    React.createElement(RightArrow, null),
    typeof upgrade.to === "number" ? (React.createElement("div", { className: s.value }, formatStringCurrency(upgrade.to))) : (React.createElement(Tooltip, { title: upgrade.to },
        React.createElement("div", { className: s.truncate }, upgrade.to))))) : null;
