import { api } from "../index";
export class ProposalStore {
    constructor() {
        this.getAssociatedLoansProposalsPF = (filter) => {
            return api.getAssociatedLoansProposalsPF({ filter });
        };
        this.getUserLoanInfos = (companyId, userCpf, solicitationDate) => {
            return api.getUserLoanInfos({ companyId, userCpf, solicitationDate });
        };
        this.getLoanSimulationSummary = (loan, withPrestamista, solicitationDate, cpf, companyId) => {
            return api.getLoanSimulationSummary({
                loan,
                withPrestamista,
                solicitationDate,
                cpf,
                companyId,
            });
        };
        this.simulateInstallments = (loanValue, userId, companyId, solicitationDate, withPrestamista = false) => {
            return api.simulateInstallments({
                loanValue,
                userId,
                companyId,
                solicitationDate,
                withPrestamista,
            });
        };
        this.saveLoanProposalPF = (cpf, companyId, summary) => {
            return api.saveLoanProposalPF({ cpf, companyId, summary });
        };
        this.updateLoansProposalsStatus = (proposalsId, status) => {
            return api.updateLoansProposalsStatus({ proposalsId, status });
        };
    }
}
