import React from "react";
import classNames from "classnames";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import s from "./style.scss";
import { useStores } from "../../utils/mobx";
import { Title } from "../../../shared/components/Title";
import { LeftArrow } from "../../../shared/components/Icons/LeftArrow";
export const SectionHeader = ({ title, description, info, actions, className, hasBackButton, linkToBackButton, }) => {
    const { routerStore } = useStores();
    const { t } = useTranslation();
    return (React.createElement("div", { className: classNames(s.sectionHeader, className) },
        React.createElement("div", null,
            hasBackButton && (React.createElement("div", { className: s.backButton, onClick: () => {
                    if (linkToBackButton) {
                        routerStore.push(linkToBackButton);
                    }
                    else {
                        routerStore.goBack();
                    }
                } },
                React.createElement(LeftArrow, null),
                " ",
                t("backLabel"))),
            React.createElement(Title, { title: title }),
            info && React.createElement("div", { className: s.info, dangerouslySetInnerHTML: { __html: info } }),
            description && React.createElement("div", { className: s.description }, description)),
        actions && (React.createElement("div", { className: s.buttonContainer }, actions.map((action, index) => (React.createElement(Button, { key: index, type: "primary", size: "large", onClick: action.handleAction, disabled: action === null || action === void 0 ? void 0 : action.disabled },
            action.icon && action.icon,
            action.label)))))));
};
