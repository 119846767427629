import { __awaiter } from "tslib";
import React from "react";
import { observer } from "mobx-react-lite";
import { Icon, Spin, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useStores } from "../../utils/mobx";
import { FormTitle } from "../../../shared/components/FormTitle";
import { SectionHeader } from "../../components/SectionHeader";
import { DownloadIcon } from "../../../shared/components/Icons/Download";
import { formatBoolean, formatCurrencyNoPrefix, splitHundered, } from "../../../shared/utils/formatters";
import AgreementItem from "../../components/AgreementItem";
import { formatDate } from "../../../shared/utils/date";
import { downloadFileFromBuffer } from "../../../shared/utils/file";
import { isInterestRateRange } from "../../../shared/utils/agreements";
const Agreement = observer(() => {
    const { t } = useTranslation();
    const { agreementStore, generalStore: { catchErrors, setErrorMessage, clearMessage, selectedCompanyId }, } = useStores();
    const [loading, setLoading] = React.useState(true);
    const [agreement, setAgreement] = React.useState();
    const getAgreement = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const agreementFetched = yield agreementStore.getAgreement();
        setAgreement(agreementFetched);
        setLoading(false);
    }), []);
    React.useEffect(() => {
        getAgreement();
    }, [getAgreement, selectedCompanyId]);
    const downloadAgreementFile = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            clearMessage();
            if (agreement) {
                const file = yield agreementStore.getAgreementFile(agreement.id);
                if (file) {
                    downloadFileFromBuffer(file, agreement.filename);
                }
            }
        }
        catch (error) {
            catchErrors(error, setErrorMessage, "Arquivo não encontrado");
        }
        finally {
            setLoading(false);
        }
    });
    return (React.createElement(Spin, { spinning: loading },
        React.createElement(SectionHeader, { title: t("agreement.title"), actions: [
                {
                    label: t("agreement.downloadLabel"),
                    icon: React.createElement(Icon, { component: () => React.createElement(DownloadIcon, null) }),
                    handleAction: downloadAgreementFile,
                    disabled: !agreement,
                },
            ] }),
        !!agreement ? (React.createElement(React.Fragment, null,
            React.createElement(FormTitle, { title: t("agreement.creditPolicyTitle") }),
            React.createElement(Row, null,
                React.createElement(AgreementItem, { title: t("agreement.minWorkTimeLabel"), value: agreement.minWorkTime, description: " meses" }),
                React.createElement(AgreementItem, { title: t("agreement.maxLoanInSalariesLabel"), value: splitHundered(agreement.maxLoanInSalaries), description: " sal\u00E1rios" }),
                React.createElement(AgreementItem, { title: t("agreement.maxPercentAllowedLabel"), value: formatCurrencyNoPrefix(splitHundered(agreement.maxPercentAllowed)), description: "% do sal\u00E1rio" }),
                isInterestRateRange(agreement) && (React.createElement(AgreementItem, { title: t("agreement.minInterestRate"), value: formatCurrencyNoPrefix(splitHundered(agreement.minInterestRate)), description: "%" })),
                React.createElement(AgreementItem, { title: t(isInterestRateRange(agreement)
                        ? "agreement.maxInterestRate"
                        : "agreement.interestRateLabel"), value: formatCurrencyNoPrefix(splitHundered(agreement.maxInterestRate)), description: "%" }),
                React.createElement(AgreementItem, { title: t("agreement.maximumInstallmentsLabel"), value: agreement.maximumInstallments }),
                React.createElement(AgreementItem, { title: t("agreement.minimumLoanAmountLabel"), value: formatCurrencyNoPrefix(splitHundered(agreement.minimumLoanAmount)), prefix: "R$ " }),
                React.createElement(AgreementItem, { title: t("agreement.denyParallelLoansLabel"), value: formatBoolean(agreement.denyParallelLoans) })),
            React.createElement(FormTitle, { title: t("agreement.ratesAndValuesTitle") }),
            React.createElement(Row, null,
                React.createElement(AgreementItem, { title: t("agreement.structuringRateLabel"), value: formatCurrencyNoPrefix(splitHundered(agreement.structuringRate)), description: "%" }),
                React.createElement(AgreementItem, { title: t("agreement.tedValueLabel"), value: formatCurrencyNoPrefix(splitHundered(agreement.tedValue)), prefix: "R$ " }),
                React.createElement(AgreementItem, { title: t("agreement.custodyValueLabel"), value: formatCurrencyNoPrefix(splitHundered(agreement.custodyValue)), prefix: "R$ " }),
                React.createElement(AgreementItem, { title: t("agreement.thirdPartyValue"), value: formatCurrencyNoPrefix(splitHundered(agreement.thirdPartyValue)), prefix: "R$ " })),
            React.createElement(FormTitle, { title: t("agreement.dueTitle") }),
            React.createElement(Row, null,
                React.createElement(AgreementItem, { title: t("agreement.dueDateLabel"), value: formatDate(new Date(agreement.dueDate), "DD/MM/YYYY") })))) : (React.createElement("div", null, "Nenhum conv\u00EAnio encontrado"))));
});
export default Agreement;
