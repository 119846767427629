import * as ReactDOM from "react-dom";
import React from "react";
import { LocaleProvider } from "antd";
import { Provider } from "mobx-react";
import { rootStore } from "./stores/RootStore";
import { App } from "./containers/App";
import { Router } from "react-router";
import ptBR from "antd/lib/locale-provider/pt_BR";
import { ClearCacheProvider } from "react-clear-cache";
import "firebase/analytics";
import { ApiClient } from "../api/companyApi";
import { ApiFactory } from "../api/apiFactory";
import { FIREBASE_CONFIG_PORTAL } from "../constants/firebase.constant";
import { initFirebaseAnalytics } from "../shared/utils/firebase";
initFirebaseAnalytics(FIREBASE_CONFIG_PORTAL);
export const api = new ApiFactory().create(ApiClient, "company");
rootStore.history.listen(() => {
    rootStore.authStore.updateUserFromLocalStorage();
    rootStore.generalStore.clearMessage();
});
ReactDOM.render(React.createElement(ClearCacheProvider, { duration: 5000 },
    React.createElement(Provider, Object.assign({}, rootStore),
        React.createElement(Router, { history: rootStore.history },
            React.createElement(LocaleProvider, { locale: ptBR },
                React.createElement(App, null))))), document.getElementById("root"));
