import onlyNumbers from "@brazilian-utils/helper-only-numbers";
import isValidCpf from "@brazilian-utils/is-valid-cpf";
export const isValidDate = (date) => !!date && date.toString() !== "Invalid Date";
export const isValidCpfCellValue = (cpf) => {
    if (typeof cpf === "string") {
        const numbers = onlyNumbers(cpf);
        return numbers !== "" && numbers.length <= 11 && isValidCpf(cpf);
    }
    return !!cpf;
};
export const isValidNumber = (value) => !!value && !isNaN(Number(value)) && value > 0;
export const isValidNumberForMonthlyDiscountPayroll = (value) => {
    return !isEmpty(value) && !isNaN(Number(value)) && value >= 0;
};
export const formatStringAndRemoveWhiteSpaces = (value, fallback) => value ? value.toString().trim() : fallback;
export const valueToNumber = (value) => value || Number(value);
function isEmpty(value) {
    return value === null || value === undefined || value.toString().trim() === "";
}
