import { __awaiter } from "tslib";
import { api } from "../index";
export class PayrollReportStore {
    constructor() {
        this.getPayrollReport = (rowsPerPage, page, month, filter) => __awaiter(this, void 0, void 0, function* () { return api.getPayrollReport({ month: month.month, year: month.year, page, rowsPerPage, filter }); });
        this.getPayrollSummary = (month, filter) => __awaiter(this, void 0, void 0, function* () { return api.getPayrollSummary({ month: month.month, year: month.year, filter }); });
        this.getOldestMonthWithActiveInstallment = (filter) => __awaiter(this, void 0, void 0, function* () { return api.getOldestMonthWithActiveInstallment({ filter }); });
        this.exportPayrollReport = (monthAndYear, filter) => __awaiter(this, void 0, void 0, function* () { return api.exportPayrollReport({ month: monthAndYear.month, year: monthAndYear.year, filter }); });
        this.getReferenceDate = (solicitationDate) => __awaiter(this, void 0, void 0, function* () { return api.getReferenceDate({ solicitationDate }); });
    }
}
