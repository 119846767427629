import xlsx from "xlsx-populate/browser/xlsx-populate";
import { formatStringAndRemoveWhiteSpaces, isValidCpfCellValue, isValidNumber, isValidDate, isValidNumberForMonthlyDiscountPayroll, valueToNumber, } from "./utils";
const defaultWidth = 22;
export const payrollSheetSchema = {
    columns: {
        name: {
            label: "Nome",
            sample: "João da Silva Pereira",
            transform: formatStringAndRemoveWhiteSpaces,
            validator: (name) => {
                if (typeof name === "string") {
                    return name !== "" && name.length <= 60;
                }
                return !!name;
            },
            comment: "Máximo de 60 caracteres.",
            width: defaultWidth,
        },
        cpf: {
            label: "CPF",
            sample: "513.373.090-01",
            transform: formatStringAndRemoveWhiteSpaces,
            validator: (cpf) => isValidCpfCellValue(cpf),
            style: {
                numFmt: "@",
            },
            comment: "CPF 11 dígitos.",
            width: defaultWidth,
        },
        grossSalary: {
            label: "Salário Bruto",
            sample: 5000.0,
            transform: (v) => {
                return v ? Number(v) : undefined;
            },
            validator: (grossSalary) => isValidNumber(grossSalary),
            style: {
                numFmt: "#,##0.00",
            },
            comment: "Decimal, formato #,##0.00.",
            width: defaultWidth,
        },
        netSalary: {
            label: "Salário Disponível",
            sample: 4500.46,
            transform: (v) => {
                return v ? Number(v) : undefined;
            },
            validator: (netSalary) => isValidNumber(netSalary),
            style: {
                numFmt: "#,##0.00",
            },
            comment: "Decimal, formato #,##0.00.",
            width: defaultWidth,
        },
        birthDate: {
            label: "Data de Nascimento",
            sample: new Date(1989, 4, 29),
            transform: (v) => {
                return v ? xlsx.numberToDate(Number(v)) : undefined;
            },
            validator: (birthDate) => birthDate instanceof Date && isValidDate(birthDate),
            style: {
                numFmt: "dd/mm/yyyy",
            },
            comment: "Data no padrão DD/MM/YYYY",
            width: defaultWidth,
        },
        admissionDate: {
            label: "Data de Admissão",
            sample: new Date(2019, 6, 5),
            transform: (v) => {
                return v ? xlsx.numberToDate(Number(v)) : undefined;
            },
            validator: (admissionDate) => admissionDate instanceof Date && isValidDate(admissionDate),
            style: {
                numFmt: "dd/mm/yyyy",
            },
            comment: "Data no padrão DD/MM/YYYY",
            width: defaultWidth,
        },
        companySector: {
            label: "Setor",
            sample: "Administração",
            transform: (v) => (v ? v.toString() : undefined),
            validator: (sector) => typeof sector === "string" && sector !== "",
            comment: "Máximo de 60 caracteres.",
            width: defaultWidth,
        },
        companyPosition: {
            label: "Cargo",
            sample: "Analista",
            transform: (v) => (v ? v.toString() : undefined),
            validator: (position) => typeof position === "string" && position !== "" && position.length <= 60,
            comment: "Máximo de 60 caracteres.",
            width: defaultWidth,
        },
        inssRemoval: {
            label: "Afastado pelo INSS",
            sample: "N",
            transform: (v) => (v ? v.toString() : undefined),
            validator: (name) => {
                if (typeof name === "string") {
                    return name !== "" && (name === "S" || name === "N");
                }
                return !!name;
            },
            style: {
                alignment: { horizontal: "center" },
            },
            comment: "['N’ = não, ‘S’ = sim]",
            width: defaultWidth,
        },
        monthlyPayrollDiscountWithOtherInstitutions: {
            label: "Desconto mensal em consignados com outras instituições",
            sample: 0,
            transform: (v) => valueToNumber(v),
            validator: (monthlyPayrollDiscountWithOtherInstitutions) => isValidNumberForMonthlyDiscountPayroll(monthlyPayrollDiscountWithOtherInstitutions),
            style: {
                numFmt: "#,##0.00",
            },
            comment: "Decimal, formato #,##0.00.",
            width: 52,
        },
    },
};
