import { api } from "../index";
export class ContractStore {
    constructor() {
        this.getContractTotal = (filter) => api.getContractTotal({ filter });
        this.getContracts = (filter, page, rowsPerPage) => {
            return api.getContracts({ filter, page, rowsPerPage });
        };
        this.exportContractReport = (filter) => {
            return api.exportContracts({ filter });
        };
        this.getUserLoanInfos = (companyId, userCpf, solicitationDate) => {
            return api.getUserLoanInfos({ companyId, userCpf, solicitationDate });
        };
        this.getLoanSimulationSummary = (loan, withPrestamista, solicitationDate, cpf, companyId) => {
            return api.getLoanSimulationSummary({
                loan,
                withPrestamista,
                solicitationDate,
                cpf,
                companyId,
            });
        };
        this.simulateInstallments = (loanValue, userId, companyId, solicitationDate, withPrestamista = false) => {
            return api.simulateInstallments({
                loanValue,
                userId,
                companyId,
                solicitationDate,
                withPrestamista,
            });
        };
    }
}
