import { __awaiter } from "tslib";
import React, { useEffect, useState } from "react";
import { Form, Spin } from "antd";
import useReactRouter from "use-react-router";
import { useTranslation } from "react-i18next";
import { getFormItemName } from "../../../../shared/utils/form.utils";
import { RoundedButton } from "../../RoundedButton";
import { useStores } from "../../../utils/mobx";
import { EmployeesInfo } from "../../EmployeeInfo";
import { FiringFormFields } from "../FiringFormFields";
import { mapFiringsInfoInDb } from "../../../utils/payrollConflict.utils";
import styles from "./style.scss";
export var Steps;
(function (Steps) {
    Steps[Steps["simulateFiringStep"] = 0] = "simulateFiringStep";
    Steps[Steps["firingEmployesStep"] = 1] = "firingEmployesStep";
})(Steps || (Steps = {}));
const FiringDetailsComp = ({ form, onBackButtonClick, usersIds, onSubmit, }) => {
    const { t } = useTranslation();
    const { match } = useReactRouter();
    const [employeesPreview, setEmployeesPreview] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(Steps.simulateFiringStep);
    const [isSimulating, setIsSimulating] = useState(false);
    const { validateFieldsAndScroll } = form;
    const { generalStore: { setErrorMessage, catchErrors, setSuccessMessage }, firingsStore, } = useStores();
    const fetchEmployeesPreview = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const employess = yield firingsStore.getFiringEmployeesPreview(match.params.companyId, usersIds);
            setEmployeesPreview(employess);
            if (haveEmployeesToFiringWithActiveLoans(employess) === false) {
                setCurrentStep(Steps.firingEmployesStep);
            }
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("firing.fetchEmployeesFail"));
        }
        finally {
            setLoading(false);
        }
    });
    const findEmployeesWithActiveLoan = (employees) => employees.find(({ activeLoans }) => activeLoans > 0);
    const haveEmployeesToFiringWithActiveLoans = (employees) => {
        return findEmployeesWithActiveLoan(employees) ? true : false;
    };
    useEffect(() => {
        fetchEmployeesPreview();
    }, [usersIds, match.params.companyId]);
    const handleConfirmFiring = () => {
        validateFieldsAndScroll((errors, values) => __awaiter(void 0, void 0, void 0, function* () {
            if (!errors) {
                if (currentStep === Steps.firingEmployesStep) {
                    yield fireEmployees(values.firings);
                }
                setCurrentStep(Steps.firingEmployesStep);
            }
        }));
    };
    const fireEmployees = (firings) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const changeId = yield firingsStore.fireEmployees(match.params.companyId, mapFiringsInfoInDb(firings));
            onSubmit(changeId);
            setSuccessMessage(t("firing.firingSuccess"));
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("firing.firingError"));
        }
        finally {
            setLoading(false);
        }
    });
    return (React.createElement("div", { className: styles.firingDetails },
        React.createElement(Spin, { spinning: loading },
            React.createElement("div", { className: styles.cardsWrapper }, employeesPreview.map((employee, index) => {
                const fieldName = getFormItemName("firings", index);
                return (React.createElement("div", { key: employee.cpf, className: styles.firingCard },
                    React.createElement(EmployeesInfo, { employee: employee }),
                    React.createElement(FiringFormFields, { employee: employee, fieldName: fieldName, form: form, currentStep: currentStep, setIsSimulating: setIsSimulating })));
            })),
            React.createElement("div", { className: styles.firingDetailsFooter },
                React.createElement(RoundedButton, { secondary: true, onClick: onBackButtonClick, "data-cy": "back-button" }, t("backLabel")),
                React.createElement(RoundedButton, { onClick: handleConfirmFiring, "data-cy": "confirm-button", disabled: isSimulating }, currentStep === Steps.simulateFiringStep &&
                    haveEmployeesToFiringWithActiveLoans(employeesPreview)
                    ? t("next")
                    : t("confirmLabel"))))));
};
export const FiringDetails = Form.create({ name: "firings_form" })(FiringDetailsComp);
