import React from "react";
import { useTranslation } from "react-i18next";
import { ScrollableList } from "../../../../shared/components/ScrollableList";
import styles from "./style.scss";
export const UpdatesList = ({ children, loadMoreUpdates, currentPage, hasMore, }) => {
    const { t } = useTranslation();
    const getMoreUpdates = () => {
        if (hasMore) {
            loadMoreUpdates(currentPage + 1);
        }
    };
    return (React.createElement("div", { className: styles.updatesList, "data-cy": "updateList-data-cy" },
        React.createElement("div", { className: styles.title }, t("updatesLabel").toLowerCase()),
        React.createElement(ScrollableList, null,
            children,
            hasMore && (React.createElement("div", { onClick: getMoreUpdates, className: styles.loadMoreButton }, t("oldestUpdatesText"))))));
};
