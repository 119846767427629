import firebase from "firebase/app";
export function initFirebaseAnalytics(config) {
    firebase.initializeApp(config);
    firebase.analytics();
}
export function sendFirebaseAnalyticsEvent({ message, event }) {
    if (document.readyState === "complete") {
        firebase.analytics().logEvent(message, event);
    }
}
