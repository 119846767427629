import { __awaiter } from "tslib";
import React, { useState, useEffect, useRef } from "react";
import { DatePicker, Form, Input, Radio } from "antd";
import { useTranslation } from "react-i18next";
import { formatStringCurrency, toInt } from "../../../../shared/utils/formatters";
import InputCurrency from "../../../../shared/components/InputCurrency";
import styles from "./style.scss";
import { toMoment } from "../../../../shared/utils/date";
import { useStores } from "../../../utils/mobx";
import useReactRouter from "use-react-router";
import { mapFiringsInfo } from "../../../utils/payrollConflict.utils";
import { isEqual } from "lodash";
import { Steps } from "../FiringDetails";
import TooltipDisplay from "../../../../shared/components/TooltipDisplay";
const shouldRequestEmployeeFiringInfo = (employee) => employee.activeLoans > 0;
export const FiringFormFields = ({ employee, form, fieldName, employeeDetails, currentStep, setIsSimulating, }) => {
    const { t } = useTranslation();
    const { getFieldDecorator, setFieldsValue, getFieldsValue } = form;
    const [discountedValueRescissionOverride, setDiscountedValueRescissionOverride] = useState(true);
    const { match } = useReactRouter();
    const [firingsInfoInDB, setFiringsInfoInDB] = useState();
    const [firingsInfo, setFiringsInfo] = useState();
    const firingsInfoRef = useRef();
    firingsInfoRef.current = firingsInfo;
    const isFiringEmployesStep = currentStep === Steps.firingEmployesStep;
    const getDisplayOption = () => {
        return isFiringEmployesStep ? "none" : "";
    };
    const { generalStore: { setErrorMessage, catchErrors }, firingsStore, } = useStores();
    const fetchSimulateFireEmployees = (firings) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (setIsSimulating)
                setIsSimulating(true);
            const firingInfo = mapFiringsInfo([firings]);
            const firingInfoInDB = yield firingsStore.simulateFireEmployees(match.params.companyId, firingInfo[0]);
            setFiringsInfoInDB(firingInfoInDB);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("firing.fetchSimulateEmployeesFail"));
        }
        finally {
            if (setIsSimulating)
                setIsSimulating(false);
        }
    });
    const handleSimulateFiring = (firings) => __awaiter(void 0, void 0, void 0, function* () {
        if (firings) {
            yield fetchSimulateFireEmployees(firings);
        }
    });
    useEffect(() => {
        setFieldsValue({
            [fieldName]: {
                discountedValueRescission: firingsInfoInDB === null || firingsInfoInDB === void 0 ? void 0 : firingsInfoInDB.discountedValue,
                discountedValue: firingsInfoInDB === null || firingsInfoInDB === void 0 ? void 0 : firingsInfoInDB.discountedValue,
                userDueBalance: firingsInfoInDB === null || firingsInfoInDB === void 0 ? void 0 : firingsInfoInDB.userDueBalance,
            },
        });
    }, [firingsInfoInDB]);
    useEffect(() => {
        const values = getFieldsValue();
        const firing = values.firings.find((firings) => employee.id === (firings === null || firings === void 0 ? void 0 : firings.userId));
        if (!isEqual(firingsInfoRef.current, firing)) {
            setFiringsInfo(firing);
        }
    }, [getFieldsValue()]);
    useEffect(() => {
        if (isFiringEmployesStep && firingsInfo && employee.activeLoans > 0) {
            handleSimulateFiring(firingsInfo);
        }
    }, [firingsInfo, currentStep]);
    const fieldClass = employeeDetails ? styles.fieldDetails : styles.field;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { display: shouldRequestEmployeeFiringInfo(employee) ? "flex" : "none" }, className: styles.forms, "data-cy": "firing-form-wrapper" },
            React.createElement(Form.Item, { style: { display: "none" } },
                getFieldDecorator(`${fieldName}.userId`, {
                    initialValue: employee.id,
                }),
                React.createElement(Input, null)),
            React.createElement(Form.Item, { style: { display: "none" } },
                getFieldDecorator(`${fieldName}.userDueBalance`),
                React.createElement(Input, null)),
            React.createElement(Form.Item, { style: { display: "none" } },
                getFieldDecorator(`${fieldName}.discountedValue`),
                React.createElement(Input, null)),
            React.createElement("div", { className: fieldClass, style: { display: getDisplayOption() } },
                React.createElement(Form.Item, { "data-cy": "firing-resignationDate-wrapper", label: t("firing.resignationDate") }, getFieldDecorator(`${fieldName}.resignationDate`, {
                    initialValue: (employeeDetails === null || employeeDetails === void 0 ? void 0 : employeeDetails.resignationDate)
                        ? toMoment(employeeDetails === null || employeeDetails === void 0 ? void 0 : employeeDetails.resignationDate)
                        : null,
                    rules: [
                        {
                            required: shouldRequestEmployeeFiringInfo(employee),
                            message: t("mandatoryField", { field: t("firing.resignationDate") }),
                        },
                    ],
                })(React.createElement(DatePicker, { disabled: !!employeeDetails, placeholder: "10/10/2010", format: "DD/MM/YYYY", "data-cy": "firing-resignationDate-wrapper-DatePicker" })))),
            React.createElement("div", { className: fieldClass, style: { display: getDisplayOption() } },
                React.createElement(Form.Item, { "data-cy": "firing-rescissionValue-wrapper", label: t("firing.rescissionValue"), className: fieldClass }, getFieldDecorator(`${fieldName}.rescissionValue`, {
                    normalize: (value) => toInt(value),
                    initialValue: (employeeDetails === null || employeeDetails === void 0 ? void 0 : employeeDetails.rescissionValue) &&
                        formatStringCurrency(employeeDetails === null || employeeDetails === void 0 ? void 0 : employeeDetails.rescissionValue),
                    rules: [
                        {
                            required: shouldRequestEmployeeFiringInfo(employee),
                            message: t("mandatoryField", { field: t("firing.rescissionValue") }),
                        },
                    ],
                })(React.createElement(InputCurrency, { disabled: !!employeeDetails, min: 0 })))),
            React.createElement("div", { className: fieldClass, style: { display: getDisplayOption() } },
                React.createElement("div", { className: styles.rescissionPaymentDateContainer },
                    React.createElement(Form.Item, { "data-cy": "firing-rescissionPaymentDate-wrapper", label: t("firing.rescissionPaymentDate"), className: fieldClass }, getFieldDecorator(`${fieldName}.rescissionPaymentDate`, {
                        initialValue: (employeeDetails === null || employeeDetails === void 0 ? void 0 : employeeDetails.rescissionPaymentDate)
                            ? toMoment(employeeDetails === null || employeeDetails === void 0 ? void 0 : employeeDetails.rescissionPaymentDate)
                            : null,
                        rules: [
                            {
                                required: shouldRequestEmployeeFiringInfo(employee),
                                message: t("mandatoryField", { field: t("firing.rescissionPaymentDate") }),
                            },
                        ],
                    })(React.createElement(DatePicker, { disabled: !!employeeDetails, placeholder: "10/10/2010", format: "DD/MM/YYYY" }))),
                    React.createElement(TooltipDisplay, { title: t("firing.rescissionPaymentDateTooltipTitle"), classname: styles.tooltipContainer }))),
            isFiringEmployesStep && (React.createElement("div", { className: fieldClass },
                React.createElement(Form.Item, { "data-cy": "firing-discountedValueRescission-wrapper", label: t("firing.discountedValueRescission"), className: fieldClass }, getFieldDecorator(`${fieldName}.discountedValueRescission`, {
                    normalize: (value) => toInt(value),
                    rules: [],
                })(React.createElement(InputCurrency, { disabled: true, min: 0, value: Number(firingsInfoInDB === null || firingsInfoInDB === void 0 ? void 0 : firingsInfoInDB.discountedValue), key: String((firingsInfoInDB === null || firingsInfoInDB === void 0 ? void 0 : firingsInfoInDB.discountedValue) || undefined), "data-cy": "firing-discountedValueRescission-wrapper-input" }))))),
            isFiringEmployesStep && (React.createElement("div", { className: fieldClass },
                React.createElement("div", { className: styles.discountedValueRadio },
                    React.createElement(TooltipDisplay, { title: t("firing.tollTipDiscountedValueRadio"), classname: styles.tooltipContainer }),
                    React.createElement(Form.Item, { label: t("firing.discountedValueRadio"), className: fieldClass }, getFieldDecorator(`${fieldName}.discountedValueRadio`, {
                        rules: [
                            {
                                required: shouldRequestEmployeeFiringInfo(employee),
                                message: t("mandatoryField", {
                                    field: t("firing.discountedValueRadioValidation"),
                                }),
                            },
                        ],
                    })(React.createElement(Radio.Group, { className: styles.horizontalRadio, onChange: (e) => {
                            setDiscountedValueRescissionOverride(e.target.value);
                        } },
                        React.createElement(Radio, { value: true }, t("yesLabel")),
                        React.createElement(Radio, { value: false, checked: true }, t("noLabel")))))))),
            !discountedValueRescissionOverride && isFiringEmployesStep && (React.createElement("div", { className: fieldClass },
                React.createElement(Form.Item, { "data-cy": "firing-discountedValueRescissionOverride-wrapper", label: t("firing.discountedValueRescissionOverride"), className: fieldClass }, getFieldDecorator(`${fieldName}.discountedValueRescissionOverride`, {
                    normalize: (value) => toInt(value),
                    rules: [
                        {
                            required: shouldRequestEmployeeFiringInfo(employee),
                            message: t("mandatoryField", { field: t("firing.rescissionValue") }),
                        },
                    ],
                })(React.createElement(InputCurrency, { min: 0 }))))),
            !!employeeDetails && (React.createElement("div", { className: fieldClass },
                React.createElement(Form.Item, { label: t("firing.discountedValue"), "data-cy": "discountedValue-input-wrapper" }, getFieldDecorator(`${fieldName}.discountedValueDetails`, {
                    normalize: (value) => toInt(value),
                    initialValue: employeeDetails.discountedValue &&
                        formatStringCurrency(employeeDetails.discountedValue),
                })(React.createElement(InputCurrency, { disabled: true, min: 0 }))))))));
};
